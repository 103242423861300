<template>
	<div class="gcash-success">
		<app-gcash-success-page />
	</div>
</template>

<script>

import GCashSuccessPageComponent from "@/components/GCashSuccessPage/GCashSuccessPageComponent.vue";

export default {
	name: "GCashSuccessPageView",
	components: {
		"app-gcash-success-page": GCashSuccessPageComponent,
	},
};
</script>