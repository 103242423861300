<template>
	<div class="home">
		<app-head />
		<app-about-coopnet />
		<app-whoweserve />
		<app-ourdxbanksolutions />
		<app-mobilebankingapp />
		<app-coopnetmachine />

		<app-howtousecoopnet />

		<app-hrbreak />

		<app-whycoopnet />
        <app-aggregatedpartners />

		<!-- <app-ourteam /> -->

        <app-hrbreak />

		<app-contactus />
		<app-footer />

		<app-scroll-to-top />
	</div>
</template>

<script>
import HeadComponent from "@/components/Head/HeadComponent.vue";
import AboutCoopnetComponent from "@/components/AboutCoopnet/AboutCoopnetComponent.vue";
import WhoWeServeComponent from "@/components/WhoWeServe/WhoWeServeComponent.vue";
import OurDXBankSolutionsComponent from "@/components/OurDXBankSolutions/OurDXBankSolutionsComponent.vue";
import CoopnetMachineComponent from "@/components/CoopnetMachine/CoopnetMachineComponent.vue";
import MobileBankingAppComponent from "@/components/MobileBankingApp/MobileBankingAppComponent.vue";
import HowToUseCoopnetComponent from "@/components/HowToUseCoopnet/HowToUseCoopnetComponent.vue";
import ContactUsComponent from "@/components/ContactUs/ContactUsComponent.vue";
import WhyCoopnetComponent from "@/components/WhyCoopnet/WhyCoopnetComponent.vue";
import AggregatedPartnersComponent from "@/components/AggregatedPartners/AggregatedPartnersComponent.vue";
// import OurTeamComponent from "@/components/OurTeam/OurTeamComponent.vue";
import CoopnetFooterComponent from "@/components/CoopnetFooter/CoopnetFooterComponent.vue";
import ScrollToTopComponent from "@/components/Utilities/ScrollToTopComponent.vue";
import HRBreakComponent from "@/components/Utilities/HRBreakComponent.vue";

export default {
	name: "HomeView",
	components: {
		"app-head": HeadComponent,
		"app-about-coopnet": AboutCoopnetComponent,
		"app-whoweserve": WhoWeServeComponent,
		"app-ourdxbanksolutions": OurDXBankSolutionsComponent,
		"app-coopnetmachine": CoopnetMachineComponent,
		"app-howtousecoopnet": HowToUseCoopnetComponent,
		"app-mobilebankingapp": MobileBankingAppComponent,
		"app-whycoopnet": WhyCoopnetComponent,
        "app-aggregatedpartners": AggregatedPartnersComponent,
		// "app-ourteam": OurTeamComponent,
		"app-contactus": ContactUsComponent,
		"app-footer": CoopnetFooterComponent,

        // Utilities
        "app-hrbreak": HRBreakComponent,
		"app-scroll-to-top": ScrollToTopComponent,
	},
};
</script>