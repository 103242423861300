import { render, staticRenderFns } from "./CoopnetFooterComponent.vue?vue&type=template&id=52b2bbfb&scoped=true&"
var script = {}
import style0 from "./CoopnetFooterComponent.vue?vue&type=style&index=0&id=52b2bbfb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52b2bbfb",
  null
  
)

export default component.exports