<template>
	<div class="dragonpay-success">
		<app-dragonpay-success-page />
	</div>
</template>

<script>

import DragonpaySuccessPageComponent from "@/components/DragonpaySuccessPage/DragonpaySuccessPageComponent.vue";

export default {
	name: "DragonpaySuccessPageView",
	components: {
		"app-dragonpay-success-page": DragonpaySuccessPageComponent,
	},
};
</script>