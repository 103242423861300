<template>
	<div id="mobilebankingapp">
		<!-- Desktop -->
		<div class="mobilebankingappdesktop is-hidden-mobile">
			<div class="container">
				<div class="columns is-centered">
					<div class="column mt-6 has-text-left">
						<p
							class="is-size-1 has-text-weight-bold"
							:style="{ color: 'black' }"
						>
							MOBILE
							<span :style="{ color: 'rgb(51, 157, 255)' }">
								BANKING APP
							</span>
						</p>
						<p
							class="is-size-4 has-text-weight-semibold mt-2"
							:style="{ color: 'black' }"
						>
							E-wallet plus Mobile Baking Features (when
							integrated with the entity's Core Banking System)
						</p>
						<div class="columns mt-5 is-centered">
							<div
								class="column"
								v-for="(logo, index) in mobileBankingAppLogos1"
								:key="index"
							>
								<app-mobilebankingapp-logo
									:title="logo.title"
									:file-name="logo.fileName"
								/>
							</div>
						</div>
						<div class="columns mt-2 is-centered">
							<div
								class="column"
								v-for="(logo, index) in mobileBankingAppLogos2"
								:key="index"
							>
								<app-mobilebankingapp-logo
									:title="logo.title"
									:file-name="logo.fileName"
								/>
							</div>
						</div>
					</div>
					<div class="column p-0 mt-6 ">
						<div class="ellipsebackground">
							<div class="columns">
								<div class="column">
									<img
										:src="
											require('@/assets/mobilebankingapp/coopnet-mobile 1.png')
										"
										alt="coopnet mobile"
										class="hidden ml-6"
										v-infocus="'mobilebankingapp1'"
									/>
								</div>
								<div class="column is-flex is-align-items-center">
									<div class="is-flex-direction-column ml-6">
										<img
											:src="
												require('@/assets/mobilebankingapp/mobilebankingapp-logo.png')
											"
											alt="coopnet mobile logo"
											class="hidden is-align-self-center"
											v-infocus="'mobilebankingapp1'"
										/>
										<p
											class="
												has-text-weight-bold
												is-size-4
												hidden
											"
											v-infocus="'mobilebankingapp1'"
										>
											Coopnet
										</p>
										<p
											class="hidden has-text-weight-semibold"
											v-infocus="'mobilebankingapp1'"
										>
											Available on
										</p>
										<div>
											<a :href="googleplaystorelink" target="_blank">
												<img
													:src="
														require('@/assets/mobilebankingapp/Googleplay 1.png')
													"
													alt="coopnet mobile logo"
													class="
														hidden
														is-align-self-center
													"
													v-infocus="'mobilebankingapp1'"
												/>
											</a>
										</div>
										<div>
											<a :href="appleappstorelink" target="_blank">
												<img
													:src="
														require('@/assets/mobilebankingapp/appstore 1.png')
													"
													alt="coopnet mobile logo"
													class="
														hidden
														is-align-self-center
													"
													v-infocus="'mobilebankingapp1'"
												/>
											</a>
										</div>
										<div>
											<!-- <a :href="huaweiappgallerylink" target="_blank"> -->
												<img
													:src="
														require('@/assets/mobilebankingapp/appgallery 1.png')
													"
													alt="coopnet mobile logo"
													class="
														hidden
														is-align-self-center
													"
													v-infocus="'mobilebankingapp1'"
												/>
											<!-- </a> -->
										</div>
									</div>
								</div>
							</div>
						</div>
							
						
					</div>
				</div>
			</div>
		</div>
		<!-- Mobile -->
		<div class="mobilebankingappmobile is-hidden-tablet">
			<div class="container">
				<div class="columns is-centered">
					<div class="column has-text-centered">
						<p
							class="
								is-size-3-mobile
								has-text-weight-bold
								mt-1
								mb-3
							"
							:style="{ color: 'black' }"
						>
							MOBILE <br />
							<span :style="{ color: 'rgb(51, 157, 255)' }"
								>BANKING APP</span
							>
						</p>
						<p
							class="
								is-size-6-mobile
								has-text-weight-semibold
								mt-2
								px-5
							"
							:style="{ color: 'black' }"
							v-infocus="'mobilebankingappmobile2'"
						>
							E-wallet plus Mobile Baking Features (when
							integrated with the entity's Core Banking System)
						</p>
						<div class="columns is-mobile mt-2 is-centered">
							<div class="column is-4">
								<app-mobilebankingapp-logo
									:title="mobileBankingAppLogos1[0].title"
									:file-name="
										mobileBankingAppLogos1[0].fileName
									"
									v-infocus="'mobilebankingleft1mobile'"
									class="hidden"
								/>
							</div>
							<div class="column is-4">
								<app-mobilebankingapp-logo
									:title="mobileBankingAppLogos1[1].title"
									:file-name="
										mobileBankingAppLogos1[1].fileName
									"
									v-infocus="'mobilebankingleft1mobile'"
									class="hidden"
								/>
							</div>
						</div>
						<div class="columns is-mobile mt-2 is-centered">
							<div class="column is-4">
								<app-mobilebankingapp-logo
									:title="mobileBankingAppLogos1[2].title"
									:file-name="
										mobileBankingAppLogos1[2].fileName
									"
									v-infocus="'mobilebankingright2mobile'"
									class="hidden"
								/>
							</div>
							<div class="column is-4">
								<app-mobilebankingapp-logo
									:title="mobileBankingAppLogos1[3].title"
									:file-name="
										mobileBankingAppLogos1[3].fileName
									"
									v-infocus="'mobilebankingright2mobile'"
									class="hidden"
								/>
							</div>
						</div>
						<div class="columns is-mobile mt-2 is-centered">
							<div class="column is-4">
								<app-mobilebankingapp-logo
									:title="mobileBankingAppLogos2[0].title"
									:file-name="
										mobileBankingAppLogos2[0].fileName
									"
									v-infocus="'mobilebankingleft3mobile'"
									class="hidden"
								/>
							</div>
							<div class="column is-4">
								<app-mobilebankingapp-logo
									:title="mobileBankingAppLogos2[1].title"
									:file-name="
										mobileBankingAppLogos2[1].fileName
									"
									v-infocus="'mobilebankingleft3mobile'"
									class="hidden"
								/>
							</div>
						</div>
						<div class="columns is-mobile mt-2 is-centered">
							<div class="column is-4">
								<app-mobilebankingapp-logo
									:title="mobileBankingAppLogos2[2].title"
									:file-name="
										mobileBankingAppLogos2[2].fileName
									"
									v-infocus="'mobilebankingright4mobile'"
									class="hidden"
								/>
							</div>
							<div class="column is-4">
								<app-mobilebankingapp-logo
									:title="mobileBankingAppLogos2[3].title"
									:file-name="
										mobileBankingAppLogos2[3].fileName
									"
									v-infocus="'mobilebankingright4mobile'"
									class="hidden"
								/>
							</div>
						</div>
						<div
							class="
								download-mobile-banking-app
								is-mobile
								mt-2
								is-centered
								is-flex
								is-flex-direction-row
								is-justify-content-center
							"
						>
							<div class="is-flex is-align-items-center mr-4">
								<div class="is-flex-direction-column">
									<img
										:src="
											require('@/assets/mobilebankingapp/mobilebankingapp-logo.png')
										"
										alt="coopnet mobile logo"
										class="
											coopnet-logo-mobile-size
											is-align-self-center
											mt-5
                                            hidden
										"                                        
                                        v-infocus="'mobilebankingleft5mobile'"
									/>
									<p
										class="
											has-text-weight-bold
											is-size-5-mobile
                                            hidden
										"
                                        v-infocus="'mobilebankingleft5mobile'"
									>
										Coopnet
									</p>
								</div>
							</div>
							<div class="is-flex is-align-items-center">
								<div class="is-flex-direction-column">
									<p
										class="has-text-weight-semibold hidden"
										v-infocus="'mobilebankingright5mobile'"
									>
										Available on
									</p>
									<div>
										<a :href="googleplaystorelink" target="_blank">
											<img
												:src="
													require('@/assets/mobilebankingapp/Googleplay 1.png')
												"
												alt="coopnet mobile logo"
												class="
													is-align-self-center
													download-link-logo-mobile-size
													hidden
												"
												v-infocus="
													'mobilebankingright5mobile'
												"
											/>
										</a>
									</div>
									<div>
										<a :href="appleappstorelink" target="_blank">
											<img
												:src="
													require('@/assets/mobilebankingapp/appstore 1.png')
												"
												alt="coopnet mobile logo"
												class="
													is-align-self-center
													download-link-logo-mobile-size
													hidden
												"
												v-infocus="
													'mobilebankingright5mobile'
												"
											/>
										</a>
									</div>
									<div>
										<!-- <a :href="huaweiappgallerylink" target="_blank"> -->
											<img
												:src="
													require('@/assets/mobilebankingapp/appgallery 1.png')
												"
												alt="coopnet mobile logo"
												class="
													is-align-self-center
													download-link-logo-mobile-size
													hidden
												"
												v-infocus="
													'mobilebankingright5mobile'
												"
											/>
										<!-- </a> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MobileBankingAppLogoComponent from "@/components/MobileBankingApp/MobileBankingAppLogoComponent.vue";
import infocusDirective from "@/components/directives/infocusDirective.vue";

export default {
	name: "MobileBankingAppComponent",
	components: {
		"app-mobilebankingapp-logo": MobileBankingAppLogoComponent,
	},
	data() {
		return {
			mobileBankingAppLogos1: {
				0: {
					title: "Bills Payment",
					fileName: "/mobilebankingapp/billspayment.png",
				},
				1: {
					title: "Fund Transfer",
					fileName: "/mobilebankingapp/fundtransfer.png",
				},
				2: {
					title: "Bank Transfer",
					fileName: "/mobilebankingapp/banktransfer.png",
				},
				3: {
					title: "Remittance",
					fileName: "/mobilebankingapp/remittance.png",
				},
			},
			mobileBankingAppLogos2: {
				0: {
					title: "eLoad",
					fileName: "/mobilebankingapp/eload.png",
				},
				1: {
					title: "Loan Application",
					fileName: "/mobilebankingapp/loanapplication.png",
				},
				2: {
					title: "Cash In",
					fileName: "/mobilebankingapp/cashin.png",
				},
				3: {
					title: "More",
					fileName: "/mobilebankingapp/more.png",
				},
			},
			googleplaystorelink: "https://play.google.com/store/apps/details?id=com.traxiontech.cnm&fbclid=IwAR3pernvQ3uel3IF8iuVa2JQjKBPqIY4ewVqtfoXLm-g8iw1Aj0_qZgUsIU",
			appleappstorelink: "https://apps.apple.com/us/app/coopnet-mobile/id6443550847",
			huaweiappgallerylink: "#",
		};
	},
	directives: {
		infocus: infocusDirective,
	},
};
</script>

<style scoped>
.mobilebankingappdesktop, .mobilebankingappmobile {
	background-color: white;
}
.ellipsebackground{
	background-image: url(@/assets/mobilebankingapp/ellipse-background.png);
	height: 100%;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
}

.coopnet-logo-mobile-size {
	height: 6rem;
}
.download-link-logo-mobile-size {
	height: 3rem;
}
/* Hidden before animation plays */
.hidden {
	opacity: 0;
}

/* Animate.CSS */
/* Desktop Animations */
.mobilebankingapp1 {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 0.3s;
}
/* Mobile Animations */
.mobilebankingleft1mobile {
	opacity: 1;
	animation: fadeInLeft;
	animation-duration: 0.3s;
}
.mobilebankingright2mobile {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 0.6s;
}
.mobilebankingleft3mobile {
	opacity: 1;
	animation: fadeInLeft;
	animation-duration: 0.9s;
}
.mobilebankingright4mobile {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 1.2s;
}
.mobilebankingleft5mobile {
	opacity: 1;
	animation: fadeInLeft;
	animation-duration: 1.5s;
}
.mobilebankingright5mobile {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 1.5s;
}
</style>