<template>
	<div class="contact-us px-6" id="contactus">
		<div class="columns is-vcentered m-0">

            <!-- Traxion Team Photos -->
			<div class="column is-7 is-hidden-mobile">
				<div class="columns">
					<div class="column align-content-flex-start">
						<div>
							<img
								:src="require('@/assets/contactus/manila.jpg')"
								class="contact-us-group-photo"
								alt="image"
							/>
							<p
								class="
									is-italic
									has-text-black has-text-weight-bold
								"
							>
								MANILA TEAM
							</p>
						</div>
					</div>
					<div class="column is-flex align-content-end">
						<div>
							<img
								:src="
									require('@/assets/contactus/tagum-and-davao.jpg')
								"
								class="contact-us-group-photo"
								alt="image"
							/>
							<p
								class="
									is-italic
									has-text-black has-text-weight-bold
								"
							>
								TAGUM AND DAVAO TEAM
							</p>
						</div>
					</div>
				</div>
			</div>

			<!-- Normal Form 
                <div class="column is-4 is-hidden">
                    <p
                        class="
                            is-size-1 is-size-3-mobile
                            has-text-weight-semibold
                            my-2
                            hidden
                        "
                        :style="{ color: 'rgb(67, 62, 206)' }"
                        v-infocus="'contactus2'"
                    >
                        CONTACT US
                    </p>
                    <p
                        class="is-size-4 is-size-5-mobile hidden"
                        :style="{ color: 'rgb(51, 51, 51)' }"
                        v-infocus="'contactus2'"
                    >
                        Tell us a little bit about your situation, and we will get
                        back to you as soon as we can.
                    </p>
                    <div class="inputDiv mb-5 hidden" v-infocus="'contactus3'">
                        <b-field>
                            <span
                                class="
                                    mt-3
                                    has-text-danger has-font-weight-bold
                                    is-size-4
                                "
                                >*</span
                            >
                            <b-input
                                placeholder="Name"
                                :autocomplete="on"
                                custom-class="contactusinput is-size-6-mobile"
                                size="is-medium"
                                expanded
                                v-model="name"
                            />
                        </b-field>
                    </div>
                    <div class="inputDiv mb-5 hidden" v-infocus="'contactus3'">
                        <b-field>
                            <span
                                class="
                                    mt-3
                                    has-text-danger has-font-weight-bold
                                    is-size-4
                                "
                                >*</span
                            >
                            <b-input
                                placeholder="Email"
                                :autocomplete="on"
                                custom-class="contactusinput is-size-6-mobile"
                                size="is-medium"
                                expanded
                                v-model="email"
                            />
                        </b-field>
                    </div>
                    <div class="inputDiv mb-5 hidden" v-infocus="'contactus3'">
                        <b-field grouped>
                            <span
                                class="
                                    mt-3
                                    has-text-danger has-font-weight-bold
                                    is-size-4
                                "
                                >*</span
                            >
                            <b-input
                                placeholder="Message"
                                :autocomplete="on"
                                maxlength="200"
                                type="textarea"
                                custom-class="contactusinput is-size-6-mobile"
                                size="is-medium"
                                :has-counter="false"
                                expanded
                                v-model="message"
                            />
                        </b-field>
                    </div>
                    <b-button
                        class="contactussubmit-btn is-rounded hidden"
                        size="is-medium"
                        outlined
                        type="is-info"
                        :style="{
                            padding: '1.5rem 6rem',
                            backgroundColor: 'transparent',
                        }"
                        v-infocus="'contactus4'"
                        @click="sendEmail"
                    >
                        Submit
                    </b-button>
                </div>
            -->
			<!-- GForm Link -->
			<div class="column">
				<p
					class="
						is-size-1
						has-text-weight-semibold
						is-size-4-mobile
						mt-1
						mb-3
                        px-6
                        px-4-mobile
					"
					:style="{ color: 'black' }"
				>
					CONTACT
					<span :style="{ color: 'rgb(51, 157, 255)' }">US</span>
				</p>
				<p
					class="is-size-5 is-size-6-mobile has-text-weight-semibold mb-3"
					:style="{ color: 'black' }"
				>
					Tell us a little bit about your situation, and we will get
					back to you as soon as we can.
				</p>
				<a :href="formLink" target="_blank" class="is-hidden-mobile">
					<b-button
						class="contactussubmit-btn is-rounded"
						size="is-medium"
						outlined
						type="is-info"
						:style="{
							padding: '1.5rem 6rem',
							backgroundColor: 'transparent',
						}"
					>
						Open Form
					</b-button>
				</a>
                <a :href="formLink" target="_blank" class="is-hidden-tablet">
					<b-button
						class="contactussubmit-btn-mobile is-rounded is-size-6-mobile mb-6"
						size="is-medium"
						outlined
						type="is-info"
						:style="{
							padding: '0.5rem 2rem',
							backgroundColor: 'transparent',
						}"
					>
						Open Form
					</b-button>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import infocusDirective from "@/components/directives/infocusDirective.vue";

export default {
	name: "ContactUsComponent",
	directives: {
		infocus: infocusDirective,
	},
	data() {
		return {
			name: null,
			email: null,
			message: null,
			emailSent: false,
			formLink: "https://forms.clickup.com/9008000595/f/8cepbjk-79478/ASLM1J6SY305JQVYQ5",
		};
	},
	methods: {
		// sendEmail() {
		// 	const payload = {
		// 		name: this.name,
		// 		email: this.email,
		// 		message: this.message,
		// 	};
		// 	const url =
		// 		"https://5d748e521c757d261d2c629c5be84207.m.pipedream.net";

		// 	this.$http
		// 		.post(url, payload, {
		// 			headers: {
		// 				"Content-Type": "application/json",
		// 			},
		// 		})
		// 		.then((response) => {
		// 			console.log("success");
		// 			console.log(response);
		// 			alert("Email Sent!");
		// 			this.clearFields();
		// 		})
		// 		.catch((error) => {
		// 			console.log("error");
		// 			console.log(error);
		// 			alert("Error in sending email. Please try again");
		// 			this.clearFields();
		// 		});
		// },

		// clearFields() {
		// 	this.name = null;
		// 	this.email = null;
		// 	this.message = null;
		// },
	},
};
</script>

<style scoped>
.contactusinput {
	background-color: transparent !important;
	border-color: transparent !important;
	box-shadow: none !important;
	color: rgb(51, 51, 51) !important;
	padding-bottom: 0rem !important;
	padding-left: 0.5rem !important;
}

.contactussubmit-btn,
.contactussubmit-btn:hover,
.contactussubmit-btn:focus {
	color: rgb(51, 157, 255) !important;
}

.contactusinput:active,
.contactusinput:hover,
.contactusinput:focus {
	border-color: transparent !important;
	color: rgb(51, 157, 255) !important;
}
.contactusinput:hover {
	border-color: inherit;
}
.inputDiv {
	border-bottom: 2px solid rgb(181, 181, 181);
}

/* Hidden before animation plays */
.hidden {
	opacity: 0;
}
/* Animate.CSS */
.contactus1 {
	opacity: 1;
	animation: fadeinRight;
	animation-duration: 1s;
}
.contactus2 {
	opacity: 1;
	animation: fadeinRight;
	animation-duration: 1s;
}
.contactus3 {
	opacity: 1;
	animation: fadeInUp;
	animation-duration: 1s;
}
.contactus4 {
	opacity: 1;
	animation: fadeInUp;
	animation-duration: 1s;
}
</style>