<template>
	<div id="head">
		<!-- DESKTOP -->
		<div class="head main-bg is-hidden-mobile">
			<!-- Top Navbar -->
			<b-navbar>
				<template #brand>
					<b-navbar-item
						tag="router-link"
						:to="{ path: '/' }"
						class="is-size-5"
					>
						<img
							src="@/assets/coopnet-logo.94f920f2.png"
							alt="Coopnet Logo"
						/>
					</b-navbar-item>
				</template>
				<template #start>
					<b-navbar-item href="#" class="is-size-5 desktop-only">
						Home
					</b-navbar-item>
					<b-navbar-item
						href="#whoweserve"
						class="is-size-5 desktop-only"
					>
						Who we serve
					</b-navbar-item>
					<b-navbar-item
						href="#coopnetmachine"
						class="is-size-5 desktop-only"
					>
						About CTM
					</b-navbar-item>
					<!--
						<b-navbar-item
							href="#ourteam"
							class="is-size-5 desktop-only"
						>
							Our Team
						</b-navbar-item>
					-->
					<b-navbar-item
						href="#contactus"
						class="is-size-5 desktop-only"
					>
						Contact Us
					</b-navbar-item>
				</template>
				<template #end>
					<b-navbar-item tag="div" class="is-hidden"> </b-navbar-item>
				</template>
			</b-navbar>

			<!-- Head Body -->
			<div
				class="head-body columns is-vcentered"
				:style="{ padding: '0px 50px' }"
			>
				<div
					class="
						column
						has-text-left
						is-flex is-flex-direction-column is-align-items-center
						animate__animated animate__fadeInUp animate__delay-0.3s
					"
				>
					<img
						:src="require('@/assets/coopnet-logo-2.afcec3ef.png')"
						alt="Coopnet Logo"
					/>
					<p
						class="
							has-text-centered has-text-weight-semibold
							is-size-3
						"
					>
						“Our new cooperation network”
					</p>
					<a href="#contactus">
						<b-button
							type="is-info"
							class="button ml-4 p-5 get-started-btn"
							outlined
							rounded
						>
							Get Started
						</b-button>
					</a>
				</div>
				<div class="column is-5">
					<b-carousel
						:indicator-inside="false"
						:indicator="false"
						:arrow="false"
						:pause-info="false"
					>
						<b-carousel-item>
							<img
								:src="
									require('@/assets/ctm-sidever.ea53acd5.png')
								"
							/>
						</b-carousel-item>
						<b-carousel-item>
							<img
								:src="
									require('@/assets/coop-mobile.791fa1f6.png')
								"
							/>
						</b-carousel-item>
						<b-carousel-item>
							<img :src="require('@/assets/PH-MAP 1.png')" />
						</b-carousel-item>
					</b-carousel>
				</div>
			</div>
		</div>
		<!-- MOBILE -->
		<div class="headmobile main-bg-mobile is-hidden-tablet">
			<!-- Top Navbar -->
			<b-navbar>
				<template #brand>
					<b-navbar-item
						tag="router-link"
						:to="{ path: '/' }"
						class="is-size-5 is-hidden-mobile"
					>
						<img
							src="@/assets/coopnet-logo.94f920f2.png"
							alt="Coopnet Logo"
						/>
					</b-navbar-item>
				</template>
				<template #start>
					<b-navbar-item href="#" class="has-text-weight-light">
						Home
					</b-navbar-item>
					<b-navbar-item
						href="#whoweserve"
						class="has-text-weight-light"
					>
						Who we serve
					</b-navbar-item>
					<b-navbar-item
						href="#coopnetmachine"
						class="has-text-weight-light"
					>
						About CTM
					</b-navbar-item>
					<!--
						<b-navbar-item
							href="#ourteam"
							class="has-text-weight-light"
						>
							Our Team
						</b-navbar-item>
					-->
					<b-navbar-item
						href="#contactus"
						class="has-text-weight-light"
					>
						Contact Us
					</b-navbar-item>
				</template>
				<template #end>
					<b-navbar-item tag="div" class="is-hidden"> </b-navbar-item>
				</template>
			</b-navbar>

			<!-- Head Body -->
			<div
				class="head-body columns is-vcentered"
				:style="{ padding: '0px 50px' }"
			>
				<div class="column">
					<img
						:src="require('@/assets/coopnet-logo-2.afcec3ef.png')"
						alt="Coopnet Logo"
						class="
							animate__animated
							animate__fadeInUp
							animate__delay-0.3s
						"
					/>
					<p
						class="
							is-size-3 is-size-5-mobile
							has-text-centered
							animate__animated
							animate__fadeInUp
							animate__delay-0.6s
						"
						v-infocus="'head2'"
					>
						“OUR NEW
						<br />
						COOPERATION
						<br />
						NETWORK”
					</p>
					<a href="#contactus" class="has-text-centered">
						<b-button
							type="is-info"
							class="
								button
								get-started-btn
								animate__animated
								animate__fadeInUp
								animate__delay-0.9s
							"
							outlined
							rounded
						>
							Get Started
						</b-button>
					</a>
				</div>
				<div class="column is-5">
					<b-carousel
						class="mb-3"
						:indicator-inside="false"
						:indicator="false"
						:arrow="false"
						:pause-info="false"
					>
						<b-carousel-item>
							<b-image
								:src="
									require('@/assets/ctm-sidever.ea53acd5.png')
								"
							></b-image>
						</b-carousel-item>
						<b-carousel-item>
							<b-image
								:src="
									require('@/assets/coop-mobile.791fa1f6.png')
								"
							></b-image>
						</b-carousel-item>
						<b-carousel-item>
							<b-image
								:src="require('@/assets/PH-MAP 1.png')"
							></b-image>
						</b-carousel-item>
					</b-carousel>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import infocusDirective from "@/components/directives/infocusDirective.vue";

export default {
	name: "HeadComponent",
	directives: {
		infocus: infocusDirective,
	},
};
</script>

<style scoped>
.get-started-btn {
	background-color: transparent;
	font-size: 1.125rem;
	padding-left: 2.25em;
	padding-right: 2.25em;
	margin-top: 1rem;
}
div.carousel-pause {
	display: none !important;
}
.navbar {
	padding: 24px 64px 20px 0;
	background-color: transparent !important;
}
.navbar-menu > .navbar-start > a.navbar-item:hover {
	background-color: transparent;
	color: #339dff;
}

/* Desktop Specific */
.main-bg {
	background-image: url(@/assets/home-bg.a2b2cbed.png);
	height: 100%;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 100vh;
}

.navbar-brand > a.navbar-item.desktop-only {
	margin-left: 2rem;
}
.navbar-menu > .navbar-start > a.navbar-item.desktop-only {
	margin-left: 2rem;
	cursor: pointer;
}

.navbar-brand > a.navbar-item > img {
	max-height: 5.375rem;
	margin-left: 2rem;
}

/* Mobile */
.main-bg-mobile .navbar {
	padding: 24px;
}

/* Animate.CSS */
/* Desktop Animations */
.head3 {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 1s;
}
</style>