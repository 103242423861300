import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "bulma";
import Buefy from "buefy";
import "buefy/dist/buefy.css";

import "animate.css";

import axios from 'axios';

import VueMask from 'v-mask'
Vue.use(VueMask);

Vue.config.productionTip = false;
Vue.use(Buefy, {});
Vue.prototype.$http = axios;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
