<template>
	<div id="terms-of-use">
        <div class="terms-of-use main-bg is-hidden-mobile">
            <div class="columns is-flex is-vcentered rounded-div">
                <div class="column is-centered">
                    <h1 class="coopnet-text-title is-size-2"> Terms of Use </h1>

                    <hr>

                    <p class="coopnet-text-primary is-size-5 has-text-centered">CoopNET Mobile SERVICE AGREEMENT</p>

                    <p class="coopnet-text-paragraph is-size-6">
                        This Agreement was made on 7th of September 2022, by and between:
                    </p>

                    <p class="coopnet-text-paragraph is-size-6">
                        CoopNET Mobile,
                    </p>

                    <p class="coopnet-text-paragraph is-size-6">
                        hereinafter referred to as the “the <span class="has-text-weight-bold">Supplier</span>”,
                    </p>

                    <p class="coopnet-text-paragraph is-size-6">
                        and
                    </p>

                    <p class="coopnet-text-paragraph is-size-6">
                        <span class="has-text-weight-bold">[ Cooperative ]</span>, a company incorporated under the laws of the Republic of the Philippines, having its registered office at [ Address ], duly represented by: <span class="has-text-weight-bold is-underlined">[ Cooperative ]</span>,
                    </p>

                    <p class="coopnet-text-paragraph is-size-6">
                        hereinafter referred to as “the <span class="has-text-weight-bold">Customer</span>”,
                    </p>

                    <p class="coopnet-text-paragraph is-size-6">
                        The Supplier and the Customer hereinafter jointly referred to as “the <span class="has-text-weight-bold">Parties</span>” or individually as “the <span class="has-text-weight-bold">Party</span>”.
                    </p>

                    <br>

                    <p class="coopnet-text-primary is-size-5 ">1. Introductory Letter</p>

                    <p class="coopnet-text-subtitle is-size-6">Definition of parties</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        These Terms of Use (the “Terms” or “Agreement”) cover your rights and obligations relating to your access and use of our app, Payment Processing Services (as defined below), as well as any other related products and services, including our APIs (as defined below) and API documentation (collectively, the “Service”). All references to “we”, “us”, “our”, or “CoopNET Mobile” refer to Traxion Tech, Inc., a corporation organized under the laws of the Philippines. All references to “you”, “your”, or “user” relate to the person or entity who registered an account on CoopNET Mobile (an “Account”) to use or access the Services. In addition to these Terms, please review our Privacy Policy, which describes our practices related to collection and use of your information to provide the Service. These Terms apply to our Privacy Policy as well.
                    </p>
                    
                    <p class="coopnet-text-subtitle is-size-6">Electronic Acceptance</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        By ticking the checkbox to indicate your acceptance to these Terms and our Privacy Policy, or otherwise by creating an Account and using the Service, you represent and agree that you have read, understand, and agree to be bound by both these Terms and our Privacy Policy as binding agreements. Further, you agree that these Terms and our Privacy Policy apply to your past use, if any, of the Service prior to the Effective Date.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Waiver of rights</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Please read these Terms carefully, as they contain important information about your rights and responsibilities, including limitation of our Liability and Binding Arbitration. If you do not accept this Agreement in its entirety, you may not access or use the service.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Support</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Do not hesitate to contact us if you have any questions or want to discuss either of these important documents.
                    </p>

                    <p class="coopnet-text-primary is-size-5 ">2. General Terms</p>

                    <p class="coopnet-text-subtitle is-size-6">Service Overview</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        The Service fundamentally provides you with the software and connectivity to allow real-time secure data transmission for processing debit cards, credit cards and certain other available payment methods (each, a “Payment Method”) provided by a Payment Method provider (each, a “Payment Method Provider”) on your website or application (the “Payment Processing Services”) that allows customers (your “Customers”) to purchase your goods and services.
                        <br><br>
                        Other Payment Methods. Other Payment Methods may include e-wallets (including but not limited to GCash, PayMaya, Coins.ph, GrabPay, WeChat Pay, and Alipay) and cash-in methods (including but not limited to 7-Eleven Instant Cash In, M Lhuiller, and Cebuana Lhuillier).
                        <br><br>
                        Credit Card. Payment Method Providers may include Visa and MasterCard. Your use of a Payment Method may be subject to separate terms applicable to the Payment Method, including but not limited to Third-Party Rules (as defined below). We may add or remove Payment Method Providers and Acquirers (as defined below) at any time. Account Creation. Subject to these Terms, users that create an Account through the Services and complete our verification requirements, as set forth below, will be able to use the Payment Processing Services. The Services also include software, APIs and any other technology as described on the CoopNET Mobile website.
                    </p>
                    
                    <p class="coopnet-text-subtitle is-size-6">Age Policy; Permitted Users</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        We do not intend that the Service be used by anyone under 18 years old. Thus, by creating an Account and using the Service, you represent and warrant that you are at least 18 years old and can validly transact on your own behalf. If we learn or have reason to suspect that a user is under 18 years of age, we will promptly revoke the user’s access to the Service and delete any personally identifiable information submitted by that user. Further, you represent and warrant that your use of the Service does not and will not conflict with any pre-existing obligation in conflict or in any way inconsistent with the provisions of these Terms.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Account Registration - Basic Requirement</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        To access our Payment Processing Services, you will need to register an Account using your
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>first and last name,</li>
                        <li>e-mail address,</li>
                        <li>contact number,</li>
                        <li>address and the</li>
                        <li>name of your business (please see our Privacy Policy for more information on our practices related to collection and use of your data), and you agree to our use of such information.</li>
                        <li>You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it up to date.</li>
                    </ol>
                    <p class="coopnet-text-paragraph is-size-6">
                        We reserve the right to suspend or terminate your Account according to these Terms if we determine that any information provided by you is inaccurate or incomplete.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Account Registration - Password Requirement</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        As part of the Account registration process, you will also be asked to create a password and a business handle (i.e. an identifier of your business which will be part of the URL that you create and share with your Customers) (your “Handle”).
                        Your business name, Handle, and/or the URL may appear on your Customers’ bank or other statements. To minimize confusion and avoid potential disputes, these descriptors must be recognizable to your Customers and must accurately describe your business or activities.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Account Registration - Authorization or Power of Attorney</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        By registering for an Account on behalf of an entity, you represent and warrant that you have authority to
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>accept these Terms on behalf of and bind such entity, and</li>
                        <li>provide information on behalf of such entity.</li>
                    </ol>
                    <p class="coopnet-text-paragraph is-size-6">
                        We may require you to provide additional information or documentation demonstrating such authority to bind the entity.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Account Registration - Prohibited Accounts</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Without our express written consent, you may not register or attempt to register for an Account on behalf of a user of CoopNET Mobile whose Account was previously terminated and is otherwise barred from further use of the Services.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Responsibility to Saferguard Accounts and Limited Liability</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        If you become aware that an unauthorized third party is using your information to access the Service, you must promptly notify us of such use. You agree that we will not be liable for any loss or harm that you incur if someone else uses your information to access the Service, either with or without your knowledge, and that you will be solely responsible for such use, whether or not you authorized the use. You are responsible for safeguarding your Account information, including your password. You agree not to disclose your password to, or share your Account with, any third party. You are solely responsible for maintaining and protecting your username and password.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Responsibility on maintaining account information</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        You agree to keep the information in your Account current. You must promptly update your Account with any changes affecting you,
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>the nature of your business activities,</li>
                        <li>beneficial owners, principals,</li>
                        <li>changes to the legal entity that operates your business (for example, if you incorporate an entity), or</li>
                        <li>any other pertinent information.</li>
                    </ol>
                    <p class="coopnet-text-paragraph is-size-6">
                        We may suspend your Account or terminate this Agreement if you fail to keep this information current.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Right to Verify Identity and managing information</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Compliance to Anti Money Laundering Law. As a provider of payment services, we are required to comply with many regulations, including anti-money laundering laws. As such, we are required to obtain information about and verify the identity of our users. To comply with our obligations under applicable laws, we will request that you provide certain information to us about you after you create your Account. This information will be used by us for the purposes of identity verification and the detection of money laundering, terrorist financing, fraud, or any other financial crimes. You agree that all such information provided by you will be complete and accurate, that you will keep us updated if any of the information you provided changes, and that we may keep a record of such information consistent with our internal policies.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Authorization to verify and collect private information.</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Authorization to gather and collect information. In addition to collecting information from you, we are required to take reasonable steps to verify your identity as a user. You expressly authorize us to take any and all actions that we reasonably deem necessary to verify your identity or protect you and/or us against fraud or other financial crime.
                        <br><br>
                        Engaging Third parties to Verify Information. These may include, but are not limited to, engaging third-party services to assist with such verification efforts, sharing your information with such third parties, and collecting additional information about you from such third parties. Any such third party to whom we disclose or transfer information about you for the above purpose is contractually required to comply with confidentiality standards and establish adequate safeguards for data privacy, undertake to respect any user’s right to privacy and comply with applicable laws. We also require that said third parties use information shared with them only for the above purposes and follow our reasonable directions with respect to this information. Please refer to the List of Third Parties engaged by CoopNET Mobile for this purpose. Please also refer to our Privacy Policy for further details.
                        <br><br>
                        Right to terminate services. Until you have submitted all required information, and subject to our review and approval, your Account will be available to you on a preliminary basis only (i.e. although users may be allowed to conduct test transactions to ensure proper integration with our Payment Processing Services, users will not be able to conduct Transactions), and we may terminate it at any time and for any reason.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Sharing information to Payment Method Providers and Acquirers</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Throughout the term of this Agreement, we may share information about your Account with Payment Method Providers and Acquirers (as defined below) in order to verify your eligibility to use the Payment Processing Services, establish any necessary accounts or credit with Payment Method Providers and Acquirers, monitor Charges (as defined below) and other activity, and conduct risk management and compliance reviews.
                        <br><br>
                        We may also share your personally identifiable information with Payment Method Providers and Acquirers for the purpose of facilitating the compliance of CoopNET Mobile, the Payment Method Providers, and the Acquirers with applicable laws and regulations, as well as any rules, guidelines, or by-laws of any of the Payment Method Providers and/or Acquirers (“Third-Party Rules”). Any Payment Method Provider or Acquirer to whom we disclose information about your Account is contractually required to comply with confidentiality standards and establish adequate safeguards for data privacy, undertake to respect any user’s right to privacy and comply with applicable laws. We also require that these Payment Method Providers or Acquirers use information shared with them only for the above purposes and follow our reasonable directions with respect to this information. Please refer to the List of Third Parties for the list of Payment Method Providers or Acquirers who will have access to information about your account. Please also refer to our Privacy Policy for further details.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Additional Verification</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        In addition to the above, at any time during your use of the Services, we may require additional information from you to verify beneficial ownership or control of the business, validate information you provided, verify your identity, and assess the risk associated with your business. This additional information may include -
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>phone numbers,</li>
                        <li>physical and mailing addresses for you and your business,</li>
                        <li>your business’s tax identification number,</li>
                        <li>business invoices,</li>
                        <li>copies of government-issued identification,</li>
                        <li>business licenses, or</li>
                        <li>other information related to your business, its beneficial owners or principals.</li>
                        <li>We may also request that you provide copies of financial statements or records pertaining to your compliance with this Agreement or require you to provide a personal or company guarantee.</li>
                    </ol>
                    <p class="coopnet-text-paragraph is-size-6">
                        Your failure to provide this information or material may result in suspension or termination of your Account. We may share this information, including any other personally identifiable information you provide to us, with our third-party vendors we use to comply with many regulations, including anti-money laundering laws, subject to compliance with same requirements and limitations as provided in Account Verification Clause above.
                    </p>

                    <p class="coopnet-text-primary is-size-5 ">3. Technology and Services</p>

                    <p class="coopnet-text-subtitle is-size-6">Services Overview</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        The Payment Processing Services give you the ability to accept Payment Methods from your Customers in exchange for your goods or services. In order to provide you with access to the Payment Methods and Payment Processing Services, we work with Payment Method Providers, Acquirers (as defined below), and other third parties.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Definition of Terms</p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>Acquirer means a financial institution that is authorized by a Payment Method Provider to accept Charges on behalf of the Payment Method Provider;</li>
                        <li>Charge means a credit or debit instruction to capture funds from an account that a Customer maintains with a bank or other financial institution in connection with a Transaction;</li>
                        <li>Chargeback means an instruction initiated by a Customer for the return of funds for an existing Charge;</li>
                        <li>Chargeback means an instruction initiated by a Customer for the return of funds for an existing Charge;</li>
                        <li>Refund means a refund issued by you through the Payment Processing Services;</li>
                        <li>Reserve means an amount or percentage of your Payouts that CoopNET Mobile holds in order to protect against the risk of Reversals, Chargebacks, or any other risk, exposure and/or potential liability to us related to your use of the Payment Processing Services; and</li>
                        <li>Reversal means an instruction initiated by a Payment Method Provider, an Acquirer, or CoopNET Mobile to return funds for an existing Charge.</li>
                    </ol>

                    <p class="coopnet-text-subtitle is-size-6">Use of Service</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        You may only use Payment Processing Services to facilitate a purchase, sale, donation, order, or other transaction (each a “Transaction”) with your Customers. You may not use Payment Processing Services to send money to others, to conduct any personal transactions, or for any other purposes prohibited by this Agreement. You represent and warrant to CoopNET Mobile that each Transaction that you process using the Payment Processing Services is solely in payment for your provision or sale of bona fide goods or services (including charitable services) to your Customers.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Restricted Business Activities</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        You must use the Services in a lawful manner, and must obey all laws, rules, and regulations applicable to your use of the Services and to Transactions. You may not use the Services to enable any person (including you) to benefit from any activities CoopNET Mobile has identified as a restricted business or activity (collectively, “Restricted Businesses”). Restricted Businesses include use of the Services in or for the benefit of a country, organization, entity, or person embargoed or blocked by any government.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Restricted Business Activities Inclusions</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Restricted Businesses shall also include, but not be limited to, any party participating in:
                        <br><br>
                        Any activities that violate these Terms, any Third-Party Rules, or any other agreement that you have entered into with us or any of our agents in connection with the Payment Processing Services;
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>Any activities that violate applicable law;</li>
                        <li>Investment and credit services;</li>
                        <li>Money and legal services, including money transmitters and money services businesses, check cashing businesses, currency exchanges or dealers, bill-pay services, crowdfunding, insurance, bail bonds, collections agencies, law firms collecting funds for any purpose other than to pay fees owed to the firm for services provided by the firm;</li>
                        <li>Virtual currency, including virtual currency that can be monetized, resold, or converted to physical or digital products and services or otherwise exit the virtual world (for example, Bitcoin);</li>
                        <li>Gambling, including internet gambling, lotteries, bidding fee auctions, sports betting, internet gaming, contests, sweepstakes, games of chance including legal or illegal forms of gambling;</li>
                        <li>Adult services, including pornography and other obscene materials (including literature, imagery and other media) depicting nudity or explicitly sexual acts, sites offering any sexually-related services such as prostitution, escorts, pay-per view, adult live chat features, sexually oriented items (for example, adult toys), adult video stores and sexually oriented massage parlors, gentleman’s clubs, topless bars, and strip clubs, sexually oriented dating services;</li>
                        <li>Illegal drugs or drug paraphernalia;</li>
                        <li>Infringement of Intellectual property or proprietary rights; counterfeit or unauthorized goods;</li>
                        <li>The sale of illegal products or services;</li>
                        <li>Unfair, predatory, or deceptive practices, including pyramid or “get-rich-quick” schemes; and</li>
                        <li>Any products or services that are otherwise restricted by third parties we work with in providing you with the Service, including but not limited to Payment Method Providers.</li>
                    </ol>

                    <p class="coopnet-text-subtitle is-size-6">List of Restricted Businesses</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Please review the list of Restricted Businesses thoroughly before registering for and opening an Account. If you are uncertain whether a category of business or activity is restricted or have questions about how these restrictions apply to you, please contact us. We may add to or update the Restricted Business List at any time.
                    </p>

                    <p class="coopnet-text-primary is-size-5 ">4. Disputes, Reversals, Refunds, and Chargebacks</p>

                    <p class="coopnet-text-subtitle is-size-6">Acting Agents</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        You may only submit charges through the Payment Processing Services that are authorized by your Customers. To enable us to process Transactions for you, you authorize and direct us, our affiliates, the Payment Method Providers and Acquirers to receive and settle any amounts due to you from your Transactions through the Payment Processing Services. You may not grant or assign any interest in the amounts due to you from your Transactions to any third party until such time as such amounts are deposited into your Bank Account (as defined below). You appoint CoopNET Mobile as your agent for the limited purpose of directing, receiving, holding and settling such amounts. You agree that CoopNET Mobile’s receipt of such amounts satisfies the relevant end-customer’s obligations to make payments to you. We will promptly update your Account balance to reflect any such amounts that we receive on your behalf.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Limited Liability</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        You understand that even authorized Transactions may be subject to a Chargeback. We are not responsible for or liable to you for authorized and completed Charges that are later the subject of a Chargeback, Refund, or Reversal, are submitted without authorization or in error, or violate any applicable laws or regulations.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">General Liability</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        You are immediately responsible to us for all Chargebacks, Refunds, Reversals, or Fines (as defined below) regardless of the reason or timing. We may decline to act upon a Refund instruction, or delay execution of the instruction, if:
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>it would cause your Account balance to become negative;</li>
                        <li>you are the subject of bankruptcy proceedings; or</li>
                        <li>where we otherwise believe that there is a risk that you will not meet your liabilities under this Agreement (including with respect to the Charge that is the subject of the Refund instruction).</li>
                    </ol>
                    <p class="coopnet-text-paragraph is-size-6">
                        Please note that Payment Method Providers and/or Acquirers may also initiate Reversals.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Non-Bank Institution declaration</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        CoopNET Mobile is not a bank and we do not accept deposits, provide loans or extend credit. If you accept payment for products or services not immediately deliverable to the Customer, we may, in our sole discretion, initiate a Reversal. Reversals may also result from
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>invalidation of a charge by a Payment Method Provider or an Acquirer;</li>
                        <li>funds settled to you in error or without authorization; and</li>
                        <li>submission of a Charge in violation of this Agreement, Third-Party Rules, and/or applicable laws and regulations.</li>
                    </ol>

                    <p class="coopnet-text-subtitle is-size-6">Chargebacks Disputes</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        You may be able to challenge a Chargeback by submitting evidence to us through various channels, including email or as otherwise indicated by CoopNET Mobile. In the future, we may also allow you submit such evidence through the CoopNET Mobile Dashboard (the “Dashboard”). We may request additional information to provide to Payment Method Providers and Acquirers to assist you in contesting the Chargeback, but we cannot guarantee that your challenge will be successful. Payment Method Providers and Acquirers may deny your challenge for any reason they deem appropriate. You may not submit a new Charge which duplicates a Transaction that is subject to a Chargeback.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Liability against losses caused by Fraud</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        You are liable for all losses you incur when lost or stolen payment credentials or accounts are used to purchase products or services from you. CoopNET Mobile does not and will not insure you against losses caused by fraud under any circumstances.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Customer Support</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        CoopNET Mobile will provide you with support in the event you have general issues relating to your Account or your use of the Payment Processing Services. We may make this support available to you via our website or our API documentation. If you cannot resolve the issues you are having with the support we make available to you via our website or our API documentation, please feel free to contact us.
                    </p>

                    <p class="coopnet-text-primary is-size-5 ">5. Taxes, Fees and Penalties</p>
                    <p class="coopnet-text-subtitle is-size-6">Fees and Penalties</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        CoopNET Mobile will provide the Payment Processing Services to you at the rates and for the fees (the “Fees”) described on the CoopNET Mobile website. We may revise the Fees at any time. In case Fees are revised, CoopNET Mobile will provide you with no less than 30 days advance notice of such change.
                        <br><br>
                        In addition to the Fees, you are also responsible for any penalties or fines imposed on you in relation to your Account by CoopNET Mobile or any Payment Method Provider or Payment Method Acquirer resulting from your use of Payment Processing Services in a manner not permitted by this Agreement or a Payment Method Provider’s Third-Party Rules (each such fine or penalty, a “Fine”).
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Taxes</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Our fees are exclusive of any applicable Taxes, except as expressly stated to the contrary. You shall pay, indemnify, and hold CoopNET Mobile harmless from
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>any sales, use, excise, import or export, value-added, or similar tax or duty, and any other tax or duty not based on CoopNET Mobile’s income, and</li>
                        <li>all government permit fees, customs fees and similar fees which CoopNET Mobile may incur with respect to this Agreement.</li>
                    </ol>
                    <p class="coopnet-text-paragraph is-size-6">
                        Such taxes, fees and duties paid by you shall not be considered a part of, a deduction from, or an offset against, payments due to CoopNET Mobile hereunder.
                        <br><br>
                        Additionally, you understand that we may send documents to you and tax authorities for Transactions processed using the Services. Pursuant to applicable law, we may be required to file periodic informational return with taxing authorities in relation to your use of the Payment Processing Services.
                    </p>


                    <p class="coopnet-text-primary is-size-5 ">6. Account Settlement</p>
                    <p class="coopnet-text-subtitle is-size-6">Accounts Settlement General</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Subject to the terms of this Agreement, CoopNET Mobile will instruct the Acquirer to remit to the bank or other financial institution account that you designate (your “Bank Account”), all amounts due to you from your Transactions, minus any Fees, Fines, Reversals, Chargebacks, Refunds, setoffs, recoupments or other amounts that you owe to CoopNET Mobile under this Agreement. You affirm that you are authorized to initiate settlements to and debits from your Bank Account, and that your Bank Account is owned by you. If you update your Bank Account, then you must ensure that you continue to comply with the requirements of this section. We may require you to provide us with documentary proof demonstrating your compliance with this section, and your failure to provide such proof will constitute a breach of this Agreement.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Accounts Settlement Insufficient Pay-out</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        If the Payout is not sufficient to cover the amounts due, without limiting our other rights and remedies (all of which are cumulative), you agree that we may debit your Bank Account for the applicable amounts, and/or set-off the applicable amounts against future Payouts. Upon our request, you agree to provide us with all necessary bank account routing and related information and grant us permission to debit amounts due from your Bank Account.
                        <br>
                        We may require a holding period before making initial settlement to your Bank Account.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Pay-out schedule</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Your Payout Schedule (i.e. the time it takes for CoopNET Mobile to initiate settlement to your Bank Account) will be specified on your Dashboard, or as otherwise designated by CoopNET Mobile. Please be aware that a Payment Method Provider, an Acquirer, or the financial institution holding your Bank Account may delay settlement for any reason (and thus delay your Payout Schedule), and we are not responsible for any harm suffered by you stemming from such delay. We are not responsible for any action taken by the institution holding your Bank Account to not credit your Bank Account or to otherwise not make funds available to you as you expected. 
                    </p>
                    
                    <p class="coopnet-text-subtitle is-size-6">Right to Suspend settlement</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        We reserve the right to suspend settlement to you. Examples of situations where we may do so are:
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>where there are pending, anticipated, or excessive Chargebacks, Refunds, or Reversals;</li>
                        <li>in the event that we suspect or become aware of suspicious activity; or</li>
                        <li>where we are required by applicable law or court order.</li>
                    </ol>
                    <p class="coopnet-text-paragraph is-size-6">
                        We have the right to withhold settlement to your Bank Account upon termination of this Agreement if we reasonably determine that we may incur losses resulting from credit, fraud, or other legal risks associated with your Account. If we exercise our right to withhold a Payout for any reason, we will communicate the general reason for withholding the Payout and give you a timeline for releasing the funds.
                    </p>

                    <p class="coopnet-text-primary is-size-5 ">7. Reserves</p>
                    <p class="coopnet-text-subtitle is-size-6">General Provision</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        CoopNET Mobile, in its sole discretion, may place a Reserve on a portion of your Payouts in the event that we believe that there is a high level of risk associated with your business. If we place a reserve on your Payouts, we will provide you with notice specifying the terms of the Reserve. The terms may require that a certain percentage of your Payouts are held for a certain period of time, that a fixed amount of your Payouts are withheld from payout to you, or such other restrictions that CoopNET Mobile determines in its sole discretion. CoopNET Mobile may change the terms of the Reserve at any time by providing you with notice of the new terms. 
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Managing Reserves</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        CoopNET Mobile may hold a Reserve as long as it deems necessary, in its sole discretion, to mitigate any risks related to your Transactions. You agree that you will remain liable for all obligations related to your Transactions even after the release of any Reserve. In addition, we may require you to keep your Bank Account available for any open settlements, Chargebacks and other adjustments. 
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Security Interest</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        As security for the payment and performance of all of your now existing or hereinafter arising or incurred obligations under this Agreement or any other obligation to CoopNET Mobile, you grant to CoopNET Mobile a lien on, and security interest in and to, all your now existing or hereafter right, title and/or interest in, to or under this Agreement, the Reserve, all funds held in Reserve, all Reserves at any time established in accordance with this Agreement, and all proceeds of any of the foregoing. You agree to duly execute and deliver to CoopNET Mobile such instruments and documents as may be required to perfect and otherwise give effect to the lien and security interest granted to CoopNET Mobile under this Agreement. 
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Dormant Accounts</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        If you leave any funds dormant in your Account for a period of ten (10) years and you do not give us instructions where to send them, we may be required by applicable law to deem the funds to be abandoned by you, and to deliver them to the Philippine government. To the extent required by applicable law, we will attempt to provide you with notice if we hold funds payable to you in an account beyond the applicable dormancy period for abandoned property. 
                    </p>

                    <p class="coopnet-text-primary is-size-5 ">8. Suspension or Refusal of Transactions</p>
                    <p class="coopnet-text-subtitle is-size-6">Suspension or Refusal of Transactions</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        CoopNET Mobile may refuse, condition, or suspend any Transactions that we believe:
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>may violate this Agreement or other agreements you may have with us;</li>
                        <li>are unauthorized, fraudulent or illegal; or</li>
                        <li>expose you, CoopNET Mobile, or others to risks unacceptable to CoopNET Mobile.</li>
                    </ol>
                    <p class="coopnet-text-paragraph is-size-6">
                        If we suspect or know that you are using or have used the Services for unauthorized, fraudulent, or illegal purposes, we may share any information related to such activity with the appropriate financial institution, regulatory authority, or law enforcement agency consistent with our legal obligations. This information may include information about you, your Account, your Customers, and Transactions made through your use of the Payment Processing Services.
                    </p>
                    
                    <p class="coopnet-text-primary is-size-5 ">9. Customer relationship</p>
                    <p class="coopnet-text-subtitle is-size-6">PLATFORM</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Supplier provides the technological platform, which is the CoopNET Mobile system, that bridges the connection between the cooperatives and/or their members to UnionBank of the Philippines so that they can avail of the financial products and services of UnionBank of the Philippines. [Bank] is an entity separate and distinct from Supplier. It is duly registered with the Securities and Exchange Commission and regulated by the Bangko Sentral ng Pilipinas. All rules and regulations applied by UnionBank of the Philippines to Supplier shall likewise be applicable to the cooperatives and/or their members who avail of UnionBank of the Philippines’s services.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">General Provision</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        The Supplier provides, manages and operates CoopNET Mobile, a platform that facilitates and supervises the lending process between the borrower and the lender, which is an entity separate and distinct from the Supplier. The terms and conditions of the loan shall be governed by a separate agreement between the lender and borrower. The Supplier merely operates the CoopNET Mobile platform and does not act as lender or guarantor of any loan that may be processed through the platform.
                    </p>
                    <p class="coopnet-text-paragraph is-size-6">
                        The lender and borrower agree to hold Supplier free and harmless from any liability that may arise between the said lender and borrower or any third party notwithstanding their use or availment of the CoopNET Mobile platform. Any action or decision taken by any registered user when using the platform shall be its sole responsibility. The lender and borrower likewise agree to indemnify the Supplier for any damage that Supplier may incur as a result of the lender and/or borrower’s breach of any of the above representations or warranties. 
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>Lender’s terms and conditions. Users of CoopNET Mobile intending to act as lenders will be subject to accepting services agreement relating to money lending services on the Platform and lender’s terms and conditions. For avoidance of doubt, all such texts and documents will be easily accessible on the CoopNET Mobile for such users;</li>
                        <li>Borrower’s documentation. Users of CoopNET Mobile intending to act as borrowers will be subject to accepting loan agreement establishing rights and obligations between the lender and the borrower. </li>
                    </ol>
                    <p class="coopnet-text-paragraph is-size-6">
                        You may only use our Payment Processing Services to conduct legitimate Transactions with your Customers. You are responsible for your relationship with your Customers. We are not responsible for the products or services you publicize or sell, or that your Customers purchase using the Payment Processing Services; or if you accept donations, for your communication to your Customers of the intended use of such donations. You understand and affirm that you are solely responsible for the nature and quality of the products or services you provide, and for delivery, support, refunds, returns, and for any other ancillary services you provide to your Customers.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">ATM Card/Debit Card</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        At the Customer’s request, a banking institution, through the Supplier, shall issue a UnionBank of the Philippines ATM Card to the Customer. The UnionBank of the Philippines Card is a payment and withdrawal card that allows a member to access his or her bank account balance through Point-of-Sale (POS) terminals, Automated Teller Machines (ATMs), and eCommerce websites, as may be applicable. Transactions made using the [Bank] Card require a card verification process in the form of either a PIN (Personal Identification Number) or signature. The term “Bank” or “Banking institution” shall refer to an entity performing banking functions as defined by law, and rules and regulations issued by pertinent regulatory bodies such as the Bangko Sentral ng Pilipinas (“BSP”), Securities and Exchange Commission (“SEC”), Anti-Money Laundering Council (“AMLC”), and Philippine Deposit Insurance Corporation (“PDIC”). 
                    </p>
                    <p class="coopnet-text-paragraph is-size-6">
                        The Customer’s use of the  UnionBank of the Philippines Card constitutes Costumer’s concurrence to and acceptance of this Agreement and the Terms and Conditions of the issuing Bank governing PRODUCTS, SERVICES, FACILITIES, AND CHANNELS, as well as Customer’s acknowledgement  and full understanding of the corresponding risks attendant to the availment of these products, facilities, services, and channels. 
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Services Disclaimer</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Although we provide you with our Payment Processing Services, we have no way of knowing if any Transaction is accurate or complete, or normal for your business in particular or your type of business. Only you are responsible for knowing whether a Transaction initiated by your Customer is erroneous (such as a Customer purchasing one item when they meant to order another) or suspicious (such as unusual or large purchases, or a request for delivery to a foreign country where this typically does not occur). If you are unsure if a Transaction is erroneous or suspicious, you agree to research the Transaction and, if necessary, contact your Customer before fulfilling or completing the Transaction. You are solely responsible for any losses you incur due to erroneous or fraudulent Transactions in connection with your use of the Services. 
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Customer Responsibility over their Customers</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Except where CoopNET Mobile and a Customer have otherwise agreed, you maintain the direct relationship with your Customers and are responsible for: 
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>acquiring appropriate consent to submit Charges through the Payment Processing Services on their behalf;</li>
                        <li>providing confirmation or receipts to Customers for each Charge;</li>
                        <li>verifying Customers’ identities; and</li>
                        <li>determining a Customer’s eligibility and authority to complete Transactions.</li>
                    </ol>

                    <p class="coopnet-text-subtitle is-size-6">Accounts</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Accounts 
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Customer Responsibility on the Payment processing Services</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Customers must understand the purpose, amount, and conditions of Charges you submit through the Payment Processing Services. When using the Payment Processing Services you agree to: 
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>accurately communicate, and not misrepresent, the nature of the Transaction, and the amount of the Charge in the appropriate currency prior to submitting it to the API;</li>
                        <li>provide a receipt that accurately describes each Transaction to Customers;</li>
                        <li>provide Customers a meaningful way to contact you in the event that the product or service is not provided as described;</li>
                        <li>not use Services to sell products or services in a manner that is unfair or deceptive, exposes Customers to unreasonable risks, or does not disclose material terms of a purchase in advance; and</li>
                        <li>inform Customers that CoopNET Mobile and its affiliates process Transactions (including payment Transactions) for you. You also agree to maintain and make available to your Customers a fair and neutral return, refund, cancellation, or adjustment policy, and clearly explain the process by which Customers can receive a Refund.</li>
                    </ol>

                    <p class="coopnet-text-subtitle is-size-6">Undertaking</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        You hereby agree to undertake, and you acknowledge and understand that it is your sole responsibility, to 
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>obtain your Customers’ consent to these Terms and the Privacy Policy and</li>
                        <li>provide all notices and obtain all consents necessary for CoopNET Mobile’s use of Customer Data (as defined in this document).</li>
                    </ol>

                    <p class="coopnet-text-primary is-size-5 ">10. Application Programming Interface</p>
                    <p class="coopnet-text-subtitle is-size-6">API Access</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        CoopNET Mobile will provide you access to its Application Programming Interface (“APIs”) that you will use to access the Payment Processing Services. You may use the APIs solely as described in the API documentation or as otherwise communicated to you by us. We may update the APIs and API documentation from time to time, and may add or remove functionality. To the extent possible, we will communicate to you any changes in functionality. 
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">API Keys and Authorization</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        You will be provided with API keys, and you can manage these keys from the Dashboard. Public keys are meant to be used for transactions that are accessed through public channels (e.g. generating tokens for credit card numbers from your web application), and secret keys are for transactions that are done internally in your systems (e.g. creating an actual charge). You are responsible for securing your secret keys – do not publish or share them with any unauthorized persons. Failure to secure your secret keys will increase the likelihood of fraud on your Account and potential losses to you or your Customers. You should contact us immediately if you become aware of any unauthorized use of your secret key or any other breach of security regarding the Services. We will provide more details on proper use of public and secret API keys in the API documentation. 
                    </p>

                    <p class="coopnet-text-primary is-size-5 ">11. Intellectual Property & Licenses</p>
                    <p class="coopnet-text-subtitle is-size-6">Ownership of Our IP</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        CoopNET Mobile owns all rights, title, and interest in the patents, copyrights (including rights in derivative works), moral rights, rights of publicity, trademarks or service marks, logos and designs, trade secrets, and other intellectual property embodied by, or contained in the API and API documentation, Services (including all files and content), and the Dashboard (collectively, “Our IP”) or any copies thereof. Our IP is protected by copyright, trade secret, trademark, patent, and other intellectual property laws, and all rights in Our IP not expressly granted to you in this Agreement are reserved. To be clear, other than the express licenses granted by this Agreement, CoopNET Mobile grants no right or license by implication, estoppel or otherwise to Our IP. You may not sell, redistribute, download, export, or reproduce Our IP in any way. You also may not decompile, reverse-engineer, disassemble, or otherwise convert Our IP without our permission.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Improvement Ideas and Suggestions</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        You may choose to or we may invite you to submit comments or ideas about improvements to the Service, our API, our platform, or any other component of our products or services (“Ideas”). If you submit an Idea to us, we will presume that your submission was voluntary, unsolicited by us, and delivered to us without any restrictions on our use of the Idea. You also agree that CoopNET Mobile has no fiduciary or any other obligation to you in connection with any Idea you submit to us, and that we are free to use your Ideas without any attribution or compensation to you. You also agree that we do not waive any rights to use similar or related Ideas previously known to us, developed by our employees or contractors, or obtained from other sources. 
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Software License</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        CoopNET Mobile hereby grants you a revocable, non-exclusive, and non-transferable limited license to access and/or use CoopNET Mobile’s APIs, developer’s toolkit, and other software in accordance with the accompanying API documentation made available by CoopNET Mobile for purposes of using the Payment Processing Services. This license grant includes all updates, upgrades, new versions and replacement software for your use in connection with the Payment Processing Services. If you do not comply with these Terms, the API documentation, and any other requirements provided by CoopNET Mobile, then you will be liable for all resulting damages suffered by you, CoopNET Mobile and third parties. Upon expiration or termination of this Agreement, you will immediately cease all use of the Service, including the API and the Payment Processing Services. 
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Trademark License</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Additionally, CoopNET Mobile grants you a revocable, non-exclusive, non-transferable license to use CoopNET Mobile’s trademarks used to identify the Payment Processing Services (the “Trademarks”) solely in conjunction with the use of the Payment Processing Services. You may only use the Trademarks on the portion of your website or application that directly relates to the Payment Processing Services, such as on a checkout page utilizing our Payment Processing Services. All Trademarks should directly link to our homepage. CoopNET Mobile may, in the future, provide you with additional guidelines governing your use of the Trademarks. 
                    </p>
                    <p class="coopnet-text-paragraph is-size-6">
                        You agree that you will not at any time during or after this Agreement assert or claim any interest in or do anything that may adversely affect the validity of any Trademark or any other trademark, trade name or product designation belonging to or licensed to CoopNET Mobile (including, without limitation registering or attempting to register any Trademark or any such other trademark, trade name or product designation). You may not use the Trademarks to imply endorsement by CoopNET Mobile of your products or services, or in a manner that causes customer confusion. You may not misrepresent your relationship with CoopNET Mobile, or use the Trademarks in any manner that is misleading or damaging to CoopNET Mobile, in CoopNET Mobile’s sole discretion. In addition to any other right that we may have to enforce the terms of this Agreement, you must promptly comply with any request that we make for you to cease a use of the Trademarks that we determine is non-compliant with this paragraph. Upon expiration or termination of this Agreement, you will immediately cease all display, advertising and use of all of the Trademarks.
                        <br><br>
                        As part of this Agreement, we do not make any representations regarding your use of the Trademarks. We disclaim all warranties, express and implied, including any warranties of non-infringement.
                    </p>

                    <p class="coopnet-text-primary is-size-5 ">12. Data Usage, Privacy, and Security</p>
                    <p class="coopnet-text-subtitle is-size-6">Customer Data</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Basic Information. In order for your Customers to process a Transaction through the Payment Processing Services, they will be required to provide you with certain personally identifiable information, which, in turn, you provide to us through your use of the Payment Processing Services. This information may include, but is not limited to:
                        <br>
                        First and last name;
                        <br>
                        Physical and/or mailing address;
                        <br>
                        Financial information, including but not limited to credit or debit card numbers or bank account information;
                        <br>
                        Email address; and,
                        <br>
                        Phone number.
                    </p>
                    <p class="coopnet-text-paragraph is-size-6">Additionally, CoopNET Mobile may automatically collect information from Customers and their devices and browsers, including: </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>Device information, such as a unique device identifier; and,</li>
                        <li>Location information, such as your IP address or geo-location.</li>
                    </ol>

                    <p class="coopnet-text-subtitle is-size-6">Customer Data Ownership</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        All Customer Data shall be owned by you and you hereby grant CoopNET Mobile a perpetual, irrevocable, sub-licensable, assignable, worldwide, royalty-free license to use, reproduce, electronically distribute, and display Customer Data for the following purposes:
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>providing and improving the Services;</li>
                        <li>internal usage, including but not limited to, data analytics and metrics so long as such Customer Data has been anonymized and aggregated with other customer data;</li>
                        <li>complying with applicable legal requirements and assisting law enforcement agencies by responding to requests for the disclosure of information in accordance with applicable law; and</li>
                        <li>complying with applicable legal requirements and assisting law enforcement agencies by responding to requests for the disclosure of information in accordance with applicable law; and</li>
                        <li>any other purpose for which consent has been provided by the Customer. You hereby agree to undertake, and you acknowledge and understand that it is your sole responsibility to provide all notices to and obtain all consents from Customers necessary for CoopNET Mobile’s use of Customer Data as set out above.</li>
                    </ol>
                    <p class="coopnet-text-paragraph is-size-6">
                        For the purposes of this Agreement, “Customer Data” means the personal data that
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>the Customer provides to you and you pass on to CoopNET Mobile through your use of the Payment Processing Services and</li>
                        <li>CoopNET Mobile collects from the Customer’s device and browser through your use of the Payment Processing Services.</li>
                    </ol>

                    <p class="coopnet-text-subtitle is-size-6">Publicity and Advertising</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        You hereby grant CoopNET Mobile permission to use your name and logo in CoopNET Mobile’s marketing materials including, but not limited to use on CoopNET Mobile’s website, in customer listings, in interviews and in press releases.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Information shared to us</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        As part of the Service, we may provide you with various information in furtherance of the Service. Our intention in doing so is to be helpful and to make the Service more useful to you. However, you agree that all information and suggestions that we provide to you through the Service is strictly for informational purposes and shall not be construed or relied upon in any way, and specifically shall not be construed or relied upon as professional advice of any kind. You agree that, to the fullest extent permitted by law, and as detailed below in the “Limitation of Liability” section of these Terms, we will not incur any liability at all whatsoever in the event that your reliance on any information provided by us results in harm or damage to you or your property.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Information shared to Customer</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        As a core aspect of the functionality of the Service, you may provide us with information about yourself, by giving us access to information collected by third parties, and by using the Service. By providing this information and content, you grant us the right to use the information and content for the purposes described in these Terms and our Privacy Policy. While we do not claim ownership over any such information and content that you provide, you agree that we have the right to use such information and content as specified in these Terms and our Privacy Policy. Further, by providing us with information and content through the Service, you represent and warrant that you own, or have the full legal authority to distribute, all information that you provide on the Service.
                        <br><br>
                        In addition, we should not be relied upon as a means to store your data or information and we assume no responsibility for such activities.
                    </p>

                    <p class="coopnet-text-primary is-size-5 ">13. Use of the Service</p>
                    <p class="coopnet-text-subtitle is-size-6">Inapproriate and Illegal Content Prohibited</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        You agree not to transmit any inappropriate content on the Service including, but not limited to, libelous, defamatory, obscene, pornographic, abusive, or threatening content; content that advocates or encourages conduct that could constitute a criminal offense, give rise to civil liability, or otherwise violate any applicable local, state, national, or foreign law or regulation; content that is misleading or not true; or advertise or otherwise solicits funds for goods or services. We may remove such content from our servers, and we may suspend or revoke your access to the Service, and we reserve the right to investigate, and seek applicable remedies for, violations of applicable law to the fullest extent of the law.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Termination of Accounts</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        CoopNET Mobile may terminate Accounts that have been the subject of five (5) separate DMCA notices. In the event a user's materials are removed due to a DMCA Notice and then subsequently restored due to the filing of a counter-notification, CoopNET Mobile will treat the underlying DMCA Notice as withdrawn. CoopNET Mobile reserves the right to terminate Accounts that are the subject of fewer than five (5) DMCA Notices in appropriate circumstances - such as where the user has a history of violating or willfully disregarding these Terms.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Use of Others' Intellectual Property</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Although you may provide information and content to CoopNET Mobile as part of your use of the Service, you agree to be respectful of others’ intellectual property rights. You may not upload, transmit, or otherwise distribute any information or content in violation of intellectual property laws or proprietary rights of any third parties. If you do not respect a third party’s intellectual property or proprietary rights, you are solely responsible for any violations of law. In addition, you are solely responsible for infringement of third-party rights caused by any information that is generated or submitted through your use of the Service. We take claims of intellectual property infringement seriously. As such, we reserve the right to suspend and/or revoke access to the Service for any user who is found to have infringed on the intellectual property rights of third parties, or us, or otherwise is found to have violated any intellectual property laws.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Third Party Sites and Services</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Our Service is integrated with services provided by third parties as part of the functionality of the Service. We have no control over third parties and make no guarantees about, and assume no responsibility for, the information or services provided by third parties.
                        <br><br>
                        Additionally, we may provide links to third-party websites and businesses and otherwise may provide information on the Service. You acknowledge and agree that we are not responsible for the accuracy, content, or functionality of services or information provided on or by such third-party websites or businesses or through such information on the Service, and we do not endorse, nor assume any liability related to your use of, such websites, businesses or information in any way. Nothing on the Service shall be considered an endorsement, representation, or warranty of anything posted by other users on the Service.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Services Termination General Provision</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        At any time, you may discontinue your use of the Service. Please visit our Privacy Policy to understand how we treat your information when you discontinue your use of the Service.
                        <br><br>
                        We reserve the right to take action to limit or prevent your access to our Service, if we, in our sole discretion, deem that such action is necessary based on: your use of the Service in a way that would potentially expose us to liability; disruption of the Service by you to others; your violation of these Terms or our Privacy Policy; your violation of any applicable laws, rules, regulations, and agreements that may apply to you; and your use of the Service in a way that could cause harm to any person.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Responsibilities after Services Termination</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Termination does not immediately relieve you of obligations incurred by you under this Agreement. Upon termination, you agree to (i) complete all pending Transactions, (ii) stop accepting new Transactions, and (iii) immediately remove all Trademarks from your website (unless permitted under a separate license with the payment network). Your continued or renewed use of the Services after all pending Transactions have been processed serves to renew your consent to the terms of this Agreement. If you terminate this Agreement, we will pay out any remaining funds owed to you subject to these Terms.
                        <br><br>
                        After termination by either party, you shall no longer have access to, and shall cease all use of the Services. Any termination of this Agreement does not relieve you of any obligations to pay any Fees or any other financial obligation incurred by you or through your use of the Services prior to or after termination. In addition, all licenses granted to you by CoopNET Mobile under this Agreement will end.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Proper Use of the Service</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        You shall not violate or attempt to violate any security features of the Service, including, without limitation, (a) accessing content or data not intended for you, or logging onto a server that you are not authorized to access; (b) attempting to probe, scan, or test the vulnerability of the Service, or any associated system or network, or to breach security or authentication measures without proper authorization; (c) interfering or attempting to interfere with service to any user, host, or network, including, without limitation, by means of submitting a virus to the Service, overloading, “flooding,” “spamming,” “mail bombing,” “crashing,” or undertaking similar activities; (d) resell the Services or permit third parties to use the Services without our prior written consent; (e) make unauthorized copies of any content in the Services; and (f) forging any TCP/IP packet header or any part of the header information in any e-mail or in any posting using the Service.
                    </p>

                    <p class="coopnet-text-primary is-size-5 ">14. Additional Legal Terms</p>
                    <p class="coopnet-text-subtitle is-size-6">Assumption of Risk</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        YOUR USE OF THE SERVICE IS ENTIRELY AT YOUR OWN RISK.
                        <br><br>
                        YOU AGREE THAT IF YOU CHOOSE TO USE THE SERVICE, YOU ARE DOING SO VOLUNTARILY. YOU ASSUME ALL SUCH RISKS WITHOUT LIMITATION.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Disclaimer of Warranties</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        WE ARE MAKING THE SERVICE AVAILABLE “AS IS” AND, TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE SERVICE, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. YOU ASSUME THE RISK OF ANY AND ALL DAMAGE OR LOSS FROM USE OF, OR INABILITY TO USE, THE SERVICE. WE DO NOT WARRANT THAT OPERATION OF THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE.
                    </p>
                    
                    <p class="coopnet-text-subtitle is-size-6">Limitation of Liability</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        To The Maximum Extent Permitted By Law, We Shall Not Be Liable For Damages Of Any Kind (Including, But Not Limited To, Special, Incidental, Or Consequential Damages, Lost Profits, Or Lost Data, Regardless Of The Foreseeability Of Those Damages) Arising Out Of Or In Connection With Your Use Of The Service Including, But Not Limited To, Damages Arising From Or Related To Any Of The Following: Any Claim Related To Personal Or Bodily Injury In Connection With The Use Of The Service; Any Harm Or Damage Caused By, Or Otherwise Related To, Your Reliance On Information Provided Through The Service; Any Loss Or Unauthorized Disclosure Of Data; Any Harm Or Damage Caused By, Or Otherwise Related To, Your Use Of Or Interaction With Any Services Offered Through The Service; And Any Violations Of Applicable Laws, Rules, Regulations, And Other Agreements. This Limitation Shall Apply Regardless Of The Legal Theory Or Form Of Action.
                    </p>
                    <p class="coopnet-text-paragraph is-size-6">
                        In No Event Will Our Aggregate Liability Arising Out Of Or In Connection With These Terms And Your Use Of The Service Exceed One Hundred Dollars ($100.00).
                    </p>
                    <p class="coopnet-text-paragraph is-size-6">
                        The Limitations Of Damages Set Forth Above Are Fundamental Elements Of The Basis Of The Bargain Between You And Us.
                    </p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Some Jurisdictions Do Not Allow The Exclusion Or Limitation Of Liability For Certain Types Of Damages Or Causes Of Action, So The Above Limitation May Not Apply To You.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Indemnification</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        In the event that any third party brings a claim against us related to your actions, content, information, or any other use of the Service by you, you agree to indemnify, defend, and hold us and our agents harmless from and against any and all third party claims, losses, liability, damages, and/or costs (including reasonable attorney fees and costs) relating to such claim. We will notify you promptly of any such claim and will provide you with reasonable assistance, at your expense, in defending any such claim, provided, however, that our failure to so notify you shall not relieve you of your indemnity obligations, but instead shall reduce those obligations by the amount of damages or increased costs and expenses attributable to our failure to give notice. We reserve the right to approve counsel retained by you, to take control of the defense (at our expense) of any claim for which indemnity is required, and to participate in the defense of any claim (at our expense) for which indemnity is required. You may not settle any claim without our prior consent.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Dispute Resolution – Event</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        You agree to resolve any dispute, claim, or controversy with CoopNET Mobile arising out of or relating to your use in any way of the Service in the following manner. First, we both agree to attempt in good faith to resolve the dispute informally by contacting each other by email. Second, if the dispute is not resolved through informal resolution, we both agree to attempt in good faith to resolve the dispute through mediation administered by the court, which shall take place in Pasig City, Philippines, and the costs of which shall be divided equally between you and CoopNET Mobile. Third, if any such dispute is not resolved through informal resolution and mediation, we both agree to first refer such dispute for settlement through binding arbitration administered by court, which shall take place in Pasig City, Philippines.
                    </p>
                    
                    <p class="coopnet-text-subtitle is-size-6">Dispute Resolution - Fees</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Either you or we may bring a lawsuit solely for injunctive relief without first engaging in the dispute resolution process described above.
                        <br><br>
                        We both agree that, in the event of arbitration, or in the event of a lawsuit as permitted by this Section or otherwise, the prevailing party shall be entitled to costs and fees (including reasonable attorneys’ fees). Arbitration pursuant to this Section shall be confidential, and neither you, nor CoopNET Mobile, nor the arbitrator may disclose the existence, content or results of any arbitration, except as may be required by law or for purposes of enforcement or appeal of the arbitration award. Judgment on any arbitration award may be entered in any court having proper jurisdiction. There shall be no right or authority for any claims subject to this arbitration clause to be arbitrated on a class action or consolidated basis or on bases involving claims brought in a purported representative capacity on behalf of the general public (including, but not limited to, as a private attorney general). If any portion of this arbitration clause is determined by a court to be inapplicable or invalid, then the remainder shall still be given full force and effect.
                        <br><br>
                        Nothing contained in this Section shall limit our ability to take action related to your access to the Service as provided in these Terms.
                    </p>
                    
                    <p class="coopnet-text-subtitle is-size-6">Governing Law, Venue, and Personal Jurisdiction</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        These Terms shall be governed by the laws of the Republic of the Philippines, without regard to conflict of law provisions. In the event a lawsuit is filed subsequent to a failure of dispute resolution as provided under the immediately preceding provisions, we both agree that any judicial proceeding shall be brought exclusively in the appropriate courts of the City of Makati, Philippines.
                    </p>
                    
                    <p class="coopnet-text-subtitle is-size-6">Severability Waiver</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        If, for whatever reason, any term or condition in these Terms is found unenforceable, all other terms and conditions will remain unaffected and in full force and effect. The failure to enforce any provision of these Terms is not a waiver of our right to do so later, and no waiver shall be effective unless made in writing and signed by an authorized representative of the waiving party.
                    </p>
                    
                    <p class="coopnet-text-subtitle is-size-6">Changes to These Terms</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        CoopNET Mobile reserves the right to change these Terms from time to time, with or without notice to you. If you continue to use the Service, you consent to the new Terms. Any changes to these Terms will become effective on the “Effective Date” indicated above. If you continue to use the Service after the Effective Date, you consent to the new Terms. CoopNET Mobile will always have the latest Terms posted on the Service.
                    </p>
                    
                    <p class="coopnet-text-subtitle is-size-6">Contact Us</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        If you have any questions about these Terms or our Service, please feel free to contact us by email <a href="mailto:contact@coopnet.online">contact@coopnet.online</a>
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Headings Used in These Terms</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        The section headings contained in these Terms are for reference purposes only and shall not affect the meaning or interpretation of these Terms in any way.
                    </p>

                    <br><br>

                    <p class="coopnet-text-primary is-size-6">ANNEX OF PAYMENT PROVIDERS AND ACQUIRERS</p>

                    <div class="columns">
                        <div class="column">
                            <p class="coopnet-text-subtitle has-text-weight-bold is-size-6">Payment Provider and/or Acquirer</p>
                            <p class="coopnet-text-paragraph is-size-6">
                                PayMaya: PayMaya Philippines Inc.
                            </p>
                            <p class="coopnet-text-paragraph is-size-6">
                                DragonPay: DragonPay Corporation
                            </p>
                            <p class="coopnet-text-paragraph is-size-6">
                                Coins.ph
                            </p>
                            <p class="coopnet-text-paragraph is-size-6">
                                Union Bank of the Philippines
                            </p>
                            <p class="coopnet-text-paragraph is-size-6">
                                ECPay: Electronic Commerce Payment Inc.
                            </p>
                            <p class="coopnet-text-paragraph is-size-6">
                                Mynt: Globe Fintech Innovations Inc.
                            </p>
                        </div>
                        <div class="column">
                            <p class="coopnet-text-subtitle has-text-weight-bold is-size-6">Payment Type</p>
                            <p class="coopnet-text-paragraph is-size-6">
                                Online Banking, OTC, OTC Non-Bank, Supported Banks, Cebuana Lhuilier
                            </p>
                            <p class="coopnet-text-paragraph is-size-6">
                                Cebuana Lhuilier, Other OTC payments, Coins.ph
                            </p>
                            <p class="coopnet-text-paragraph is-size-6">
                                Instapay, Pesonet
                            </p>
                            <p class="coopnet-text-paragraph is-size-6">
                                7-Eleven
                            </p>
                            <p class="coopnet-text-paragraph is-size-6">
                                Gcash
                            </p>
                        </div>
                    </div>
                    

                </div>
            </div>
        </div>
        <div class="terms-of-use-mobile main-bg-mobile is-hidden-tablet">
            <div class="pt-6">
                <div class="columns is-flex is-vcentered rounded-div-mobile">
                    <div class="column is-centered">
                        <h1 class="coopnet-text-mobile-title is-size-4-mobile"> Terms of Use </h1>

                        <hr>

                        <p class="coopnet-text-mobile-primary is-size-5 has-text-centered">CoopNET Mobile SERVICE AGREEMENT</p>

                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            This Agreement was made on 7th of September 2022, by and between:
                        </p>

                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            CoopNET Mobile,
                        </p>

                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            hereinafter referred to as the “the <span class="has-text-weight-bold">Supplier</span>”,
                        </p>

                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            and
                        </p>

                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            <span class="has-text-weight-bold">[ Cooperative ]</span>, a company incorporated under the laws of the Republic of the Philippines, having its registered office at [ Address ], duly represented by: <span class="has-text-weight-bold is-underlined">[ Cooperative ]</span>,
                        </p>

                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            hereinafter referred to as “the <span class="has-text-weight-bold">Customer</span>”,
                        </p>

                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            The Supplier and the Customer hereinafter jointly referred to as “the <span class="has-text-weight-bold">Parties</span>” or individually as “the <span class="has-text-weight-bold">Party</span>”.
                        </p>

                        <br>

                        <p class="coopnet-text-mobile-primary is-size-5 ">1. Introductory Letter</p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Definition of parties</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            These Terms of Use (the “Terms” or “Agreement”) cover your rights and obligations relating to your access and use of our app, Payment Processing Services (as defined below), as well as any other related products and services, including our APIs (as defined below) and API documentation (collectively, the “Service”). All references to “we”, “us”, “our”, or “CoopNET Mobile” refer to Traxion Tech, Inc., a corporation organized under the laws of the Philippines. All references to “you”, “your”, or “user” relate to the person or entity who registered an account on CoopNET Mobile (an “Account”) to use or access the Services. In addition to these Terms, please review our Privacy Policy, which describes our practices related to collection and use of your information to provide the Service. These Terms apply to our Privacy Policy as well.
                        </p>
                        
                        <p class="coopnet-text-mobile-subtitle is-size-6">Electronic Acceptance</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            By ticking the checkbox to indicate your acceptance to these Terms and our Privacy Policy, or otherwise by creating an Account and using the Service, you represent and agree that you have read, understand, and agree to be bound by both these Terms and our Privacy Policy as binding agreements. Further, you agree that these Terms and our Privacy Policy apply to your past use, if any, of the Service prior to the Effective Date.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Waiver of rights</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Please read these Terms carefully, as they contain important information about your rights and responsibilities, including limitation of our Liability and Binding Arbitration. If you do not accept this Agreement in its entirety, you may not access or use the service.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Support</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Do not hesitate to contact us if you have any questions or want to discuss either of these important documents.
                        </p>

                        <p class="coopnet-text-mobile-primary is-size-5 ">2. General Terms</p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Service Overview</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            The Service fundamentally provides you with the software and connectivity to allow real-time secure data transmission for processing debit cards, credit cards and certain other available payment methods (each, a “Payment Method”) provided by a Payment Method provider (each, a “Payment Method Provider”) on your website or application (the “Payment Processing Services”) that allows customers (your “Customers”) to purchase your goods and services.
                            <br><br>
                            Other Payment Methods. Other Payment Methods may include e-wallets (including but not limited to GCash, PayMaya, Coins.ph, GrabPay, WeChat Pay, and Alipay) and cash-in methods (including but not limited to 7-Eleven Instant Cash In, M Lhuiller, and Cebuana Lhuillier).
                            <br><br>
                            Credit Card. Payment Method Providers may include Visa and MasterCard. Your use of a Payment Method may be subject to separate terms applicable to the Payment Method, including but not limited to Third-Party Rules (as defined below). We may add or remove Payment Method Providers and Acquirers (as defined below) at any time. Account Creation. Subject to these Terms, users that create an Account through the Services and complete our verification requirements, as set forth below, will be able to use the Payment Processing Services. The Services also include software, APIs and any other technology as described on the CoopNET Mobile website.
                        </p>
                        
                        <p class="coopnet-text-mobile-subtitle is-size-6">Age Policy; Permitted Users</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            We do not intend that the Service be used by anyone under 18 years old. Thus, by creating an Account and using the Service, you represent and warrant that you are at least 18 years old and can validly transact on your own behalf. If we learn or have reason to suspect that a user is under 18 years of age, we will promptly revoke the user’s access to the Service and delete any personally identifiable information submitted by that user. Further, you represent and warrant that your use of the Service does not and will not conflict with any pre-existing obligation in conflict or in any way inconsistent with the provisions of these Terms.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Account Registration - Basic Requirement</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            To access our Payment Processing Services, you will need to register an Account using your
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>first and last name,</li>
                            <li>e-mail address,</li>
                            <li>contact number,</li>
                            <li>address and the</li>
                            <li>name of your business (please see our Privacy Policy for more information on our practices related to collection and use of your data), and you agree to our use of such information.</li>
                            <li>You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it up to date.</li>
                        </ol>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            We reserve the right to suspend or terminate your Account according to these Terms if we determine that any information provided by you is inaccurate or incomplete.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Account Registration - Password Requirement</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            As part of the Account registration process, you will also be asked to create a password and a business handle (i.e. an identifier of your business which will be part of the URL that you create and share with your Customers) (your “Handle”).
                            Your business name, Handle, and/or the URL may appear on your Customers’ bank or other statements. To minimize confusion and avoid potential disputes, these descriptors must be recognizable to your Customers and must accurately describe your business or activities.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Account Registration - Authorization or Power of Attorney</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            By registering for an Account on behalf of an entity, you represent and warrant that you have authority to
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>accept these Terms on behalf of and bind such entity, and</li>
                            <li>provide information on behalf of such entity.</li>
                        </ol>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            We may require you to provide additional information or documentation demonstrating such authority to bind the entity.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Account Registration - Prohibited Accounts</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Without our express written consent, you may not register or attempt to register for an Account on behalf of a user of CoopNET Mobile whose Account was previously terminated and is otherwise barred from further use of the Services.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Responsibility to Saferguard Accounts and Limited Liability</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            If you become aware that an unauthorized third party is using your information to access the Service, you must promptly notify us of such use. You agree that we will not be liable for any loss or harm that you incur if someone else uses your information to access the Service, either with or without your knowledge, and that you will be solely responsible for such use, whether or not you authorized the use. You are responsible for safeguarding your Account information, including your password. You agree not to disclose your password to, or share your Account with, any third party. You are solely responsible for maintaining and protecting your username and password.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Responsibility on maintaining account information</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            You agree to keep the information in your Account current. You must promptly update your Account with any changes affecting you,
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>the nature of your business activities,</li>
                            <li>beneficial owners, principals,</li>
                            <li>changes to the legal entity that operates your business (for example, if you incorporate an entity), or</li>
                            <li>any other pertinent information.</li>
                        </ol>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            We may suspend your Account or terminate this Agreement if you fail to keep this information current.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Right to Verify Identity and managing information</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Compliance to Anti Money Laundering Law. As a provider of payment services, we are required to comply with many regulations, including anti-money laundering laws. As such, we are required to obtain information about and verify the identity of our users. To comply with our obligations under applicable laws, we will request that you provide certain information to us about you after you create your Account. This information will be used by us for the purposes of identity verification and the detection of money laundering, terrorist financing, fraud, or any other financial crimes. You agree that all such information provided by you will be complete and accurate, that you will keep us updated if any of the information you provided changes, and that we may keep a record of such information consistent with our internal policies.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Authorization to verify and collect private information.</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Authorization to gather and collect information. In addition to collecting information from you, we are required to take reasonable steps to verify your identity as a user. You expressly authorize us to take any and all actions that we reasonably deem necessary to verify your identity or protect you and/or us against fraud or other financial crime.
                            <br><br>
                            Engaging Third parties to Verify Information. These may include, but are not limited to, engaging third-party services to assist with such verification efforts, sharing your information with such third parties, and collecting additional information about you from such third parties. Any such third party to whom we disclose or transfer information about you for the above purpose is contractually required to comply with confidentiality standards and establish adequate safeguards for data privacy, undertake to respect any user’s right to privacy and comply with applicable laws. We also require that said third parties use information shared with them only for the above purposes and follow our reasonable directions with respect to this information. Please refer to the List of Third Parties engaged by CoopNET Mobile for this purpose. Please also refer to our Privacy Policy for further details.
                            <br><br>
                            Right to terminate services. Until you have submitted all required information, and subject to our review and approval, your Account will be available to you on a preliminary basis only (i.e. although users may be allowed to conduct test transactions to ensure proper integration with our Payment Processing Services, users will not be able to conduct Transactions), and we may terminate it at any time and for any reason.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Sharing information to Payment Method Providers and Acquirers</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Throughout the term of this Agreement, we may share information about your Account with Payment Method Providers and Acquirers (as defined below) in order to verify your eligibility to use the Payment Processing Services, establish any necessary accounts or credit with Payment Method Providers and Acquirers, monitor Charges (as defined below) and other activity, and conduct risk management and compliance reviews.
                            <br><br>
                            We may also share your personally identifiable information with Payment Method Providers and Acquirers for the purpose of facilitating the compliance of CoopNET Mobile, the Payment Method Providers, and the Acquirers with applicable laws and regulations, as well as any rules, guidelines, or by-laws of any of the Payment Method Providers and/or Acquirers (“Third-Party Rules”). Any Payment Method Provider or Acquirer to whom we disclose information about your Account is contractually required to comply with confidentiality standards and establish adequate safeguards for data privacy, undertake to respect any user’s right to privacy and comply with applicable laws. We also require that these Payment Method Providers or Acquirers use information shared with them only for the above purposes and follow our reasonable directions with respect to this information. Please refer to the List of Third Parties for the list of Payment Method Providers or Acquirers who will have access to information about your account. Please also refer to our Privacy Policy for further details.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Additional Verification</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            In addition to the above, at any time during your use of the Services, we may require additional information from you to verify beneficial ownership or control of the business, validate information you provided, verify your identity, and assess the risk associated with your business. This additional information may include -
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>phone numbers,</li>
                            <li>physical and mailing addresses for you and your business,</li>
                            <li>your business’s tax identification number,</li>
                            <li>business invoices,</li>
                            <li>copies of government-issued identification,</li>
                            <li>business licenses, or</li>
                            <li>other information related to your business, its beneficial owners or principals.</li>
                            <li>We may also request that you provide copies of financial statements or records pertaining to your compliance with this Agreement or require you to provide a personal or company guarantee.</li>
                        </ol>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Your failure to provide this information or material may result in suspension or termination of your Account. We may share this information, including any other personally identifiable information you provide to us, with our third-party vendors we use to comply with many regulations, including anti-money laundering laws, subject to compliance with same requirements and limitations as provided in Account Verification Clause above.
                        </p>

                        <p class="coopnet-text-mobile-primary is-size-5 ">3. Technology and Services</p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Services Overview</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            The Payment Processing Services give you the ability to accept Payment Methods from your Customers in exchange for your goods or services. In order to provide you with access to the Payment Methods and Payment Processing Services, we work with Payment Method Providers, Acquirers (as defined below), and other third parties.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Definition of Terms</p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>Acquirer means a financial institution that is authorized by a Payment Method Provider to accept Charges on behalf of the Payment Method Provider;</li>
                            <li>Charge means a credit or debit instruction to capture funds from an account that a Customer maintains with a bank or other financial institution in connection with a Transaction;</li>
                            <li>Chargeback means an instruction initiated by a Customer for the return of funds for an existing Charge;</li>
                            <li>Chargeback means an instruction initiated by a Customer for the return of funds for an existing Charge;</li>
                            <li>Refund means a refund issued by you through the Payment Processing Services;</li>
                            <li>Reserve means an amount or percentage of your Payouts that CoopNET Mobile holds in order to protect against the risk of Reversals, Chargebacks, or any other risk, exposure and/or potential liability to us related to your use of the Payment Processing Services; and</li>
                            <li>Reversal means an instruction initiated by a Payment Method Provider, an Acquirer, or CoopNET Mobile to return funds for an existing Charge.</li>
                        </ol>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Use of Service</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            You may only use Payment Processing Services to facilitate a purchase, sale, donation, order, or other transaction (each a “Transaction”) with your Customers. You may not use Payment Processing Services to send money to others, to conduct any personal transactions, or for any other purposes prohibited by this Agreement. You represent and warrant to CoopNET Mobile that each Transaction that you process using the Payment Processing Services is solely in payment for your provision or sale of bona fide goods or services (including charitable services) to your Customers.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Restricted Business Activities</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            You must use the Services in a lawful manner, and must obey all laws, rules, and regulations applicable to your use of the Services and to Transactions. You may not use the Services to enable any person (including you) to benefit from any activities CoopNET Mobile has identified as a restricted business or activity (collectively, “Restricted Businesses”). Restricted Businesses include use of the Services in or for the benefit of a country, organization, entity, or person embargoed or blocked by any government.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Restricted Business Activities Inclusions</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Restricted Businesses shall also include, but not be limited to, any party participating in:
                            <br><br>
                            Any activities that violate these Terms, any Third-Party Rules, or any other agreement that you have entered into with us or any of our agents in connection with the Payment Processing Services;
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>Any activities that violate applicable law;</li>
                            <li>Investment and credit services;</li>
                            <li>Money and legal services, including money transmitters and money services businesses, check cashing businesses, currency exchanges or dealers, bill-pay services, crowdfunding, insurance, bail bonds, collections agencies, law firms collecting funds for any purpose other than to pay fees owed to the firm for services provided by the firm;</li>
                            <li>Virtual currency, including virtual currency that can be monetized, resold, or converted to physical or digital products and services or otherwise exit the virtual world (for example, Bitcoin);</li>
                            <li>Gambling, including internet gambling, lotteries, bidding fee auctions, sports betting, internet gaming, contests, sweepstakes, games of chance including legal or illegal forms of gambling;</li>
                            <li>Adult services, including pornography and other obscene materials (including literature, imagery and other media) depicting nudity or explicitly sexual acts, sites offering any sexually-related services such as prostitution, escorts, pay-per view, adult live chat features, sexually oriented items (for example, adult toys), adult video stores and sexually oriented massage parlors, gentleman’s clubs, topless bars, and strip clubs, sexually oriented dating services;</li>
                            <li>Illegal drugs or drug paraphernalia;</li>
                            <li>Infringement of Intellectual property or proprietary rights; counterfeit or unauthorized goods;</li>
                            <li>The sale of illegal products or services;</li>
                            <li>Unfair, predatory, or deceptive practices, including pyramid or “get-rich-quick” schemes; and</li>
                            <li>Any products or services that are otherwise restricted by third parties we work with in providing you with the Service, including but not limited to Payment Method Providers.</li>
                        </ol>

                        <p class="coopnet-text-mobile-subtitle is-size-6">List of Restricted Businesses</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Please review the list of Restricted Businesses thoroughly before registering for and opening an Account. If you are uncertain whether a category of business or activity is restricted or have questions about how these restrictions apply to you, please contact us. We may add to or update the Restricted Business List at any time.
                        </p>

                        <p class="coopnet-text-mobile-primary is-size-5 ">4. Disputes, Reversals, Refunds, and Chargebacks</p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Acting Agents</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            You may only submit charges through the Payment Processing Services that are authorized by your Customers. To enable us to process Transactions for you, you authorize and direct us, our affiliates, the Payment Method Providers and Acquirers to receive and settle any amounts due to you from your Transactions through the Payment Processing Services. You may not grant or assign any interest in the amounts due to you from your Transactions to any third party until such time as such amounts are deposited into your Bank Account (as defined below). You appoint CoopNET Mobile as your agent for the limited purpose of directing, receiving, holding and settling such amounts. You agree that CoopNET Mobile’s receipt of such amounts satisfies the relevant end-customer’s obligations to make payments to you. We will promptly update your Account balance to reflect any such amounts that we receive on your behalf.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Limited Liability</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            You understand that even authorized Transactions may be subject to a Chargeback. We are not responsible for or liable to you for authorized and completed Charges that are later the subject of a Chargeback, Refund, or Reversal, are submitted without authorization or in error, or violate any applicable laws or regulations.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">General Liability</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            You are immediately responsible to us for all Chargebacks, Refunds, Reversals, or Fines (as defined below) regardless of the reason or timing. We may decline to act upon a Refund instruction, or delay execution of the instruction, if:
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>it would cause your Account balance to become negative;</li>
                            <li>you are the subject of bankruptcy proceedings; or</li>
                            <li>where we otherwise believe that there is a risk that you will not meet your liabilities under this Agreement (including with respect to the Charge that is the subject of the Refund instruction).</li>
                        </ol>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Please note that Payment Method Providers and/or Acquirers may also initiate Reversals.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Non-Bank Institution declaration</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            CoopNET Mobile is not a bank and we do not accept deposits, provide loans or extend credit. If you accept payment for products or services not immediately deliverable to the Customer, we may, in our sole discretion, initiate a Reversal. Reversals may also result from
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>invalidation of a charge by a Payment Method Provider or an Acquirer;</li>
                            <li>funds settled to you in error or without authorization; and</li>
                            <li>submission of a Charge in violation of this Agreement, Third-Party Rules, and/or applicable laws and regulations.</li>
                        </ol>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Chargebacks Disputes</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            You may be able to challenge a Chargeback by submitting evidence to us through various channels, including email or as otherwise indicated by CoopNET Mobile. In the future, we may also allow you submit such evidence through the CoopNET Mobile Dashboard (the “Dashboard”). We may request additional information to provide to Payment Method Providers and Acquirers to assist you in contesting the Chargeback, but we cannot guarantee that your challenge will be successful. Payment Method Providers and Acquirers may deny your challenge for any reason they deem appropriate. You may not submit a new Charge which duplicates a Transaction that is subject to a Chargeback.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Liability against losses caused by Fraud</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            You are liable for all losses you incur when lost or stolen payment credentials or accounts are used to purchase products or services from you. CoopNET Mobile does not and will not insure you against losses caused by fraud under any circumstances.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Customer Support</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            CoopNET Mobile will provide you with support in the event you have general issues relating to your Account or your use of the Payment Processing Services. We may make this support available to you via our website or our API documentation. If you cannot resolve the issues you are having with the support we make available to you via our website or our API documentation, please feel free to contact us.
                        </p>

                        <p class="coopnet-text-mobile-primary is-size-5 ">5. Taxes, Fees and Penalties</p>
                        <p class="coopnet-text-mobile-subtitle is-size-6">Fees and Penalties</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            CoopNET Mobile will provide the Payment Processing Services to you at the rates and for the fees (the “Fees”) described on the CoopNET Mobile website. We may revise the Fees at any time. In case Fees are revised, CoopNET Mobile will provide you with no less than 30 days advance notice of such change.
                            <br><br>
                            In addition to the Fees, you are also responsible for any penalties or fines imposed on you in relation to your Account by CoopNET Mobile or any Payment Method Provider or Payment Method Acquirer resulting from your use of Payment Processing Services in a manner not permitted by this Agreement or a Payment Method Provider’s Third-Party Rules (each such fine or penalty, a “Fine”).
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Taxes</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Our fees are exclusive of any applicable Taxes, except as expressly stated to the contrary. You shall pay, indemnify, and hold CoopNET Mobile harmless from
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>any sales, use, excise, import or export, value-added, or similar tax or duty, and any other tax or duty not based on CoopNET Mobile’s income, and</li>
                            <li>all government permit fees, customs fees and similar fees which CoopNET Mobile may incur with respect to this Agreement.</li>
                        </ol>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Such taxes, fees and duties paid by you shall not be considered a part of, a deduction from, or an offset against, payments due to CoopNET Mobile hereunder.
                            <br><br>
                            Additionally, you understand that we may send documents to you and tax authorities for Transactions processed using the Services. Pursuant to applicable law, we may be required to file periodic informational return with taxing authorities in relation to your use of the Payment Processing Services.
                        </p>


                        <p class="coopnet-text-mobile-primary is-size-5 ">6. Account Settlement</p>
                        <p class="coopnet-text-mobile-subtitle is-size-6">Accounts Settlement General</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Subject to the terms of this Agreement, CoopNET Mobile will instruct the Acquirer to remit to the bank or other financial institution account that you designate (your “Bank Account”), all amounts due to you from your Transactions, minus any Fees, Fines, Reversals, Chargebacks, Refunds, setoffs, recoupments or other amounts that you owe to CoopNET Mobile under this Agreement. You affirm that you are authorized to initiate settlements to and debits from your Bank Account, and that your Bank Account is owned by you. If you update your Bank Account, then you must ensure that you continue to comply with the requirements of this section. We may require you to provide us with documentary proof demonstrating your compliance with this section, and your failure to provide such proof will constitute a breach of this Agreement.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Accounts Settlement Insufficient Pay-out</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            If the Payout is not sufficient to cover the amounts due, without limiting our other rights and remedies (all of which are cumulative), you agree that we may debit your Bank Account for the applicable amounts, and/or set-off the applicable amounts against future Payouts. Upon our request, you agree to provide us with all necessary bank account routing and related information and grant us permission to debit amounts due from your Bank Account.
                            <br>
                            We may require a holding period before making initial settlement to your Bank Account.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Pay-out schedule</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Your Payout Schedule (i.e. the time it takes for CoopNET Mobile to initiate settlement to your Bank Account) will be specified on your Dashboard, or as otherwise designated by CoopNET Mobile. Please be aware that a Payment Method Provider, an Acquirer, or the financial institution holding your Bank Account may delay settlement for any reason (and thus delay your Payout Schedule), and we are not responsible for any harm suffered by you stemming from such delay. We are not responsible for any action taken by the institution holding your Bank Account to not credit your Bank Account or to otherwise not make funds available to you as you expected. 
                        </p>
                        
                        <p class="coopnet-text-mobile-subtitle is-size-6">Right to Suspend settlement</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            We reserve the right to suspend settlement to you. Examples of situations where we may do so are:
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>where there are pending, anticipated, or excessive Chargebacks, Refunds, or Reversals;</li>
                            <li>in the event that we suspect or become aware of suspicious activity; or</li>
                            <li>where we are required by applicable law or court order.</li>
                        </ol>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            We have the right to withhold settlement to your Bank Account upon termination of this Agreement if we reasonably determine that we may incur losses resulting from credit, fraud, or other legal risks associated with your Account. If we exercise our right to withhold a Payout for any reason, we will communicate the general reason for withholding the Payout and give you a timeline for releasing the funds.
                        </p>

                        <p class="coopnet-text-mobile-primary is-size-5 ">7. Reserves</p>
                        <p class="coopnet-text-mobile-subtitle is-size-6">General Provision</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            CoopNET Mobile, in its sole discretion, may place a Reserve on a portion of your Payouts in the event that we believe that there is a high level of risk associated with your business. If we place a reserve on your Payouts, we will provide you with notice specifying the terms of the Reserve. The terms may require that a certain percentage of your Payouts are held for a certain period of time, that a fixed amount of your Payouts are withheld from payout to you, or such other restrictions that CoopNET Mobile determines in its sole discretion. CoopNET Mobile may change the terms of the Reserve at any time by providing you with notice of the new terms. 
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Managing Reserves</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            CoopNET Mobile may hold a Reserve as long as it deems necessary, in its sole discretion, to mitigate any risks related to your Transactions. You agree that you will remain liable for all obligations related to your Transactions even after the release of any Reserve. In addition, we may require you to keep your Bank Account available for any open settlements, Chargebacks and other adjustments. 
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Security Interest</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            As security for the payment and performance of all of your now existing or hereinafter arising or incurred obligations under this Agreement or any other obligation to CoopNET Mobile, you grant to CoopNET Mobile a lien on, and security interest in and to, all your now existing or hereafter right, title and/or interest in, to or under this Agreement, the Reserve, all funds held in Reserve, all Reserves at any time established in accordance with this Agreement, and all proceeds of any of the foregoing. You agree to duly execute and deliver to CoopNET Mobile such instruments and documents as may be required to perfect and otherwise give effect to the lien and security interest granted to CoopNET Mobile under this Agreement. 
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Dormant Accounts</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            If you leave any funds dormant in your Account for a period of ten (10) years and you do not give us instructions where to send them, we may be required by applicable law to deem the funds to be abandoned by you, and to deliver them to the Philippine government. To the extent required by applicable law, we will attempt to provide you with notice if we hold funds payable to you in an account beyond the applicable dormancy period for abandoned property. 
                        </p>

                        <p class="coopnet-text-mobile-primary is-size-5 ">8. Suspension or Refusal of Transactions</p>
                        <p class="coopnet-text-mobile-subtitle is-size-6">Suspension or Refusal of Transactions</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            CoopNET Mobile may refuse, condition, or suspend any Transactions that we believe:
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>may violate this Agreement or other agreements you may have with us;</li>
                            <li>are unauthorized, fraudulent or illegal; or</li>
                            <li>expose you, CoopNET Mobile, or others to risks unacceptable to CoopNET Mobile.</li>
                        </ol>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            If we suspect or know that you are using or have used the Services for unauthorized, fraudulent, or illegal purposes, we may share any information related to such activity with the appropriate financial institution, regulatory authority, or law enforcement agency consistent with our legal obligations. This information may include information about you, your Account, your Customers, and Transactions made through your use of the Payment Processing Services.
                        </p>
                        
                        <p class="coopnet-text-mobile-primary is-size-5 ">9. Customer relationship</p>
                        <p class="coopnet-text-mobile-subtitle is-size-6">PLATFORM</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Supplier provides the technological platform, which is the CoopNET Mobile system, that bridges the connection between the cooperatives and/or their members to UnionBank of the Philippines so that they can avail of the financial products and services of UnionBank of the Philippines. [Bank] is an entity separate and distinct from Supplier. It is duly registered with the Securities and Exchange Commission and regulated by the Bangko Sentral ng Pilipinas. All rules and regulations applied by UnionBank of the Philippines to Supplier shall likewise be applicable to the cooperatives and/or their members who avail of UnionBank of the Philippines’s services.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">General Provision</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            The Supplier provides, manages and operates CoopNET Mobile, a platform that facilitates and supervises the lending process between the borrower and the lender, which is an entity separate and distinct from the Supplier. The terms and conditions of the loan shall be governed by a separate agreement between the lender and borrower. The Supplier merely operates the CoopNET Mobile platform and does not act as lender or guarantor of any loan that may be processed through the platform.
                        </p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            The lender and borrower agree to hold Supplier free and harmless from any liability that may arise between the said lender and borrower or any third party notwithstanding their use or availment of the CoopNET Mobile platform. Any action or decision taken by any registered user when using the platform shall be its sole responsibility. The lender and borrower likewise agree to indemnify the Supplier for any damage that Supplier may incur as a result of the lender and/or borrower’s breach of any of the above representations or warranties. 
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>Lender’s terms and conditions. Users of CoopNET Mobile intending to act as lenders will be subject to accepting services agreement relating to money lending services on the Platform and lender’s terms and conditions. For avoidance of doubt, all such texts and documents will be easily accessible on the CoopNET Mobile for such users;</li>
                            <li>Borrower’s documentation. Users of CoopNET Mobile intending to act as borrowers will be subject to accepting loan agreement establishing rights and obligations between the lender and the borrower. </li>
                        </ol>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            You may only use our Payment Processing Services to conduct legitimate Transactions with your Customers. You are responsible for your relationship with your Customers. We are not responsible for the products or services you publicize or sell, or that your Customers purchase using the Payment Processing Services; or if you accept donations, for your communication to your Customers of the intended use of such donations. You understand and affirm that you are solely responsible for the nature and quality of the products or services you provide, and for delivery, support, refunds, returns, and for any other ancillary services you provide to your Customers.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">ATM Card/Debit Card</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            At the Customer’s request, a banking institution, through the Supplier, shall issue a UnionBank of the Philippines ATM Card to the Customer. The UnionBank of the Philippines Card is a payment and withdrawal card that allows a member to access his or her bank account balance through Point-of-Sale (POS) terminals, Automated Teller Machines (ATMs), and eCommerce websites, as may be applicable. Transactions made using the [Bank] Card require a card verification process in the form of either a PIN (Personal Identification Number) or signature. The term “Bank” or “Banking institution” shall refer to an entity performing banking functions as defined by law, and rules and regulations issued by pertinent regulatory bodies such as the Bangko Sentral ng Pilipinas (“BSP”), Securities and Exchange Commission (“SEC”), Anti-Money Laundering Council (“AMLC”), and Philippine Deposit Insurance Corporation (“PDIC”). 
                        </p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            The Customer’s use of the  UnionBank of the Philippines Card constitutes Costumer’s concurrence to and acceptance of this Agreement and the Terms and Conditions of the issuing Bank governing PRODUCTS, SERVICES, FACILITIES, AND CHANNELS, as well as Customer’s acknowledgement  and full understanding of the corresponding risks attendant to the availment of these products, facilities, services, and channels. 
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Services Disclaimer</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Although we provide you with our Payment Processing Services, we have no way of knowing if any Transaction is accurate or complete, or normal for your business in particular or your type of business. Only you are responsible for knowing whether a Transaction initiated by your Customer is erroneous (such as a Customer purchasing one item when they meant to order another) or suspicious (such as unusual or large purchases, or a request for delivery to a foreign country where this typically does not occur). If you are unsure if a Transaction is erroneous or suspicious, you agree to research the Transaction and, if necessary, contact your Customer before fulfilling or completing the Transaction. You are solely responsible for any losses you incur due to erroneous or fraudulent Transactions in connection with your use of the Services. 
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Customer Responsibility over their Customers</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Except where CoopNET Mobile and a Customer have otherwise agreed, you maintain the direct relationship with your Customers and are responsible for: 
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>acquiring appropriate consent to submit Charges through the Payment Processing Services on their behalf;</li>
                            <li>providing confirmation or receipts to Customers for each Charge;</li>
                            <li>verifying Customers’ identities; and</li>
                            <li>determining a Customer’s eligibility and authority to complete Transactions.</li>
                        </ol>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Accounts</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Accounts 
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Customer Responsibility on the Payment processing Services</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Customers must understand the purpose, amount, and conditions of Charges you submit through the Payment Processing Services. When using the Payment Processing Services you agree to: 
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>accurately communicate, and not misrepresent, the nature of the Transaction, and the amount of the Charge in the appropriate currency prior to submitting it to the API;</li>
                            <li>provide a receipt that accurately describes each Transaction to Customers;</li>
                            <li>provide Customers a meaningful way to contact you in the event that the product or service is not provided as described;</li>
                            <li>not use Services to sell products or services in a manner that is unfair or deceptive, exposes Customers to unreasonable risks, or does not disclose material terms of a purchase in advance; and</li>
                            <li>inform Customers that CoopNET Mobile and its affiliates process Transactions (including payment Transactions) for you. You also agree to maintain and make available to your Customers a fair and neutral return, refund, cancellation, or adjustment policy, and clearly explain the process by which Customers can receive a Refund.</li>
                        </ol>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Undertaking</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            You hereby agree to undertake, and you acknowledge and understand that it is your sole responsibility, to 
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>obtain your Customers’ consent to these Terms and the Privacy Policy and</li>
                            <li>provide all notices and obtain all consents necessary for CoopNET Mobile’s use of Customer Data (as defined in this document).</li>
                        </ol>

                        <p class="coopnet-text-mobile-primary is-size-5 ">10. Application Programming Interface</p>
                        <p class="coopnet-text-mobile-subtitle is-size-6">API Access</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            CoopNET Mobile will provide you access to its Application Programming Interface (“APIs”) that you will use to access the Payment Processing Services. You may use the APIs solely as described in the API documentation or as otherwise communicated to you by us. We may update the APIs and API documentation from time to time, and may add or remove functionality. To the extent possible, we will communicate to you any changes in functionality. 
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">API Keys and Authorization</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            You will be provided with API keys, and you can manage these keys from the Dashboard. Public keys are meant to be used for transactions that are accessed through public channels (e.g. generating tokens for credit card numbers from your web application), and secret keys are for transactions that are done internally in your systems (e.g. creating an actual charge). You are responsible for securing your secret keys – do not publish or share them with any unauthorized persons. Failure to secure your secret keys will increase the likelihood of fraud on your Account and potential losses to you or your Customers. You should contact us immediately if you become aware of any unauthorized use of your secret key or any other breach of security regarding the Services. We will provide more details on proper use of public and secret API keys in the API documentation. 
                        </p>

                        <p class="coopnet-text-mobile-primary is-size-5 ">11. Intellectual Property & Licenses</p>
                        <p class="coopnet-text-mobile-subtitle is-size-6">Ownership of Our IP</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            CoopNET Mobile owns all rights, title, and interest in the patents, copyrights (including rights in derivative works), moral rights, rights of publicity, trademarks or service marks, logos and designs, trade secrets, and other intellectual property embodied by, or contained in the API and API documentation, Services (including all files and content), and the Dashboard (collectively, “Our IP”) or any copies thereof. Our IP is protected by copyright, trade secret, trademark, patent, and other intellectual property laws, and all rights in Our IP not expressly granted to you in this Agreement are reserved. To be clear, other than the express licenses granted by this Agreement, CoopNET Mobile grants no right or license by implication, estoppel or otherwise to Our IP. You may not sell, redistribute, download, export, or reproduce Our IP in any way. You also may not decompile, reverse-engineer, disassemble, or otherwise convert Our IP without our permission.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Improvement Ideas and Suggestions</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            You may choose to or we may invite you to submit comments or ideas about improvements to the Service, our API, our platform, or any other component of our products or services (“Ideas”). If you submit an Idea to us, we will presume that your submission was voluntary, unsolicited by us, and delivered to us without any restrictions on our use of the Idea. You also agree that CoopNET Mobile has no fiduciary or any other obligation to you in connection with any Idea you submit to us, and that we are free to use your Ideas without any attribution or compensation to you. You also agree that we do not waive any rights to use similar or related Ideas previously known to us, developed by our employees or contractors, or obtained from other sources. 
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Software License</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            CoopNET Mobile hereby grants you a revocable, non-exclusive, and non-transferable limited license to access and/or use CoopNET Mobile’s APIs, developer’s toolkit, and other software in accordance with the accompanying API documentation made available by CoopNET Mobile for purposes of using the Payment Processing Services. This license grant includes all updates, upgrades, new versions and replacement software for your use in connection with the Payment Processing Services. If you do not comply with these Terms, the API documentation, and any other requirements provided by CoopNET Mobile, then you will be liable for all resulting damages suffered by you, CoopNET Mobile and third parties. Upon expiration or termination of this Agreement, you will immediately cease all use of the Service, including the API and the Payment Processing Services. 
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Trademark License</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Additionally, CoopNET Mobile grants you a revocable, non-exclusive, non-transferable license to use CoopNET Mobile’s trademarks used to identify the Payment Processing Services (the “Trademarks”) solely in conjunction with the use of the Payment Processing Services. You may only use the Trademarks on the portion of your website or application that directly relates to the Payment Processing Services, such as on a checkout page utilizing our Payment Processing Services. All Trademarks should directly link to our homepage. CoopNET Mobile may, in the future, provide you with additional guidelines governing your use of the Trademarks. 
                        </p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            You agree that you will not at any time during or after this Agreement assert or claim any interest in or do anything that may adversely affect the validity of any Trademark or any other trademark, trade name or product designation belonging to or licensed to CoopNET Mobile (including, without limitation registering or attempting to register any Trademark or any such other trademark, trade name or product designation). You may not use the Trademarks to imply endorsement by CoopNET Mobile of your products or services, or in a manner that causes customer confusion. You may not misrepresent your relationship with CoopNET Mobile, or use the Trademarks in any manner that is misleading or damaging to CoopNET Mobile, in CoopNET Mobile’s sole discretion. In addition to any other right that we may have to enforce the terms of this Agreement, you must promptly comply with any request that we make for you to cease a use of the Trademarks that we determine is non-compliant with this paragraph. Upon expiration or termination of this Agreement, you will immediately cease all display, advertising and use of all of the Trademarks.
                            <br><br>
                            As part of this Agreement, we do not make any representations regarding your use of the Trademarks. We disclaim all warranties, express and implied, including any warranties of non-infringement.
                        </p>

                        <p class="coopnet-text-mobile-primary is-size-5 ">12. Data Usage, Privacy, and Security</p>
                        <p class="coopnet-text-mobile-subtitle is-size-6">Customer Data</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Basic Information. In order for your Customers to process a Transaction through the Payment Processing Services, they will be required to provide you with certain personally identifiable information, which, in turn, you provide to us through your use of the Payment Processing Services. This information may include, but is not limited to:
                            <br>
                            First and last name;
                            <br>
                            Physical and/or mailing address;
                            <br>
                            Financial information, including but not limited to credit or debit card numbers or bank account information;
                            <br>
                            Email address; and,
                            <br>
                            Phone number.
                        </p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">Additionally, CoopNET Mobile may automatically collect information from Customers and their devices and browsers, including: </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>Device information, such as a unique device identifier; and,</li>
                            <li>Location information, such as your IP address or geo-location.</li>
                        </ol>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Customer Data Ownership</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            All Customer Data shall be owned by you and you hereby grant CoopNET Mobile a perpetual, irrevocable, sub-licensable, assignable, worldwide, royalty-free license to use, reproduce, electronically distribute, and display Customer Data for the following purposes:
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>providing and improving the Services;</li>
                            <li>internal usage, including but not limited to, data analytics and metrics so long as such Customer Data has been anonymized and aggregated with other customer data;</li>
                            <li>complying with applicable legal requirements and assisting law enforcement agencies by responding to requests for the disclosure of information in accordance with applicable law; and</li>
                            <li>complying with applicable legal requirements and assisting law enforcement agencies by responding to requests for the disclosure of information in accordance with applicable law; and</li>
                            <li>any other purpose for which consent has been provided by the Customer. You hereby agree to undertake, and you acknowledge and understand that it is your sole responsibility to provide all notices to and obtain all consents from Customers necessary for CoopNET Mobile’s use of Customer Data as set out above.</li>
                        </ol>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            For the purposes of this Agreement, “Customer Data” means the personal data that
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>the Customer provides to you and you pass on to CoopNET Mobile through your use of the Payment Processing Services and</li>
                            <li>CoopNET Mobile collects from the Customer’s device and browser through your use of the Payment Processing Services.</li>
                        </ol>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Publicity and Advertising</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            You hereby grant CoopNET Mobile permission to use your name and logo in CoopNET Mobile’s marketing materials including, but not limited to use on CoopNET Mobile’s website, in customer listings, in interviews and in press releases.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Information shared to us</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            As part of the Service, we may provide you with various information in furtherance of the Service. Our intention in doing so is to be helpful and to make the Service more useful to you. However, you agree that all information and suggestions that we provide to you through the Service is strictly for informational purposes and shall not be construed or relied upon in any way, and specifically shall not be construed or relied upon as professional advice of any kind. You agree that, to the fullest extent permitted by law, and as detailed below in the “Limitation of Liability” section of these Terms, we will not incur any liability at all whatsoever in the event that your reliance on any information provided by us results in harm or damage to you or your property.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Information shared to Customer</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            As a core aspect of the functionality of the Service, you may provide us with information about yourself, by giving us access to information collected by third parties, and by using the Service. By providing this information and content, you grant us the right to use the information and content for the purposes described in these Terms and our Privacy Policy. While we do not claim ownership over any such information and content that you provide, you agree that we have the right to use such information and content as specified in these Terms and our Privacy Policy. Further, by providing us with information and content through the Service, you represent and warrant that you own, or have the full legal authority to distribute, all information that you provide on the Service.
                            <br><br>
                            In addition, we should not be relied upon as a means to store your data or information and we assume no responsibility for such activities.
                        </p>

                        <p class="coopnet-text-mobile-primary is-size-5 ">13. Use of the Service</p>
                        <p class="coopnet-text-mobile-subtitle is-size-6">Inapproriate and Illegal Content Prohibited</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            You agree not to transmit any inappropriate content on the Service including, but not limited to, libelous, defamatory, obscene, pornographic, abusive, or threatening content; content that advocates or encourages conduct that could constitute a criminal offense, give rise to civil liability, or otherwise violate any applicable local, state, national, or foreign law or regulation; content that is misleading or not true; or advertise or otherwise solicits funds for goods or services. We may remove such content from our servers, and we may suspend or revoke your access to the Service, and we reserve the right to investigate, and seek applicable remedies for, violations of applicable law to the fullest extent of the law.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Termination of Accounts</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            CoopNET Mobile may terminate Accounts that have been the subject of five (5) separate DMCA notices. In the event a user's materials are removed due to a DMCA Notice and then subsequently restored due to the filing of a counter-notification, CoopNET Mobile will treat the underlying DMCA Notice as withdrawn. CoopNET Mobile reserves the right to terminate Accounts that are the subject of fewer than five (5) DMCA Notices in appropriate circumstances - such as where the user has a history of violating or willfully disregarding these Terms.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Use of Others' Intellectual Property</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Although you may provide information and content to CoopNET Mobile as part of your use of the Service, you agree to be respectful of others’ intellectual property rights. You may not upload, transmit, or otherwise distribute any information or content in violation of intellectual property laws or proprietary rights of any third parties. If you do not respect a third party’s intellectual property or proprietary rights, you are solely responsible for any violations of law. In addition, you are solely responsible for infringement of third-party rights caused by any information that is generated or submitted through your use of the Service. We take claims of intellectual property infringement seriously. As such, we reserve the right to suspend and/or revoke access to the Service for any user who is found to have infringed on the intellectual property rights of third parties, or us, or otherwise is found to have violated any intellectual property laws.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Third Party Sites and Services</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Our Service is integrated with services provided by third parties as part of the functionality of the Service. We have no control over third parties and make no guarantees about, and assume no responsibility for, the information or services provided by third parties.
                            <br><br>
                            Additionally, we may provide links to third-party websites and businesses and otherwise may provide information on the Service. You acknowledge and agree that we are not responsible for the accuracy, content, or functionality of services or information provided on or by such third-party websites or businesses or through such information on the Service, and we do not endorse, nor assume any liability related to your use of, such websites, businesses or information in any way. Nothing on the Service shall be considered an endorsement, representation, or warranty of anything posted by other users on the Service.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Services Termination General Provision</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            At any time, you may discontinue your use of the Service. Please visit our Privacy Policy to understand how we treat your information when you discontinue your use of the Service.
                            <br><br>
                            We reserve the right to take action to limit or prevent your access to our Service, if we, in our sole discretion, deem that such action is necessary based on: your use of the Service in a way that would potentially expose us to liability; disruption of the Service by you to others; your violation of these Terms or our Privacy Policy; your violation of any applicable laws, rules, regulations, and agreements that may apply to you; and your use of the Service in a way that could cause harm to any person.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Responsibilities after Services Termination</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Termination does not immediately relieve you of obligations incurred by you under this Agreement. Upon termination, you agree to (i) complete all pending Transactions, (ii) stop accepting new Transactions, and (iii) immediately remove all Trademarks from your website (unless permitted under a separate license with the payment network). Your continued or renewed use of the Services after all pending Transactions have been processed serves to renew your consent to the terms of this Agreement. If you terminate this Agreement, we will pay out any remaining funds owed to you subject to these Terms.
                            <br><br>
                            After termination by either party, you shall no longer have access to, and shall cease all use of the Services. Any termination of this Agreement does not relieve you of any obligations to pay any Fees or any other financial obligation incurred by you or through your use of the Services prior to or after termination. In addition, all licenses granted to you by CoopNET Mobile under this Agreement will end.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Proper Use of the Service</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            You shall not violate or attempt to violate any security features of the Service, including, without limitation, (a) accessing content or data not intended for you, or logging onto a server that you are not authorized to access; (b) attempting to probe, scan, or test the vulnerability of the Service, or any associated system or network, or to breach security or authentication measures without proper authorization; (c) interfering or attempting to interfere with service to any user, host, or network, including, without limitation, by means of submitting a virus to the Service, overloading, “flooding,” “spamming,” “mail bombing,” “crashing,” or undertaking similar activities; (d) resell the Services or permit third parties to use the Services without our prior written consent; (e) make unauthorized copies of any content in the Services; and (f) forging any TCP/IP packet header or any part of the header information in any e-mail or in any posting using the Service.
                        </p>

                        <p class="coopnet-text-mobile-primary is-size-5 ">14. Additional Legal Terms</p>
                        <p class="coopnet-text-mobile-subtitle is-size-6">Assumption of Risk</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            YOUR USE OF THE SERVICE IS ENTIRELY AT YOUR OWN RISK.
                            <br><br>
                            YOU AGREE THAT IF YOU CHOOSE TO USE THE SERVICE, YOU ARE DOING SO VOLUNTARILY. YOU ASSUME ALL SUCH RISKS WITHOUT LIMITATION.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Disclaimer of Warranties</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            WE ARE MAKING THE SERVICE AVAILABLE “AS IS” AND, TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE SERVICE, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. YOU ASSUME THE RISK OF ANY AND ALL DAMAGE OR LOSS FROM USE OF, OR INABILITY TO USE, THE SERVICE. WE DO NOT WARRANT THAT OPERATION OF THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE.
                        </p>
                        
                        <p class="coopnet-text-mobile-subtitle is-size-6">Limitation of Liability</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            To The Maximum Extent Permitted By Law, We Shall Not Be Liable For Damages Of Any Kind (Including, But Not Limited To, Special, Incidental, Or Consequential Damages, Lost Profits, Or Lost Data, Regardless Of The Foreseeability Of Those Damages) Arising Out Of Or In Connection With Your Use Of The Service Including, But Not Limited To, Damages Arising From Or Related To Any Of The Following: Any Claim Related To Personal Or Bodily Injury In Connection With The Use Of The Service; Any Harm Or Damage Caused By, Or Otherwise Related To, Your Reliance On Information Provided Through The Service; Any Loss Or Unauthorized Disclosure Of Data; Any Harm Or Damage Caused By, Or Otherwise Related To, Your Use Of Or Interaction With Any Services Offered Through The Service; And Any Violations Of Applicable Laws, Rules, Regulations, And Other Agreements. This Limitation Shall Apply Regardless Of The Legal Theory Or Form Of Action.
                        </p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            In No Event Will Our Aggregate Liability Arising Out Of Or In Connection With These Terms And Your Use Of The Service Exceed One Hundred Dollars ($100.00).
                        </p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            The Limitations Of Damages Set Forth Above Are Fundamental Elements Of The Basis Of The Bargain Between You And Us.
                        </p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Some Jurisdictions Do Not Allow The Exclusion Or Limitation Of Liability For Certain Types Of Damages Or Causes Of Action, So The Above Limitation May Not Apply To You.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Indemnification</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            In the event that any third party brings a claim against us related to your actions, content, information, or any other use of the Service by you, you agree to indemnify, defend, and hold us and our agents harmless from and against any and all third party claims, losses, liability, damages, and/or costs (including reasonable attorney fees and costs) relating to such claim. We will notify you promptly of any such claim and will provide you with reasonable assistance, at your expense, in defending any such claim, provided, however, that our failure to so notify you shall not relieve you of your indemnity obligations, but instead shall reduce those obligations by the amount of damages or increased costs and expenses attributable to our failure to give notice. We reserve the right to approve counsel retained by you, to take control of the defense (at our expense) of any claim for which indemnity is required, and to participate in the defense of any claim (at our expense) for which indemnity is required. You may not settle any claim without our prior consent.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Dispute Resolution – Event</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            You agree to resolve any dispute, claim, or controversy with CoopNET Mobile arising out of or relating to your use in any way of the Service in the following manner. First, we both agree to attempt in good faith to resolve the dispute informally by contacting each other by email. Second, if the dispute is not resolved through informal resolution, we both agree to attempt in good faith to resolve the dispute through mediation administered by the court, which shall take place in Pasig City, Philippines, and the costs of which shall be divided equally between you and CoopNET Mobile. Third, if any such dispute is not resolved through informal resolution and mediation, we both agree to first refer such dispute for settlement through binding arbitration administered by court, which shall take place in Pasig City, Philippines.
                        </p>
                        
                        <p class="coopnet-text-mobile-subtitle is-size-6">Dispute Resolution - Fees</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Either you or we may bring a lawsuit solely for injunctive relief without first engaging in the dispute resolution process described above.
                            <br><br>
                            We both agree that, in the event of arbitration, or in the event of a lawsuit as permitted by this Section or otherwise, the prevailing party shall be entitled to costs and fees (including reasonable attorneys’ fees). Arbitration pursuant to this Section shall be confidential, and neither you, nor CoopNET Mobile, nor the arbitrator may disclose the existence, content or results of any arbitration, except as may be required by law or for purposes of enforcement or appeal of the arbitration award. Judgment on any arbitration award may be entered in any court having proper jurisdiction. There shall be no right or authority for any claims subject to this arbitration clause to be arbitrated on a class action or consolidated basis or on bases involving claims brought in a purported representative capacity on behalf of the general public (including, but not limited to, as a private attorney general). If any portion of this arbitration clause is determined by a court to be inapplicable or invalid, then the remainder shall still be given full force and effect.
                            <br><br>
                            Nothing contained in this Section shall limit our ability to take action related to your access to the Service as provided in these Terms.
                        </p>
                        
                        <p class="coopnet-text-mobile-subtitle is-size-6">Governing Law, Venue, and Personal Jurisdiction</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            These Terms shall be governed by the laws of the Republic of the Philippines, without regard to conflict of law provisions. In the event a lawsuit is filed subsequent to a failure of dispute resolution as provided under the immediately preceding provisions, we both agree that any judicial proceeding shall be brought exclusively in the appropriate courts of the City of Makati, Philippines.
                        </p>
                        
                        <p class="coopnet-text-mobile-subtitle is-size-6">Severability Waiver</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            If, for whatever reason, any term or condition in these Terms is found unenforceable, all other terms and conditions will remain unaffected and in full force and effect. The failure to enforce any provision of these Terms is not a waiver of our right to do so later, and no waiver shall be effective unless made in writing and signed by an authorized representative of the waiving party.
                        </p>
                        
                        <p class="coopnet-text-mobile-subtitle is-size-6">Changes to These Terms</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            CoopNET Mobile reserves the right to change these Terms from time to time, with or without notice to you. If you continue to use the Service, you consent to the new Terms. Any changes to these Terms will become effective on the “Effective Date” indicated above. If you continue to use the Service after the Effective Date, you consent to the new Terms. CoopNET Mobile will always have the latest Terms posted on the Service.
                        </p>
                        
                        <p class="coopnet-text-mobile-subtitle is-size-6">Contact Us</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            If you have any questions about these Terms or our Service, please feel free to contact us by email <a href="mailto:contact@coopnet.online">contact@coopnet.online</a>
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Headings Used in These Terms</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            The section headings contained in these Terms are for reference purposes only and shall not affect the meaning or interpretation of these Terms in any way.
                        </p>

                        <br><br>

                        <p class="coopnet-text-mobile-primary is-size-6">ANNEX OF PAYMENT PROVIDERS AND ACQUIRERS</p>

                        <div class="columns">
                            <div class="column">
                                <p class="coopnet-text-mobile-subtitle has-text-weight-bold is-size-6">Payment Provider and/or Acquirer</p>
                                <p class="coopnet-text-mobile-paragraph is-size-6">
                                    PayMaya: PayMaya Philippines Inc.
                                </p>
                                <p class="coopnet-text-mobile-paragraph is-size-6">
                                    DragonPay: DragonPay Corporation
                                </p>
                                <p class="coopnet-text-mobile-paragraph is-size-6">
                                    Coins.ph
                                </p>
                                <p class="coopnet-text-mobile-paragraph is-size-6">
                                    Union Bank of the Philippines
                                </p>
                                <p class="coopnet-text-mobile-paragraph is-size-6">
                                    ECPay: Electronic Commerce Payment Inc.
                                </p>
                                <p class="coopnet-text-mobile-paragraph is-size-6">
                                    Mynt: Globe Fintech Innovations Inc.
                                </p>
                            </div>
                            <div class="column">
                                <p class="coopnet-text-mobile-subtitle has-text-weight-bold is-size-6">Payment Type</p>
                                <p class="coopnet-text-mobile-paragraph is-size-6">
                                    Online Banking, OTC, OTC Non-Bank, Supported Banks, Cebuana Lhuilier
                                </p>
                                <p class="coopnet-text-mobile-paragraph is-size-6">
                                    Cebuana Lhuilier, Other OTC payments, Coins.ph
                                </p>
                                <p class="coopnet-text-mobile-paragraph is-size-6">
                                    Instapay, Pesonet
                                </p>
                                <p class="coopnet-text-mobile-paragraph is-size-6">
                                    7-Eleven
                                </p>
                                <p class="coopnet-text-mobile-paragraph is-size-6">
                                    Gcash
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PrivacyPolicyComponent",
    };
</script>

<style scoped>
    .coopnet-text-title{
        color: black;
        text-align: center;
        font-weight: 600;
        margin: 10px 0px;
    }
    .coopnet-text-primary{
        color: #004e97;
        text-align: left;
        font-weight: bold;
        margin: 20px 0px;
    }
    .coopnet-text-subtitle{
        color: #1882e6;
        text-align: left;
        font-weight: bold;
        margin: 0px 0px;
    }
    .coopnet-text-paragraph{
        color: black;
        text-align: justify;
        font-weight: bold;
        margin: 0px 0px 20px 0px;
        line-height: 1.5;
    }
    .coopnet-text-list{
        color: black;
        text-align: left;
        font-weight: bold;
        margin: 0px 0px 20px 30px;
        line-height: 1.5;
    }

    .coopnet-text-mobile-title{
        color: black;
        text-align: center;
        font-weight: 600;
        margin: 10px 0px;
    }
    .coopnet-text-mobile-primary{
        color: #004e97;
        text-align: left;
        font-weight: bold;
        margin: 20px 0px;
    }
    .coopnet-text-mobile-subtitle{
        color: #1882e6;
        text-align: left;
        font-weight: bold;
        margin: 0px 0px;
    }
    .coopnet-text-mobile-paragraph{
        color: black;
        text-align: justify;
        font-weight: bold;
        margin: 0px 0px 20px 0px;
        line-height: 1.5;
    }
    .coopnet-text-mobile-list{
        color: black;
        text-align: left;
        font-weight: bold;
        margin: 0px 0px 20px 30px;
        line-height: 1.5;
    }

    .rounded-div {
        background-color: white;
        border-radius: 20px;
        min-width: 50vw;
        width: 90%;
        margin: auto;
        padding: 3rem;
    }

    .rounded-div-mobile {
        background-color: white;
        border-radius: 20px;
        min-width: 50vw;
        width: 90%;
        margin: auto;
        padding: 0.5rem;
        
    }
    /* Desktop Specific */
    .main-bg {
        background-image: linear-gradient(270deg, #004e97, #023074);
        background-repeat: repeat-x;
        background-size: cover;
        padding:10px;
        overflow-y: hidden;
    }
    
    /* Mobile */
    .main-bg-mobile {
        background-image: linear-gradient(270deg, #004e97, #023074);;
        height: 100%;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    /* Animate.CSS */
    /* Desktop Animations */
    .head3 {
        opacity: 1;
        animation: fadeInRight;
        animation-duration: 1s;
    }
    
    </style>