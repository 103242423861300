<template>
  <div class="gcash-error">
    <app-gcash-error-page />
  </div>
</template>

<script>
import GCashErrorPageComponent from "@/components/GCashErrorPage/GCashErrorPageComponent.vue";

export default {
  name: "GCashErrorPageView",
  components: {
    "app-gcash-error-page": GCashErrorPageComponent,
  },
};
</script>
