<template>
	<b-button
		class="scrollToTop has-text-white"
		type="is-info"
		rounded
		@click="scrollToTop"
		id="scrollToTopButton"
	>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 384 512"
			class="has-text-white"
			width="1.5rem"
			height="1.5rem"
		>
			<!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
			<path
				d="M374.6 246.6C368.4 252.9 360.2 256 352 256s-16.38-3.125-22.62-9.375L224 141.3V448c0 17.69-14.33 31.1-31.1 31.1S160 465.7 160 448V141.3L54.63 246.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160C387.1 213.9 387.1 234.1 374.6 246.6z"
			/>
		</svg>
	</b-button>
</template>

<script>
export default {
	name: "ScrollToTop",
	created() {
		window.addEventListener("scroll", this.handleScroll);
	},
	destroyed() {
		window.removeEventListener("scroll", this.handleScroll);
	},
	methods: {
		handleScroll() {
			if (
				document.body.scrollTop > 20 ||
				document.documentElement.scrollTop > 20
			) {
				document.getElementById("scrollToTopButton").style.visibility =
					"visible";
			} else {
				document.getElementById("scrollToTopButton").style.visibility =
					"hidden";
			}
		},
		scrollToTop() {
			document.body.scrollTop = 0; // For Safari
			document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		},
	},
};
</script>

<style scoped>
button.scrollToTop {
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	visibility: hidden;
	position: fixed;
	bottom: 20px;
	right: 30px;
	z-index: 99;
	cursor: pointer;
}

svg.has-text-white {
	filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(241deg)
		brightness(107%) contrast(108%);
}
svg {
	margin: auto;
}
</style>

