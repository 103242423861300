<template>
	<div class="privacy-policy">
		<app-privacy-policy />
	</div>
</template>

<script>

import PrivacyPolicyComponent from "@/components/PrivacyPolicy/PrivacyPolicyComponent.vue";

export default {
	name: "PrivacyPolicyView",
	components: {
		"app-privacy-policy": PrivacyPolicyComponent,
	},
};
</script>