<template>
	<div>
		<img :src="require('@/assets' + fileName)" :alt="title" />
		<p class="is-size-4 is-size-5-mobile" :style="{ color: 'rgb(101, 227, 255)' }">
			{{ title }}
		</p>
	</div>
</template>
<script>
export default {
	name: "AboutCoopnetLogoComponent",
	props: {
		title: String,
		fileName: String,
	},
};
</script>
