<template>
	<div>
		<img :src="require('@/assets/' + fileName)" alt="Who We Serve logo" />
		<p
			class="is-size-4 has-text-weight-bold"
			:style="{ color: 'rgb(51, 51, 51)' }"
		>
			{{ title }}
		</p>
	</div>
</template>
<script>
export default {
	name: "WhoWeServeLogoComponent",
	props: {
		title: String,
		fileName: String,
	},
};
</script>