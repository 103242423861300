import Vue from "vue";
import VueRouter from "vue-router";

import HomeView from "@/views/HomeView.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";
import TermsOfUseView from "@/views/TermsOfUseView.vue";
import DragonpaySuccessPageView from "@/views/DragonpaySuccessPageView.vue";
import DigicoopDragonpaySuccessPageView from "@/views/DigicoopDragonpaySuccessPageView.vue";
import GCashSuccessPageView from "@/views/GCashSuccessPageView.vue";
import DigicoopGCashSuccessPageView from "@/views/DigicoopGCashSuccessPageView.vue";
import GCashErrorPageView from "@/views/GCashErrorPageView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicyView,
  },
  {
    path: "/terms-of-use",
    name: "terms-of-use",
    component: TermsOfUseView,
  },
  {
    path: "/dragonpay/success",
    name: "dragonpay-success-page",
    component: DragonpaySuccessPageView,
  },
  {
    path: "/digicoop/v2/dragonpay/success",
    name: "digicoop-dragonpay-success-page",
    component: DigicoopDragonpaySuccessPageView,
  },
  {
    path: "/gcash/success",
    name: "gcash-success-page",
    component: GCashSuccessPageView,
  },
  {
    path: "/digicoop/v2/gcash/success",
    name: "digicoop-gcash-success-page",
    component: DigicoopGCashSuccessPageView,
  },
  {
    path: "/gcash/error",
    name: "gcash-error-page",
    component: GCashErrorPageView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
