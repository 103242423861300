<template>
	<div id="whycoopnet">
        <!-- DESKTOP -->
		<div class="whycoopnet px-6 pt-2 is-hidden-mobile" >
			<div class="container">
				<p
					class="
						is-size-1
						has-text-weight-bold
					"
					:style="{ color: 'rgb(51, 51, 51)' }"
				>
					WHY
					<span :style="{ color: 'rgb(51, 157, 255)' }">
						COOPNET?
					</span>
				</p>
				<div
					class="columns is-centered mt-4"
				>
					<div
						class="column is-3"
						v-for="(logo, index) in whycoopnetlogos"
						:key="index"
					>
						<app-whycoopnet-logo
							:title="logo.title"
							:file-name="logo.fileName"
                            v-infocus="'whycoopnetlogo' + index"
                            class="mt-5 hidden"
						/>
					</div>
				</div>
			</div>
		</div>
        <!-- MOBILE -->
        <div class="whycoopnetmobile px-4 pb-4 is-hidden-tablet" >
			<div class="container pb-6">
				<p
					class="
						is-size-3-mobile
						has-text-weight-bold
					"
					:style="{ color: 'rgb(51, 51, 51)' }"
				>
					WHY
					<span :style="{ color: 'rgb(51, 157, 255)' }">
						COOPNET?
					</span>
				</p>
				<div
					class="columns is-mobile is-centered mt-2"
						v-for="(logo, index) in whycoopnetlogos"
						:key="index"
				>
					<div class="column"
					>
						<app-whycoopnet-logo
							:title="logo.title"
							:file-name="logo.fileName"
                            v-infocus="'whycoopnetlogo' + index + 'mobile'"
                            class="hidden"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import WhyCoopnetLogoComponent from "@/components/WhyCoopnet/WhyCoopnetLogoComponent.vue";
import infocusDirective from "@/components/directives/infocusDirective.vue";

export default {
	name: "WhyCoopnetComponent",
	components: {
		"app-whycoopnet-logo": WhyCoopnetLogoComponent,
	},
	data() {
		return {
			whycoopnetlogos: {
				0: {
					title: "We take care of the cost; no huge float, manage your own wallet balance",
					fileName: "/whycoopnet/coin 1.png",
				},
				1: {
					title: "We take care of compliance, we are just extending the service to network members",
					fileName: "/whycoopnet/coin 2.png",
				},
				2: {
					title: "We take care of systems integration and updates of technology",
					fileName: "/whycoopnet/coin 3.png",
				},
			},
		};
	},
	directives: {
		infocus: infocusDirective,
	},
};
</script>

<style scoped>
.whycoopnet{
    padding-bottom:6rem;
}
/* Hidden before animation plays */
.hidden {
	opacity: 0;
}
/* Animate.CSS */
/* Desktop Animations */
.whycoopnetlogo0 {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 0.3s;
}
.whycoopnetlogo1 {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 0.6s;
}
.whycoopnetlogo2 {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 0.9s;
}

/* Mobile Animations */
.whycoopnetlogo0mobile {
	opacity: 1;
	animation: fadeInLeft;
	animation-duration: 0.3s;
}
.whycoopnetlogo1mobile {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 0.6s;
}
.whycoopnetlogo2mobile {
	opacity: 1;
	animation: fadeInLeft;
	animation-duration: 0.9s;
}
</style>