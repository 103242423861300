<template>
	<div id="coopnetmachine">
		<!-- Desktop -->
		<div class="coopnet-teller-machine ctm-desktop is-hidden-mobile">
			<div class="container pb-0">
				<div class="columns pb-0 mt-2 is-centered">
					<div class="column p-0 mt-2 has-text-left">
						<p
							class="is-size-1 has-text-weight-medium"
							:style="{ color: 'rgb(255, 255, 255)' }"
						>
							COOPNET
							<br />
							<span :style="{ color: 'rgb(101, 227, 255)' }">
								TELLER MACHINE
							</span>
						</p>
						<p
							class="is-size-4 mt-2"
							:style="{ color: 'rgb(255, 255, 255)' }"
						>
							Everything the Mobile App can do
							<span class="has-text-weight-bold">PLUS</span>
							acceptance and disbursement of cash.
						</p>
						<div class="columns mt-2">
							<div
								class="column"
								v-for="(logo, index) in coopnetMachineLogos1"
								:key="index"
							>
								<app-coopnetmachine-logo
									:title="logo.title"
									:file-name="logo.fileName"
								/>
							</div>
						</div>
						<div class="columns is-centered">
							<div
								class="column"
								v-for="(logo, index) in coopnetMachineLogos2"
								:key="index"
							>
								<app-coopnetmachine-logo
									:title="logo.title"
									:file-name="logo.fileName"
								/>
							</div>
						</div>
						<a href="#contactus">
							<b-button
								class="
									button
									avail-yours-now-btn
									mt-2
									is-rounded
								"
								>Avail yours now</b-button
							>
						</a>
					</div>

					<div
						class="column p-0 mt-2"
						:style="{ marginBottom: '-0.3em' }"
					>
						<img
							:src="
								require('@/assets/coopnetmachine/CTM-Animation.gif')
							"
							alt="logo"
							v-infocus="'coopmachine1desktop'"
							class="ctm-gif hidden"
						/>
					</div>
				</div>
			</div>
		</div>
		<!-- Mobile -->
		<div class="coopnet-teller-machine is-hidden-tablet">
			<div class="container">
				<div class="columns is-centered">
					<div class="column has-text-left">
						<p
							class="
								is-size-3-mobile
								py-3
								has-text-centered-mobile has-text-weight-medium
							"
							:style="{ color: 'rgb(255, 255, 255)' }"
						>
							COOPNET
							<br />
							<span :style="{ color: 'rgb(101, 227, 255)' }">
								TELLER MACHINE
							</span>
						</p>
						<p
							class="
								is-size-5-mobile
								px-6
								has-text-justified-mobile
							"
							:style="{ color: 'rgb(255, 255, 255)' }"
						>
							<span class="has-text-weight-semibold"
								>COOPNET Teller Machine
							</span>
							has converged as a standalone product of an advanced
							and digitally service-oriented solution. CTM is a
							technological infrastructure that involves the
							different levels of system technology exclusively
							customized for our members in maximizing the
							availability and accessibility of digital services
							such as:
						</p>
						<div class="columns is-mobile is-centered mt-2">
							<div class="column is-4">
								<app-coopnetmachine-logo
									:title="coopnetMachineLogos1[0].title"
									:file-name="
										coopnetMachineLogos1[0].fileName
									"
									v-infocus="'coopmachineleft1mobile'"
									class="hidden"
								/>
							</div>
							<div class="column is-4">
								<app-coopnetmachine-logo
									:title="coopnetMachineLogos1[1].title"
									:file-name="
										coopnetMachineLogos1[1].fileName
									"
									v-infocus="'coopmachineleft1mobile'"
									class="hidden"
								/>
							</div>
						</div>
						<div class="columns is-mobile is-centered mt-2">
							<div class="column is-4">
								<app-coopnetmachine-logo
									:title="coopnetMachineLogos1[2].title"
									:file-name="
										coopnetMachineLogos1[2].fileName
									"
									v-infocus="'coopmachineright2mobile'"
									class="hidden"
								/>
							</div>
							<div class="column is-4">
								<app-coopnetmachine-logo
									:title="coopnetMachineLogos1[3].title"
									:file-name="
										coopnetMachineLogos1[3].fileName
									"
									v-infocus="'coopmachineright2mobile'"
									class="hidden"
								/>
							</div>
						</div>
						<div class="columns is-mobile is-centered mt-2">
							<div class="column is-4">
								<app-coopnetmachine-logo
									:title="coopnetMachineLogos2[0].title"
									:file-name="
										coopnetMachineLogos2[0].fileName
									"
									v-infocus="'coopmachineleft3mobile'"
									class="hidden"
								/>
							</div>
							<div class="column is-4">
								<app-coopnetmachine-logo
									:title="coopnetMachineLogos2[1].title"
									:file-name="
										coopnetMachineLogos2[1].fileName
									"
									v-infocus="'coopmachineleft3mobile'"
									class="hidden"
								/>
							</div>
						</div>
						<div class="columns is-mobile is-centered mt-2">
							<div class="column is-4">
								<app-coopnetmachine-logo
									:title="coopnetMachineLogos2[2].title"
									:file-name="
										coopnetMachineLogos2[2].fileName
									"
									v-infocus="'coopmachineright4mobile'"
									class="hidden"
								/>
							</div>
							<div class="column is-4">
								<app-coopnetmachine-logo
									:title="coopnetMachineLogos2[3].title"
									:file-name="
										coopnetMachineLogos2[3].fileName
									"
									v-infocus="'coopmachineright4mobile'"
									class="hidden"
								/>
							</div>
						</div>
						<div class="columns is-mobile is-centered">
							<div
								class="column is-flex is-justify-content-center"
							>
								<a href="#contactus">
									<b-button
										class="
											button
											avail-yours-now-btn
											is-rounded is-centered
											hidden
											my-4
										"
										v-infocus="'coopmachineup1mobile'"
										>Avail yours now</b-button
									>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CoopnetMachineLogoComponent from "@/components/CoopnetMachine/CoopnetMachineLogoComponent.vue";
import infocusDirective from "@/components/directives/infocusDirective.vue";

export default {
	name: "CoopnetMachineComponent",
	components: {
		"app-coopnetmachine-logo": CoopnetMachineLogoComponent,
	},
	data() {
		return {
			coopnetMachineLogos1: {
				0: {
					title: "Bills Payment",
					fileName: "/coopnetmachine/billspayment.png",
				},
				1: {
					title: "Fund Transfer",
					fileName: "/coopnetmachine/fundtransfer.png",
				},
				2: {
					title: "Bank Transfer",
					fileName: "/coopnetmachine/banktransfer.png",
				},
				3: {
					title: "Remittance",
					fileName: "/coopnetmachine/remittance.png",
				},
			},
			coopnetMachineLogos2: {
				0: {
					title: "eLoad",
					fileName: "/coopnetmachine/eload.png",
				},
				1: {
					title: "Loan Application",
					fileName: "/coopnetmachine/loanapplication.png",
				},
				2: {
					title: "Cash In",
					fileName: "/coopnetmachine/cashin.png",
				},
				3: {
					title: "More",
					fileName: "/coopnetmachine/more.png",
				},
			},
		};
	},
	directives: {
		infocus: infocusDirective,
	},
};
</script>

<style scoped>
.coopnet-teller-machine {
	background-image: url(@/assets/coopnetmachine/coopnet-bg.c9a3a6f8.png);
	height: 100%;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
}
.coopnet-teller-machine .button {
	background-color: transparent;
	border-color: #fff;
	color: #fff;
	font-size: 1.125rem;
	padding-left: 2.25em;
	padding-right: 2.25em;
}
/* Resize GIF in order for animation to play */
.ctm-gif{
 height: 90vh;
 width: auto;
}
div.ctm-desktop {
    max-height: 100vh;
}
/* Hidden before animation plays */
.hidden {
	opacity: 0;
}

/* Animate.CSS */
/* Desktop Animations */
.coopmachine1desktop {
	opacity: 1;
	animation: fadeInLeft;
	animation-duration: 0.3s;
}

/* Mobile Animations */
.coopmachineleft1mobile {
	opacity: 1;
	animation: fadeInLeft;
	animation-duration: 0.3s;
}
.coopmachineright2mobile {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 0.6s;
}
.coopmachineleft3mobile {
	opacity: 1;
	animation: fadeInLeft;
	animation-duration: 0.9s;
}
.coopmachineright4mobile {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 1.2s;
}
.coopmachineup1mobile {
	opacity: 1;
	animation: fadeInUp;
	animation-duration: 1.5s;
}
</style>