<template>
	<div id="ourdxbanksolutions">
		<!-- DESKTOP -->
		<div class="ourdxbanksolutions py-6 is-hidden-mobile">
			<p
				class="is-size-1 has-text-weight-bold mt-1 mb-3"
				:style="{ color: 'white' }"
			>
				OUR
				<span :style="{ color: 'rgb(51, 157, 255)' }"
					>DXBANK SOLUTIONS</span
				>
			</p>
			<!-- Our DXBank Solutions Logos -->
			<div class="columns is-centered mt-6">
				<div
					class="column is-2 mx-5"
					v-for="(logo, index) in ourDXBankSolutionsLogos1"
					:key="index"
				>
					<app-dxbanksolutions-logo
						:title="logo.title"
						:file-name="logo.fileName"
						:style="{ display: 'block' }"
						v-infocus="'ourdxbanksolutionlogos1'"
						class="hidden"
					/>
				</div>
			</div>
			<div class="columns is-centered mt-6">
				<div
					class="column is-2 mx-5"
					v-for="(logo, index) in ourDXBankSolutionsLogos2"
					:key="index"
				>
					<app-dxbanksolutions-logo
						:title="logo.title"
						:file-name="logo.fileName"
						:style="{ display: 'block' }"
						v-infocus="'ourdxbanksolutionlogos2'"
						class="hidden"
					/>
				</div>
			</div>
		</div>
		<!-- MOBILE -->
		<div class="ourdxbanksolutionsmobile py-6 is-hidden-tablet">
			<p
				class="is-size-3-mobile has-text-weight-bold mt-1 mb-3"
				:style="{ color: 'white' }"
			>
				OUR
				<span :style="{ color: 'rgb(51, 157, 255)' }"
					>DXBANK SOLUTIONS</span
				>
			</p>
			<!-- Our DXBank Solutions Logo -->
			<div class="columns is-centered is-mobile mt-3">
				<div class="column is-5">
					<app-dxbanksolutions-logo
						:title="ourDXBankSolutionsLogos1[0].title"
						:file-name="ourDXBankSolutionsLogos1[0].fileName"
						:style="{ display: 'block' }"
						v-infocus="'ourdxbanksolutionlogosleft1'"
						class="hidden"
					/>
				</div>
				<div class="column is-5">
					<app-dxbanksolutions-logo
						:title="ourDXBankSolutionsLogos1[1].title"
						:file-name="ourDXBankSolutionsLogos1[1].fileName"
						:style="{ display: 'block' }"
						v-infocus="'ourdxbanksolutionlogosleft1'"
						class="hidden"
					/>
				</div>
			</div>
			<div class="columns is-centered is-mobile mt-3">
				<div class="column is-5">
					<app-dxbanksolutions-logo
						:title="ourDXBankSolutionsLogos1[2].title"
						:file-name="ourDXBankSolutionsLogos1[2].fileName"
						:style="{ display: 'block' }"
						v-infocus="'ourdxbanksolutionlogosright2'"
						class="hidden"
					/>
				</div>
				<div class="column is-5">
					<app-dxbanksolutions-logo
						:title="ourDXBankSolutionsLogos1[3].title"
						:file-name="ourDXBankSolutionsLogos1[3].fileName"
						:style="{ display: 'block' }"
						v-infocus="'ourdxbanksolutionlogosright2'"
						class="hidden"
					/>
				</div>
			</div>
			<div class="columns is-centered is-mobile mt-3">
				<div class="column is-5">
					<app-dxbanksolutions-logo
						:title="ourDXBankSolutionsLogos2[0].title"
						:file-name="ourDXBankSolutionsLogos2[0].fileName"
						:style="{ display: 'block' }"
						v-infocus="'ourdxbanksolutionlogosleft3'"
						class="hidden"
					/>
				</div>
				<div class="column is-5">
					<app-dxbanksolutions-logo
						:title="ourDXBankSolutionsLogos2[1].title"
						:file-name="ourDXBankSolutionsLogos2[1].fileName"
						:style="{ display: 'block' }"
						v-infocus="'ourdxbanksolutionlogosleft3'"
						class="hidden"
					/>
				</div>
			</div>
			<div class="columns is-centered is-mobile mt-3">
				<div class="column is-5">
					<app-dxbanksolutions-logo
						:title="ourDXBankSolutionsLogos2[2].title"
						:file-name="ourDXBankSolutionsLogos2[2].fileName"
						:style="{ display: 'block' }"
						v-infocus="'ourdxbanksolutionlogosright4'"
						class="hidden"
					/>
				</div>
				<div class="column is-5">
					<app-dxbanksolutions-logo
						:title="ourDXBankSolutionsLogos2[3].title"
						:file-name="ourDXBankSolutionsLogos2[3].fileName"
						:style="{ display: 'block' }"
						v-infocus="'ourdxbanksolutionlogosright4'"
						class="hidden"
					/>
				</div>
			</div>
			<div class="columns is-centered is-mobile mt-3">
				<div class="column is-5">
					<app-dxbanksolutions-logo
						:title="ourDXBankSolutionsLogos2[4].title"
						:file-name="ourDXBankSolutionsLogos2[4].fileName"
						:style="{ display: 'block' }"
						v-infocus="'ourdxbanksolutionlogosup1'"
						class="hidden"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import OurDXBankSolutionsLogoComponent from "@/components/OurDXBankSolutions/OurDXBankSolutionsLogoComponent.vue";
import infocusDirective from "@/components/directives/infocusDirective.vue";

export default {
	name: "OurDXBankSolutionsComponent",
	components: {
		"app-dxbanksolutions-logo": OurDXBankSolutionsLogoComponent,
	},
	data() {
		return {
			ourDXBankSolutionsLogos1: {
				0: {
					title: "E-Wallet",
					fileName: "/ourdxbanksolutions/ewallet.png",
				},
				1: {
					title: "eKYC",
					fileName: "/ourdxbanksolutions/eKYC.png",
				},
				2: {
					title: "Digital Account Opening",
					fileName: "/ourdxbanksolutions/Digital Account Opening.png",
				},
				3: {
					title: "Payment System",
					fileName: "/ourdxbanksolutions/Payment System.png",
				},
			},
			ourDXBankSolutionsLogos2: {
				0: {
					title: "Disbursement System",
					fileName: "/ourdxbanksolutions/Disbursement System.png",
				},
				1: {
					title: "Mobile Banking App",
					fileName: "/ourdxbanksolutions/Mobile Banking App.png",
				},
				2: {
					title: "Prepaid Debit Visa Card",
					fileName: "/ourdxbanksolutions/Prepaid Debit Visa Card.png",
				},
				3: {
					title: "Coopnet Teller Machine (CTM)",
					fileName:
						"/ourdxbanksolutions/Coopnet Teller Machine (CTM) - Cash in Cash out.png",
				},
				4: {
					title: "Core Banking System on SAAS Model",
					fileName:
						"/ourdxbanksolutions/Core Banking System on SAAS model.png",
				},
			},
		};
	},
	directives: {
		infocus: infocusDirective,
	},
};
</script>

<style scoped>
.ourdxbanksolutions {
	background: linear-gradient(270deg, #004e97 0%, #023074 69.27%);
	padding-left: 11rem;
	padding-right: 11rem;
}
.ourdxbanksolutionsmobile {
	background: linear-gradient(270deg, #004e97 0%, #023074 69.27%);
}

/* Hidden before animation plays */
.hidden {
	opacity: 0;
}
/* Animate.CSS */
/* Desktop Animations */
.ourdxbanksolutionlogos1 {
	opacity: 1;
	animation: fadeInLeft;
	animation-duration: 1s;
}
.ourdxbanksolutionlogos2 {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 1s;
}
/* Mobile Animations */
.ourdxbanksolutionlogosup1 {
	opacity: 1;
	animation: fadeInUp;
	animation-duration: 1s;
}
.ourdxbanksolutionlogosleft1 {
	opacity: 1;
	animation: fadeInLeft;
	animation-duration: 0.2s;
}
.ourdxbanksolutionlogosright2 {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 0.4s;
}
.ourdxbanksolutionlogosleft3 {
	opacity: 1;
	animation: fadeInLeft;
	animation-duration: 0.6s;
}
.ourdxbanksolutionlogosright4 {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 0.8s;
}
</style>