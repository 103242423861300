<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
export default {};
</script>


<style>
#app {
	font-family: "Montserrat", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

body {
	background-color: #fff !important;
}

nav a {
	font-weight: bold;
	color: #2c3e50;
}

nav a.router-link-exact-active {
	color: #42b983;
}
html,
body {
	scroll-behavior: smooth;
	max-width: 100%;
	overflow-x: hidden;
}
</style>
