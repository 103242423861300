<template>
	<div id="whoweserve">
        <!-- DESKTOP -->
		<div class="whoweserve p-6 is-hidden-mobile" >
			<div class="container">
				<p
					class="
						is-size-1
						has-text-weight-bold
					"
					:style="{ color: 'rgb(51, 51, 51)' }"
				>
					WHO
					<span :style="{ color: 'rgb(51, 157, 255)' }">
						WE SERVE
					</span>
				</p>
				<div
					class="columns whoweservelogos1 mt-6"
				>
					<div
						class="column is-3"
						v-for="(logo, index) in whoweservelogos1"
						:key="index"
					>
						<app-whoweserve-logo
							:title="logo.title"
							:file-name="logo.fileName"
                            v-infocus="'whoweserve2'"
                            class="hidden"
						/>
					</div>
				</div>
				<div
					class="
						columns
						whoweservelogos2
						mt-6
						is-flex is-centered
					"
					
				>
					<div
						class="column is-3"
						v-for="(logo, index) in whoweservelogos2"
						:key="index"
					>
						<app-whoweserve-logo
							:title="logo.title"
							:file-name="logo.fileName"
                            v-infocus="'whoweserve3'"
                            class="hidden"
						/>
					</div>
				</div>
			</div>
		</div>
        <!-- MOBILE -->
		<div class="whoweservemobile py-6 is-hidden-tablet">
			<div class="container">
				<p
					class="
						has-text-weight-semibold
						is-size-3-mobile
					"
					:style="{ color: 'rgb(51, 51, 51)' }"
				>
					WHO
					<span :style="{ color: 'rgb(51, 157, 255)' }">
						WE SERVE
					</span>
				</p>
				<div class="columns is-mobile is-centered mt-5">
					<div class="column is-5">
						<app-whoweserve-logo
							:title="whoweservelogos1[0].title"
							:file-name="whoweservelogos1[0].fileName"
							class="whoweservelogomobile hidden"
							v-infocus="'whoweserveright1mobile'"
						/>
					</div>
					<div class="column is-5">
						<app-whoweserve-logo
							:title="whoweservelogos1[1].title"
							:file-name="whoweservelogos1[1].fileName"
							class="whoweservelogomobile hidden"
							v-infocus="'whoweserveright1mobile'"
						/>
					</div>
				</div>
				<div class="columns is-mobile is-centered mt-5">
					<div class="column is-5">
						<app-whoweserve-logo
							:title="whoweservelogos1[2].title"
							:file-name="whoweservelogos1[2].fileName"
							class="whoweservelogomobile hidden"
							v-infocus="'whoweserveleft2mobile'"
						/>
					</div>
					<div class="column is-5">
						<app-whoweserve-logo
							:title="whoweservelogos1[3].title"
							:file-name="whoweservelogos1[3].fileName"
							class="whoweservelogomobile hidden"
							v-infocus="'whoweserveleft2mobile'"
						/>
					</div>
				</div>
				<div class="columns is-mobile is-centered mt-5">
					<div class="column is-5">
						<app-whoweserve-logo
							:title="whoweservelogos2[0].title"
							:file-name="whoweservelogos2[0].fileName"
							class="whoweservelogomobile hidden"
							v-infocus="'whoweserveright3mobile'"
						/>
					</div>
					<div class="column is-5">
						<app-whoweserve-logo
							:title="whoweservelogos2[1].title"
							:file-name="whoweservelogos2[1].fileName"
							class="whoweservelogomobile hidden"
							v-infocus="'whoweserveright3mobile'"
						/>
					</div>
				</div>
				<div class="columns is-mobile is-centered mt-5">
					<div class="column is-5">
						<app-whoweserve-logo
							:title="whoweservelogos2[2].title"
							:file-name="whoweservelogos2[2].fileName"
							class="whoweservelogomobile hidden"
							v-infocus="'whoweserveupmobile'"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import WhoWeServeLogoComponent from "@/components/WhoWeServe/WhoWeServeLogoComponent.vue";
import infocusDirective from "@/components/directives/infocusDirective.vue";

export default {
	name: "WhoWeServeComponent",
	components: {
		"app-whoweserve-logo": WhoWeServeLogoComponent,
	},
	data() {
		return {
			whoweservelogos1: {
				0: {
					title: "Coop Banks",
					fileName: "whoweserve/coopbanks.1ae62676.png",
				},
				1: {
					title: "Cooperatives",
					fileName: "whoweserve/cooperatives.f10bc990.png",
				},
				2: {
					title: "Thrift Banks",
					fileName: "whoweserve/thriftbanks.cad7ce52.png",
				},
				3: {
					title: "Savings Banks",
					fileName: "whoweserve/savingsbanks.230dfa81.png",
				},
			},
			whoweservelogos2: {
				0: {
					title: "Rural Banks",
					fileName: "whoweserve/ruralbanks.f17e65df.png",
				},
				1: {
					title: "Microfinance",
					fileName: "whoweserve/microfinance.4274c5fb.png",
				},
				2: {
					title: "Lending Companies",
					fileName: "whoweserve/lendingcompanies.1c9a7b0d.png",
				},
			},
		};
	},
	directives: {
		infocus: infocusDirective,
	},
};
</script>

<style scoped>
/* Hidden before animation plays */
.hidden {
	opacity: 0;
}
/* Animate.CSS */
/* Desktop Animations */
.whoweserve2 {
	opacity: 1;
	animation: fadeInDown;
	animation-duration: 1s;
}
.whoweserve3 {
	opacity: 1;
	animation: fadeInUp;
	animation-duration: 1s;
}

/* Mobile Animations */
.whoweserveright1mobile {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 0.3s;
}
.whoweserveright3mobile {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 0.6s;
}
.whoweserveleft2mobile {
	opacity: 1;
	animation: fadeInLeft;
	animation-duration: 0.9s;
}
.whoweserveupmobile {
	opacity: 1;
	animation: fadeInUp;
	animation-duration: 1.2s;
}
</style>