<template>
	<div class="terms-of-use">
		<app-terms-of-use />
	</div>
</template>

<script>

import TermsOfUseComponent from "@/components/TermsOfUse/TermsOfUseComponent.vue";

export default {
	name: "TermsOfUseView",
	components: {
		"app-terms-of-use": TermsOfUseComponent,
	},
};
</script>