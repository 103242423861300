import { render, staticRenderFns } from "./MobileBankingAppComponent.vue?vue&type=template&id=9884ae50&scoped=true&"
import script from "./MobileBankingAppComponent.vue?vue&type=script&lang=js&"
export * from "./MobileBankingAppComponent.vue?vue&type=script&lang=js&"
import style0 from "./MobileBankingAppComponent.vue?vue&type=style&index=0&id=9884ae50&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9884ae50",
  null
  
)

export default component.exports