<template>
	<div id="privacy-policy">
        <div class="privacy-policy main-bg is-hidden-mobile">
            <div class="columns is-flex is-vcentered rounded-div">
                <div class="column is-centered">
                    <h1 class="coopnet-text-title is-size-2"> Privacy Policy </h1>

                    <hr>

                    <p class="coopnet-text-primary is-size-5 ">1. Introductory Letter</p>

                    <p class="coopnet-text-subtitle is-size-6">Definition of parties</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Thank you for using TraxionTech! <br> Capitalized terms used but not defined shall have the respective meanings given to them in the Terms of Use. We created this Privacy Policy (“Policy”) to give you confidence as you visit and use our Service. This Policy covers how we collect, use, store, and share your information when you use our Service. All references to “we”, “us”, “our”, or “TraxionTech” refer to Traxion Tech, Inc.., a corporation duly organized and existing under Philippine law. All references to “you”, “your”, or “user” relate to the person or entity who registered an Account on TraxionTech to use or access the Services.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Electronic Acceptance</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Please read this Policy carefully. By clicking the applicable button or checkbox to indicate your acceptance to this Policy and our Terms, or otherwise by creating an Account and using the Service, you represent and agree that you have read, understand, and agree to be bound by both this Policy and our Terms as binding agreements.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Updates on Policy</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        We may update this Policy from time to time. If you continue using the Service after we make any changes to this Policy, you accept and agree to be bound by those changes. The most updated version of this Policy will always be posted on the Service, so we encourage you to check the Policy periodically. In certain situations, we may notify you about material changes to the Policy. You are responsible for ensuring we have an up-to-date, active, and deliverable email address on file for you, and you agree that your use of the Service will be subject to any updated Policy posted on the Service, whether or not you receive notice of such changes.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Applicability</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        This Policy applies to personal information we collect on the Service. It does not apply to any non-personal information or any information collected by any third party. As used in this Policy, “personal information” means information that can be used to identify any natural person either directly or indirectly. Moreover, if you are a Customer, we may collect Customer Data either directly from you or from the user using our Payment Processing Services.
                    </p>
                    

                    <p class="coopnet-text-primary is-size-5 ">2. Voluntarily Provision of Personal Information</p>
                    
                    <p class="coopnet-text-subtitle is-size-6">Information Collected</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        When you register for an Account, we require that you provide us with certain personal information about you. This includes, but is not limited to, your first and last name, e-mail address, and the name of your business. As part of the account registration and verification process, we may also ask you for certain other information, such as your phone number and your business’s tax identification number, as well as documents that include personal information about you. These documents include, but are not limited to, government-issued identification including identification containing photo and signature, bank statements, proof of income, documentation verifying the legal existence of your business and its beneficial owners and principals, bank statements and related banking information, and other financial documents to verify your identity and assess the risk associated with your business. Even if you access and use the Service without registering for an Account, you may choose to provide us with certain personal information about you (for example, you may want to sign up for our newsletters or RSVP for events). The collection of this information is necessary to provide the functionality of the Service and/or to comply with applicable laws and regulations related to the Service’s product offerings.
                    </p>
                    <p class="coopnet-text-subtitle is-size-6">Information Required</p>
                    <p class="coopnet-text-paragraph is-size-6">
                         If you are a Customer, when you make a payment through a user’s website or application in relation to a Transaction, we will receive certain Customer Data. Customer Data may include:
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>First and last name;</li>
                        <li>Physical and/or mailing address;</li>
                        <li>Financial information, including but not limited to credit or debit card numbers or bank account information;</li>
                        <li>Email address; and</li>
                        <li>Phone number.</li>
                    </ol>

                    <p class="coopnet-text-primary is-size-5 ">3. Automatically collected Personal Information</p>
                    <p class="coopnet-text-subtitle is-size-6">Information Collected and Required</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        We collect certain information about you automatically as you access and use the Service and may similarly collect information from Customers. This includes:
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>Device information, such as a unique device identifier; and</li>
                        <li>Location information, such as your IP address or geo-location.</li>
                    </ol>
                    <p class="coopnet-text-paragraph is-size-6">
                        This information does not identify you directly but may nevertheless be considered personal information.
                    </p>
                    
                    <p class="coopnet-text-primary is-size-5 ">4. Use of Information</p>
                    <p class="coopnet-text-subtitle is-size-6">General Use</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        We use the personal information that we collect to make the Service available to our users, to comply with our legal obligations under anti-money laundering and related laws, to market and promote the Service, to improve the Service, and to protect our legal rights.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Specific Examples of where information will be used</p>
                    <p class="coopnet-text-paragraph is-size-6">Examples of the ways in which we may use your personal information include:</p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>Allowing you to register for an Account and use the Payment Processing Services;</li>
                        <li>Verifying your identity in compliance with our obligations under applicable law;</li>
                        <li>Identifying fraudulent or illegal activity;</li>
                        <li>Providing you with information, products or services that you request from us;</li>
                        <li>Carrying out our obligations and enforcing our rights arising from any contracts entered into between you and us, including for billing and collection;</li>
                        <li>Notifying you about changes to the Service, such as updates to this Policy; and,</li>
                        <li>Understanding how our users interact with the Service to improve the Service and our product offerings.</li>
                    </ol>

                    <p class="coopnet-text-subtitle is-size-6">Use for Marketing and Promotions</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        In addition to the above, if you choose to opt in to receive marketing and promotional communications from us, we may use your personal information to contact you about our products. If you no longer want to receive marketing-related emails from us, you may opt-out via the unsubscribe link included in such emails, or by whatever other means we allow you to unsubscribe. We will try to comply with your request(s) as soon as reasonably practicable. Please note that if you opt-out of receiving marketing-related emails from us, we may still send you important administrative messages that are required to provide you with our Services. <br> We may also use your personal information for any other purposes with your consent.
                    </p>

                    <p class="coopnet-text-primary is-size-5 ">5. Sharing of Personal Information</p>
                    <p class="coopnet-text-subtitle is-size-6">Basic Use of Information</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        We may disclose or share personal information that we collect in the following circumstances:
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>With our subsidiaries and affiliates (please refer to the List of Third Parties for the list thereof);</li>
                        <li>With our contractors and vendors who support aspects of the Service and our business, including: services related to website hosting, data analysis, information technology and related infrastructure, bank account identity and authentication, helpdesk and support, user identity verification, and fraud prevention (please refer to List of Third Parties for the list of said contractors and vendors);</li>
                        <li>With our banking partners, including Payment Method Providers and Acquirers, who enable the processing of Transactions via the Payment Processing Services (please refer to the List of Third Parties for the list thereof);</li>
                        <li>With a buyer or other successor in the event of a merger, reorganization, dissolution or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about our users is among the assets transferred (in such event, we will provide notice to our users of such transfer);</li>
                        <li>When we believe, in good faith, that disclosure is necessary to prevent physical harm or financial loss, to report suspected illegal activity, or to investigate violations of our Terms of Service or any other applicable policies; and</li>
                        <li>With your express consent.</li>
                    </ol>

                    <p class="coopnet-text-subtitle is-size-6">Confidentiality of Information</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Any third-party entity to whom we disclose your personal information is contractually required to comply with confidentiality standards and establish adequate safeguards for data privacy, undertake to respect your right to privacy and comply with applicable laws. We also require that these third-party entities use information shared with them only for authorized purposes and follow our reasonable directions with respect to this information.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Use of information as requred by State</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Additionally, we may share your personal information as required to comply with any subpoena, court order, similar legal process, including to respond to any government or regulatory request. To the extent permitted by law, we will notify you if we receive government requests about your data.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Sharing with Anonimity</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Finally, we may disclose anonymized, non-personal information about the Service and our users without restriction.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Request for Non disclosure</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        If you do not wish any of your personal information to be shared to any third party or for any of the indicated purposes, please contact us immediately.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Accessing and Correcting Your Information</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        You are solely responsible for ensuring that any personal information that you provide to us is accurate. You may be able to view and update certain personal information that we have about you by logging into your Account or by emailing address provided at the end of this Policy. Please note that we reserve the right to reject any changes you make to your personal information and to reject any requests to change your personal information submitted through inappropriate channels.
                    </p>

                    <p class="coopnet-text-primary is-size-5 ">6. Data Management</p>
                    <p class="coopnet-text-subtitle is-size-6">Data Security</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        We use commercially reasonable physical, technical, and administrative measures to secure your personal information from accidental loss and from unauthorized access, use, and disclosure. For example, we
                    </p>
                    <ol class="coopnet-text-list is-size-6" type="a">
                        <li>have implemented a strict data security policy,</li>
                        <li>restrict access to personal information to employees, contractors, and other service providers on a need-to-know basis,</li>
                        <li>use industry-standard encryption technology to secure data,</li>
                        <li>train our personnel on privacy issues and have appointed a data privacy officer,</li>
                        <li>review the privacy practices of new products and services that we integrate into our Service, and</li>
                        <li>require our personnel to sign confidentiality agreements that extend to your personal information.</li>
                    </ol>
                    <p class="coopnet-text-paragraph is-size-6">
                        However, the transmission of information via the Internet is not completely secure. As we cannot guarantee the security of information transmitted to or from us, we are not responsible for any unauthorized access to and disclosure of any information you send to or receive from us. Any transmission of personal information is at your own risk.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Data Security</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Please also keep in mind that the safety and security of your information also depends on you. You are responsible for keeping your account information, including your password, confidential. We ask you not to share your password with anyone. If you have reason to believe that your data is no longer secure, please contact us immediately at the email address, mailing address or telephone number listed at the end of this Policy.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Data Retention</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        We may retain your personal information for the longer of: (i) six (6) months after receipt of your request to delete your Account; (ii) the length of time required by law; (iii) the length of time required by our compliance program; or (iv) the length of time required by our banking partners, including Payment Method Providers and Acquirers. Please note that if you delete your Account, we may still retain your personal information as explained above. You may delete your Account through the Dashboard, or you may email the address provided at the end of this Policy.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Automated Data Collection Technologies</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        As you navigate through and interact with the Service, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions and patterns, as further described in this Policy.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Cookies and Analytics</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        We may use cookies and other technologies to automatically collect information about your use of the Service. You can learn more about these technologies below. We may use this information to provide you with a better user experience, to comply with our legal obligations under anti-money laundering and related laws, to protect you and detect irregular or suspicious account activities, to customize our services and content for you, and to better understand how our users interact with the Service.
                    </p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Cookies. A cookie is a small file placed on your computer when you visit certain websites. Cookies may be either first-party or third-party cookies, and they may be either permanent or temporary (i.e. session) cookies. It may be possible to refuse to accept cookies by activating the appropriate setting within your browser. However, if you disable or refuse cookies, please note that some parts of the Service may be inaccessible or may not function properly.
                    </p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Other Technologies. We may use other third-party services that automatically collect information about you to better understand how you use and interact with the Service. For example, we may use third-party vendors to provide us with services surrounding analytics, advertising, and cybersecurity. The information collected through this process by the third-party service providers does not enable us or them to identify your name, contact details or other personal information that directly identifies you unless you choose to provide these.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">“Do Not Track” Signals</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        To the extent that we receive any Do-Not-Track signals, we will not comply with them.
                    </p>
                    
                    <p class="coopnet-text-primary is-size-5 ">7. Other</p>
                    <p class="coopnet-text-subtitle is-size-6">Third-Party Services</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        Any third-party services integrated with the Service shall be subject to the policies and practices of such third parties, and we are not responsible for how they collect, use, and share your personal information. We encourage you to review the privacy practices and policies of such third parties. We make no guarantees about, and assume no responsibility for the information, services, or data privacy practices of third parties.
                    </p>

                    <p class="coopnet-text-subtitle is-size-6">Contact Us</p>
                    <p class="coopnet-text-paragraph is-size-6">
                        To ask questions or comment about this Policy and our privacy practices, <a href="mailto:support@traxiontech.net">contact us</a>.
                    </p>
                </div>
            </div>
        </div>
        <div class="privacy-policy-mobile main-bg-mobile is-hidden-tablet">
            <div class="pt-6">
                <div class="columns is-flex is-vcentered rounded-div-mobile">
                    <div class="column is-centered">
                        <h1 class="coopnet-text-mobile-title is-size-4-mobile"> Privacy Policy </h1>

                        <hr>

                        <p class="coopnet-text-mobile-primary is-size-5-mobile ">1. Introductory Letter</p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Definition of parties</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Thank you for using TraxionTech! <br> Capitalized terms used but not defined shall have the respective meanings given to them in the Terms of Use. We created this Privacy Policy (“Policy”) to give you confidence as you visit and use our Service. This Policy covers how we collect, use, store, and share your information when you use our Service. All references to “we”, “us”, “our”, or “TraxionTech” refer to Traxion Tech, Inc.., a corporation duly organized and existing under Philippine law. All references to “you”, “your”, or “user” relate to the person or entity who registered an Account on TraxionTech to use or access the Services.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Electronic Acceptance</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Please read this Policy carefully. By clicking the applicable button or checkbox to indicate your acceptance to this Policy and our Terms, or otherwise by creating an Account and using the Service, you represent and agree that you have read, understand, and agree to be bound by both this Policy and our Terms as binding agreements.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Updates on Policy</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            We may update this Policy from time to time. If you continue using the Service after we make any changes to this Policy, you accept and agree to be bound by those changes. The most updated version of this Policy will always be posted on the Service, so we encourage you to check the Policy periodically. In certain situations, we may notify you about material changes to the Policy. You are responsible for ensuring we have an up-to-date, active, and deliverable email address on file for you, and you agree that your use of the Service will be subject to any updated Policy posted on the Service, whether or not you receive notice of such changes.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Applicability</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            This Policy applies to personal information we collect on the Service. It does not apply to any non-personal information or any information collected by any third party. As used in this Policy, “personal information” means information that can be used to identify any natural person either directly or indirectly. Moreover, if you are a Customer, we may collect Customer Data either directly from you or from the user using our Payment Processing Services.
                        </p>
                        

                        <p class="coopnet-text-mobile-primary is-size-5-mobile ">2. Voluntarily Provision of Personal Information</p>
                        
                        <p class="coopnet-text-mobile-subtitle is-size-6">Information Collected</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            When you register for an Account, we require that you provide us with certain personal information about you. This includes, but is not limited to, your first and last name, e-mail address, and the name of your business. As part of the account registration and verification process, we may also ask you for certain other information, such as your phone number and your business’s tax identification number, as well as documents that include personal information about you. These documents include, but are not limited to, government-issued identification including identification containing photo and signature, bank statements, proof of income, documentation verifying the legal existence of your business and its beneficial owners and principals, bank statements and related banking information, and other financial documents to verify your identity and assess the risk associated with your business. Even if you access and use the Service without registering for an Account, you may choose to provide us with certain personal information about you (for example, you may want to sign up for our newsletters or RSVP for events). The collection of this information is necessary to provide the functionality of the Service and/or to comply with applicable laws and regulations related to the Service’s product offerings.
                        </p>
                        <p class="coopnet-text-mobile-subtitle is-size-6">Information Required</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            If you are a Customer, when you make a payment through a user’s website or application in relation to a Transaction, we will receive certain Customer Data. Customer Data may include:
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>First and last name;</li>
                            <li>Physical and/or mailing address;</li>
                            <li>Financial information, including but not limited to credit or debit card numbers or bank account information;</li>
                            <li>Email address; and</li>
                            <li>Phone number.</li>
                        </ol>

                        <p class="coopnet-text-mobile-primary is-size-5-mobile ">3. Automatically collected Personal Information</p>
                        <p class="coopnet-text-mobile-subtitle is-size-6">Information Collected and Required</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            We collect certain information about you automatically as you access and use the Service and may similarly collect information from Customers. This includes:
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>Device information, such as a unique device identifier; and</li>
                            <li>Location information, such as your IP address or geo-location.</li>
                        </ol>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            This information does not identify you directly but may nevertheless be considered personal information.
                        </p>
                        
                        <p class="coopnet-text-mobile-primary is-size-5-mobile ">4. Use of Information</p>
                        <p class="coopnet-text-mobile-subtitle is-size-6">General Use</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            We use the personal information that we collect to make the Service available to our users, to comply with our legal obligations under anti-money laundering and related laws, to market and promote the Service, to improve the Service, and to protect our legal rights.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Specific Examples of where information will be used</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">Examples of the ways in which we may use your personal information include:</p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>Allowing you to register for an Account and use the Payment Processing Services;</li>
                            <li>Verifying your identity in compliance with our obligations under applicable law;</li>
                            <li>Identifying fraudulent or illegal activity;</li>
                            <li>Providing you with information, products or services that you request from us;</li>
                            <li>Carrying out our obligations and enforcing our rights arising from any contracts entered into between you and us, including for billing and collection;</li>
                            <li>Notifying you about changes to the Service, such as updates to this Policy; and,</li>
                            <li>Understanding how our users interact with the Service to improve the Service and our product offerings.</li>
                        </ol>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Use for Marketing and Promotions</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            In addition to the above, if you choose to opt in to receive marketing and promotional communications from us, we may use your personal information to contact you about our products. If you no longer want to receive marketing-related emails from us, you may opt-out via the unsubscribe link included in such emails, or by whatever other means we allow you to unsubscribe. We will try to comply with your request(s) as soon as reasonably practicable. Please note that if you opt-out of receiving marketing-related emails from us, we may still send you important administrative messages that are required to provide you with our Services. <br> We may also use your personal information for any other purposes with your consent.
                        </p>

                        <p class="coopnet-text-mobile-primary is-size-5-mobile ">5. Sharing of Personal Information</p>
                        <p class="coopnet-text-mobile-subtitle is-size-6">Basic Use of Information</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            We may disclose or share personal information that we collect in the following circumstances:
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>With our subsidiaries and affiliates (please refer to the List of Third Parties for the list thereof);</li>
                            <li>With our contractors and vendors who support aspects of the Service and our business, including: services related to website hosting, data analysis, information technology and related infrastructure, bank account identity and authentication, helpdesk and support, user identity verification, and fraud prevention (please refer to List of Third Parties for the list of said contractors and vendors);</li>
                            <li>With our banking partners, including Payment Method Providers and Acquirers, who enable the processing of Transactions via the Payment Processing Services (please refer to the List of Third Parties for the list thereof);</li>
                            <li>With a buyer or other successor in the event of a merger, reorganization, dissolution or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about our users is among the assets transferred (in such event, we will provide notice to our users of such transfer);</li>
                            <li>When we believe, in good faith, that disclosure is necessary to prevent physical harm or financial loss, to report suspected illegal activity, or to investigate violations of our Terms of Service or any other applicable policies; and</li>
                            <li>With your express consent.</li>
                        </ol>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Confidentiality of Information</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Any third-party entity to whom we disclose your personal information is contractually required to comply with confidentiality standards and establish adequate safeguards for data privacy, undertake to respect your right to privacy and comply with applicable laws. We also require that these third-party entities use information shared with them only for authorized purposes and follow our reasonable directions with respect to this information.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Use of information as requred by State</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Additionally, we may share your personal information as required to comply with any subpoena, court order, similar legal process, including to respond to any government or regulatory request. To the extent permitted by law, we will notify you if we receive government requests about your data.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Sharing with Anonimity</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Finally, we may disclose anonymized, non-personal information about the Service and our users without restriction.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Request for Non disclosure</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            If you do not wish any of your personal information to be shared to any third party or for any of the indicated purposes, please contact us immediately.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Accessing and Correcting Your Information</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            You are solely responsible for ensuring that any personal information that you provide to us is accurate. You may be able to view and update certain personal information that we have about you by logging into your Account or by emailing address provided at the end of this Policy. Please note that we reserve the right to reject any changes you make to your personal information and to reject any requests to change your personal information submitted through inappropriate channels.
                        </p>

                        <p class="coopnet-text-mobile-primary is-size-5-mobile ">6. Data Management</p>
                        <p class="coopnet-text-mobile-subtitle is-size-6">Data Security</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            We use commercially reasonable physical, technical, and administrative measures to secure your personal information from accidental loss and from unauthorized access, use, and disclosure. For example, we
                        </p>
                        <ol class="coopnet-text-mobile-list is-size-6" type="a">
                            <li>have implemented a strict data security policy,</li>
                            <li>restrict access to personal information to employees, contractors, and other service providers on a need-to-know basis,</li>
                            <li>use industry-standard encryption technology to secure data,</li>
                            <li>train our personnel on privacy issues and have appointed a data privacy officer,</li>
                            <li>review the privacy practices of new products and services that we integrate into our Service, and</li>
                            <li>require our personnel to sign confidentiality agreements that extend to your personal information.</li>
                        </ol>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            However, the transmission of information via the Internet is not completely secure. As we cannot guarantee the security of information transmitted to or from us, we are not responsible for any unauthorized access to and disclosure of any information you send to or receive from us. Any transmission of personal information is at your own risk.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Data Security</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Please also keep in mind that the safety and security of your information also depends on you. You are responsible for keeping your account information, including your password, confidential. We ask you not to share your password with anyone. If you have reason to believe that your data is no longer secure, please contact us immediately at the email address, mailing address or telephone number listed at the end of this Policy.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Data Retention</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            We may retain your personal information for the longer of: (i) six (6) months after receipt of your request to delete your Account; (ii) the length of time required by law; (iii) the length of time required by our compliance program; or (iv) the length of time required by our banking partners, including Payment Method Providers and Acquirers. Please note that if you delete your Account, we may still retain your personal information as explained above. You may delete your Account through the Dashboard, or you may email the address provided at the end of this Policy.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Automated Data Collection Technologies</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            As you navigate through and interact with the Service, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions and patterns, as further described in this Policy.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Cookies and Analytics</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            We may use cookies and other technologies to automatically collect information about your use of the Service. You can learn more about these technologies below. We may use this information to provide you with a better user experience, to comply with our legal obligations under anti-money laundering and related laws, to protect you and detect irregular or suspicious account activities, to customize our services and content for you, and to better understand how our users interact with the Service.
                        </p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Cookies. A cookie is a small file placed on your computer when you visit certain websites. Cookies may be either first-party or third-party cookies, and they may be either permanent or temporary (i.e. session) cookies. It may be possible to refuse to accept cookies by activating the appropriate setting within your browser. However, if you disable or refuse cookies, please note that some parts of the Service may be inaccessible or may not function properly.
                        </p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Other Technologies. We may use other third-party services that automatically collect information about you to better understand how you use and interact with the Service. For example, we may use third-party vendors to provide us with services surrounding analytics, advertising, and cybersecurity. The information collected through this process by the third-party service providers does not enable us or them to identify your name, contact details or other personal information that directly identifies you unless you choose to provide these.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">“Do Not Track” Signals</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            To the extent that we receive any Do-Not-Track signals, we will not comply with them.
                        </p>
                        
                        <p class="coopnet-text-mobile-primary is-size-5-mobile ">7. Other</p>
                        <p class="coopnet-text-mobile-subtitle is-size-6">Third-Party Services</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            Any third-party services integrated with the Service shall be subject to the policies and practices of such third parties, and we are not responsible for how they collect, use, and share your personal information. We encourage you to review the privacy practices and policies of such third parties. We make no guarantees about, and assume no responsibility for the information, services, or data privacy practices of third parties.
                        </p>

                        <p class="coopnet-text-mobile-subtitle is-size-6">Contact Us</p>
                        <p class="coopnet-text-mobile-paragraph is-size-6">
                            To ask questions or comment about this Policy and our privacy practices, <a href="mailto:support@traxiontech.net">contact us</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PrivacyPolicyComponent",
    };
</script>

<style scoped>
    .coopnet-text-title{
        color: black;
        text-align: center;
        font-weight: 600;
        margin: 10px 0px;
    }
    .coopnet-text-primary{
        color: #004e97;
        text-align: left;
        font-weight: bold;
        margin: 20px 0px;
    }
    .coopnet-text-subtitle{
        color: #1882e6;
        text-align: left;
        font-weight: bold;
        margin: 0px 0px;
    }
    .coopnet-text-paragraph{
        color: black;
        text-align: justify;
        font-weight: bold;
        margin: 0px 0px 20px 0px;
        line-height: 1.5;
    }
    .coopnet-text-list{
        color: black;
        text-align: left;
        font-weight: bold;
        margin: 0px 0px 20px 30px;
        line-height: 1.5;
    }

    .coopnet-text-mobile-title{
        color: black;
        text-align: center;
        font-weight: 600;
        margin: 10px 0px;
    }
    .coopnet-text-mobile-primary{
        color: #004e97;
        text-align: left;
        font-weight: bold;
        margin: 20px 0px;
    }
    .coopnet-text-mobile-subtitle{
        color: #1882e6;
        text-align: left;
        font-weight: bold;
        margin: 0px 0px;
    }
    .coopnet-text-mobile-paragraph{
        color: black;
        text-align: justify;
        font-weight: bold;
        margin: 0px 0px 20px 0px;
        line-height: 1.5;
    }
    .coopnet-text-mobile-list{
        color: black;
        text-align: left;
        font-weight: bold;
        margin: 0px 0px 20px 30px;
        line-height: 1.5;
    }

    .rounded-div {
        background-color: white;
        border-radius: 20px;
        min-width: 50vw;
        width: 90%;
        margin: auto;
        padding: 3rem;
    }

    .rounded-div-mobile {
        background-color: white;
        border-radius: 20px;
        min-width: 50vw;
        width: 90%;
        margin: auto;
        padding: 0.5rem;
        
    }
    /* Desktop Specific */
    .main-bg {
        background-image: linear-gradient(270deg, #004e97, #023074);
        background-repeat: repeat-x;
        background-size: cover;
        padding:10px;
        overflow-y: hidden;
    }
    
    /* Mobile */
    .main-bg-mobile {
        background-image: linear-gradient(270deg, #004e97, #023074);;
        height: 100%;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    /* Animate.CSS */
    /* Desktop Animations */
    .head3 {
        opacity: 1;
        animation: fadeInRight;
        animation-duration: 1s;
    }
    
    </style>