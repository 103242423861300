<template>
	<div id="aggregatedpartners">
		<!-- DESKTOP -->
		<div class="aggregatedpartners is-hidden-mobile">
			<div class="columns">
				<div class="column p-0 is-7 is-flex is-align-items-start">
					<img
						:src="
							require('@/assets/aggregatedpartners/partners.png')
						"
						alt="logo"
						class="hidden my-2"
						v-infocus="'aggregatedpartners1'"
					/>
				</div>
				<div class="column p-4 is-flex is-align-items-center">
					<div>
						<p
							class="is-size-1 has-text-weight-bold"
							:style="{ color: 'rgb(51, 157, 255)' }"
						>
							AGGREGATED
							<span :style="{ color: 'white' }"> PARTNERS </span>
						</p>
						<p
							class="is-size-5 is-italic has-text-weight-semibold"
							:style="{ color: 'white' }"
						>
							“For livelier network & ecosystem”
						</p>
					</div>
				</div>
			</div>
		</div>
		<!-- MOBILE -->
		<div class="aggregatedpartners is-hidden-tablet">
			<div class="columns">
				<div class="column is-flex is-align-items-center">
					<div>
						<p
							class="is-size-3-mobile has-text-weight-bold"
							:style="{ color: 'rgb(51, 157, 255)' }"
						>
							AGGREGATED
							<span :style="{ color: 'white' }"> PARTNERS </span>
						</p>
						<p
							class="
								is-size-6-mobile is-italic
								has-text-weight-semibold
							"
							:style="{ color: 'white' }"
						>
							“For livelier network & ecosystem”
						</p>
					</div>
				</div>
				<div class="column is-8">
					<img
						:src="
							require('@/assets/aggregatedpartners/partners.png')
						"
						alt="logo"
						class="hidden"
						v-infocus="'aggregatedpartners1mobile'"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import infocusDirective from "@/components/directives/infocusDirective.vue";

export default {
	name: "WhyCoopnetComponent",
	components: {},
	data() {
		return {
			whycoopnetlogos: {
				0: {
					title: "We take care of the cost; no huge float, manage your own wallet balance",
					fileName: "/whycoopnet/coin 1.png",
				},
				1: {
					title: "We take care of compliance, we are just extending the service to network members",
					fileName: "/whycoopnet/coin 2.png",
				},
				2: {
					title: "We take care of systems integration and updates of technology",
					fileName: "/whycoopnet/coin 3.png",
				},
			},
		};
	},
	directives: {
		infocus: infocusDirective,
	},
};
</script>

<style scoped>
.aggregatedpartners {
	background: #003566;
}
/* Hidden before animation plays */
.hidden {
	opacity: 0;
}
/* Animate.CSS */
/* Desktop Animations */
.aggregatedpartners1 {
	opacity: 1;
	animation: fadeInLeft;
	animation-duration: 0.3s;
}
.aggregatedpartners1mobile {
	opacity: 1;
	animation: fadeInLeft;
	animation-duration: 0.3s;
}
</style>