<template>
    <div class="has-text-centered">
        <img :src="require('@/assets' + fileName)" alt="logo">
        <p class="is-size-6" :style="{color: 'rgb(255, 255, 255)'}">
            {{ title }}
        </p>
    </div>
</template>
<script>
	export default {
		name: "CoopnetMachineLogoComponent",
		props: {
			title: String,
			fileName: String,
		},
	};
</script>