<!-- OLD UI -->
<!-- <template>
  <div id="dragonpay-success-page">
    <div class="success-container">
      <div class="has-text-centered">
        <img
          :src="require('@/assets/traxion.png')"
          alt="coopnet logo"
          width="250"
          class="is-align-self-center dragonpay-success-image-mobile"
        />
      </div>

      <div
        class="
          is-flex is-align-items-center is-justify-content-space-between
          mt-5
        "
      >
        <p class="description">Received from</p>
        <p class="value">{{ accountName }}</p>
      </div>
      <div
        class="
          is-flex is-align-items-center is-justify-content-space-between
          mt-1
        "
      >
        <p class="description">Email</p>
        <p class="value">{{ accountEmail }}</p>
      </div>
      <div
        class="
          is-flex is-align-items-center is-justify-content-space-between
          mt-1
        "
      >
        <p class="description">Purpose</p>
        <p class="value">{{ accountPurpose }}</p>
      </div>

      <div
        class="
          is-flex is-align-items-center is-justify-content-space-between
          mt-1
        "
      >
        <p class="description">Amount</p>
        <p class="value">{{ accountAmount.toLocaleString() | Currency }}</p>
      </div>
      
      <div
        v-if="feeProcessType"
        class="
          is-flex is-align-items-center is-justify-content-space-between
          mt-1
        "
      >
        <p class="description">Service Fee</p>
        <p class="value">{{ accountFee.toLocaleString() | Currency }}</p>
      </div>
            
      <div
        class="
          is-flex is-align-items-center is-justify-content-space-between
          mt-1
        "
      >
        <p class="description">Total Amount</p>
        <p class="value">{{ accountTotalAmount.toLocaleString() | Currency }}</p>
      </div>

      <hr />

      <p class="ref has-text-centered">Ref No. {{ accountReferenceNumber }}</p>
      <p class="date has-text-centered">{{ accountDate }}</p>

      <div class="is-flex is-flex is-align-items-center is-justify-content-center mt-4 is-hidden">
        <img
          :src="require('@/assets/coopnet.png')"
          alt="coopnet logo"
          width="150"
          class="dragonpay-success-image-mobile mr-5"
        />

        <img
          :src="require('@/assets/digicoop.png')"
          alt="coopnet logo"
          width="150"
          class="dragonpay-success-image-mobile"
        />
      </div>
    </div>
  </div>
</template> -->

<!-- NEW UI -->
<!-- NEW UI -->
<template>
  <div id="dragonpay-success-page">
    <div class="success-container">
      <div>
        <img
          :src="require('@/assets/success-logo.webp')"
          alt="success icon"
          width="250"
          class="success-icon"
        />
      </div>

      <div>
        <p class="payment">Payment Successful!</p>
      </div>

      <hr />

      <p class="ref has-text-centered">Ref No. {{ accountReferenceNumber }}</p>
      <p class="date has-text-centered">{{ accountDate }}</p>

      <div
        class="is-flex is-flex is-align-items-center is-justify-content-center is-hidden"
      >
      <img
          :src="require('@/assets/coopnet.png')"
          alt="coopnet logo"
          width="150"
          class="dragonpay-success-image-mobile mr-5"
        />

        <img
          :src="require('@/assets/digicoop.png')"
          alt="coopnet logo"
          width="150"
          class="dragonpay-success-image-mobile"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "DragonpaySuccessPageComponent",
  data() {
    return {
      name: "",
      email: "",
      purpose: "",
      amount: 0,
      fee: 0,
      totalAmount: 0,
      referenceNumber: "",
      date: "",
      feeProcessType: false,
    };
  },
  filters: {
    Currency: function (value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "Php",
      });

      return formatter.format(value);
    },
  },
  computed: {
    accountName() {
      return this.name;
    },
    accountEmail() {
      return this.email;
    },
    accountPurpose() {
      return this.purpose;
    },
    accountAmount() {
      return this.amount;
    },
    accountFee() {
      return this.fee;
    },
    accountTotalAmount() {
      return this.totalAmount;
    },
    accountReferenceNumber() {
      return this.referenceNumber;
    },
    accountDate() {
      return moment(this.date).format("MMMM Do YYYY, h:mm a");
    },
  },
  methods: {
    async getCashInDetails() {
      if (this.$route.query.txnid !== undefined) {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}transactions/funds/cash-in/callback?txnid=${this.$route.query.txnid}`,
          {
            txnid: this.$route.query.txnid,
          }
        );
        const firstName =
          response.data.data.transactionDetails?.firstName ||
          response.data.data.transactionDetails?.Firstname;
        const lastName =
          response.data.data.transactionDetails?.lastName ||
          response.data.data.transactionDetails?.Lastname;

        this.name = firstName + " " + lastName;
        this.email = response.data.data.transactionDetails.email;
        this.purpose =
          response.data.data.transactionDetails.description === ""
            ? "Cash-In"
            : response.data.data.transactionDetails.description;
        this.amount = response.data.data.transactionDetails.amount;
        this.fee = response.data.data.transactionDetails.fee;
        this.totalAmount = response.data.data.thirdPartyResponse.Amount;
        this.referenceNumber = response.data.data.aggregatorReferenceNumber;
        this.date = response.data.data.thirdPartyResponse.SettleDate;
        this.feeProcessType =
          response.data.data.transactionDetails.feeProcessType === 2
            ? false
            : true;
      }
    },
  },
  async mounted() {
    await this.getCashInDetails();
  },
};
</script>

<style scoped>
body {
  background-color: #fff !important;
}
.success-title {
  color: #004e97 !important;
}
/* Desktop Specific */
.main-bg {
  background-color: white;
  padding: 10px;
  height: 100vh;
  width: 100vw;
}

.dragonpay-success-container {
  height: 100%;
}

/* Mobile */
.main-bg-mobile {
  background-color: white;
  height: 100vh;
  width: 100vw;
}

img .dragonpay-success-image-mobile {
  height: auto;
  width: auto;
}

#dragonpay-success-page {
  display: grid;
  place-content: center;
  height: 100vh;
}

.success-container {
  display: flex;
  flex-direction: column;
  gap: 3px;
  border: 2px solid whitesmoke;
  border-radius: 20px;
  padding: 3rem 5rem;
  width: min(50vw, 700px);
  margin: 0 1rem;
}

.payment {
  font-size: 32px;
  font-weight: 800;
  font-style: italic;
  color: #595959;
  font-family: Arial, Helvetica, sans-serif;
}
.username {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #255da9;
}
.description {
  font-size: 18px;
  color: #bdbdbd;
  font-family: Arial, Helvetica, sans-serif;
}
.value {
  font-size: 18px;
  color: #262626;
  font-family: Arial, Helvetica, sans-serif;
}
.ref,
.date {
  font-size: 14px;
  color: #bdbdbd;
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 768px) {
  .success-container {
    padding: 2rem 4rem;
    width: min(70vw, 700px);
  }
}

@media screen and (max-width: 425px) {
  .success-container {
    padding: 2rem;
    width: min(90vw, 700px);
  }

  .payment {
    font-size: 30px;
  }

  .ref,
  .date {
    font-size: 14px;
    word-wrap: break-word;
  }
}

@media screen and (max-width: 375px) {
  .success-container {
    padding: 1rem;
  }

  .payment {
    font-size: 25px;
  }

  .ref,
  .date {
    font-size: 13px;
    word-wrap: break-word;
  }
}
</style>
