<template>
	<div id="digicoop-dragonpay-success-page">
        <div class="digicoop-dragonpay-success-page main-bg is-hidden-mobile">
            <div class="columns is-vcentered digicoop-dragonpay-success-container is-flex is-justify-content-center is-align-items-center">
                <div class="column">
                    <img
                    :src="
                        require('@/assets/dragonpaysuccesspage/digicoop_online_payment_flatline.png')
                    "
                    alt="dragonpay success imagw"
                    class="is-align-self-center"
                    />
                    <h1 class="has-text-weight-bold is-size-1 my-4 success-title">Congratulations!</h1>
                    <p class="has-text-weight-bold is-size-4">Your transaction has been completed.</p>
                    <p class="has-text-weight-bold is-size-4">You may now click the back button of this page to return.</p>
                </div>
            </div>
        </div>
        <div class="digicoop-dragonpay-success-page-mobile main-bg-mobile is-hidden-tablet">
            <div class="columns is-mobile is-vcentered digicoop-dragonpay-success-container is-flex is-justify-content-center is-align-items-center">
                <div class="column px-4">
                    <img
                        :src="
                            require('@/assets/dragonpaysuccesspage/digicoop_online_payment_flatline.png')
                        "
                        alt="dragonpay success imagw"
                        class="is-align-self-center digicoop-dragonpay-success-image-mobile"
                    />
                    <h1 class="has-text-weight-bold is-size-2-mobile my-3 success-title">Congratulations!</h1>
                    <p class="has-text-weight-bold is-size-6-mobile ">Your transaction has been completed.</p>
                    <p class="has-text-weight-bold is-size-6-mobile">You may now click the back button of this page to return.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DigicoopDragonpaySuccessPageComponent",
    };
</script>

<style scoped>
    .success-title{
        color: #0077D7 !important;
    }
    /* Desktop Specific */
    .main-bg {
        background-color: white;
        padding:10px;
        height: 100vh;
        width: 100vw;
    }

    .digicoop-dragonpay-success-container {
       height: 100%;
    }
    
    /* Mobile */
    .main-bg-mobile {
        background-color: white;
        height: 100vh;
        width: 100vw;
    }

    img .digicoop-dragonpay-success-image-mobile{
        height: auto;
        width: auto;
    }
    </style>