<template>
	<div class="digicoop-dragonpay-success">
		<app-digicoop-dragonpay-success-page />
	</div>
</template>

<script>

import DragonpaySuccessPageComponent from "@/components/DragonpaySuccessPage/DigicoopDragonpaySuccessPageComponent.vue";

export default {
	name: "DragonpaySuccessPageView",
	components: {
		"app-digicoop-dragonpay-success-page": DragonpaySuccessPageComponent,
	},
};
</script>