<template>
	<div id="howtousecoopnet">
		<!-- Desktop -->
		<div class="howtousecoopnet is-hidden-mobile">
			<div class="container">
				<div class="columns mt-6 is-centered">
					<div class="column is-7 mt-6">
						<figure class="image is-16by9">
							<iframe
								class="has-ratio"
								width="10em"
								height="10em"
								:src="howtousecoopnetyoutube"
								title="How to use Coopnet Teller Machine"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							></iframe>
						</figure>
					</div>
					<div class="column is-flex is-align-items-center mt-6">
						<p
							class="is-size-2 has-text-weight-bold"
							:style="{ color: 'black' }"
						>
							HOW TO USE <br />
							<span :style="{ color: 'rgb(51, 157, 255)' }">
								COOPNET TELLER MACHINE
							</span>
						</p>
					</div>
				</div>
			</div>
		</div>
		<!-- Mobile -->
		<div class="howtousecoopnet is-hidden-tablet">
			<div class="container">
				<div class="mt-6">
					<p
						class="
							is-size-4-mobile
							has-text-weight-bold
							mt-1
							mb-3
						"
						:style="{ color: 'black' }"
					>
						HOW TO USE <br />
						<span :style="{ color: 'rgb(51, 157, 255)' }"
							>COOPNET TELLER MACHINE</span
						>
					</p>
				</div>
				<div class="px-4">
					<figure class="image is-16by9">
						<iframe
							class="has-ratio"
							width="10em"
							height="10em"
							:src="howtousecoopnetyoutube"
							title="How to use Coopnet Teller Machine"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						></iframe>
					</figure>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import infocusDirective from "@/components/directives/infocusDirective.vue";

export default {
	name: "HowToUseCoopnetComponent",
	components: {},
	data() {
		return {
			howtousecoopnetyoutube: "https://www.youtube.com/embed/UU4sfpHqOMk",
		};
	},
	directives: {
		infocus: infocusDirective,
	},
};
</script>

<style scoped>
/* Hidden before animation plays */
.hidden {
	opacity: 0;
}

/* Animate.CSS */
/* Desktop Animations */

/* Mobile Animations */
</style>