<template>
	<div class="aboutcoopnet">
		<!-- DESKTOP -->
		<div class="aboutcoopnetdesktop p-6 is-hidden-mobile">
			<div class="container mt-6">
				<!-- Title -->
				<p
					class="
						is-size-1
						has-text-weight-bold
						is-size-3-mobile
					"
					:style="{ color: 'rgb(255, 255, 255)' }"
				>
					ABOUT
					<span :style="{ color: 'rgb(101, 227, 255)' }">
						COOPNET
					</span>
				</p>
				<!-- Description -->
				<p
					class="is-size-4 py-3 has-text-weight-light"
					:style="{
						color: 'rgb(255, 255, 255)',
						paddingLeft: '8.5rem',
						paddingRight: '8.5rem',
					}"
				>
					A Network Solution made specifically for financial entities
					needing to connect with each other, with banks,
					<span class="is-italic">bancnet</span>, e-wallets, and
					remittance centers to facilitate customers’ payment
					collection and disbursement using our DXBANK, Digital
					Bank-in-a-Box, thereby reducing costs of implementation and
					maintenance.
				</p>
				<!-- About Coopnet Logos -->
				<div class="columns is-centered about-coopnet-logos mt-6">
					<div
						class="column is-2 mx-5"
						v-for="(logo, index) in aboutCoopnetLogos"
						:key="index"
					>
						<app-about-coopnet-logo
							:title="logo.title"
							:file-name="logo.fileName"
							:style="{ display: 'block' }"
							v-infocus="'aboutlogos' + index"
							class="hidden"
						/>
					</div>
				</div>
			</div>
		</div>
		<!-- MOBILE -->
		<div
			class="aboutcoopnetmobile p-3 is-hidden-tablet"
			id="aboutcoopnetmobile"
		>
			<div class="container">
				<!-- Title -->
				<p
					class="is-size-3-mobile has-text-weight-semibold mt-4 mb-3"
					:style="{ color: 'rgb(255, 255, 255)' }"
				>
					ABOUT
					<span :style="{ color: 'rgb(101, 227, 255)' }">
						COOPNET
					</span>
				</p>
				<!-- Description -->
				<p
					class="is-size6-mobile"
					:style="{ color: 'rgb(255, 255, 255)' }"
				>
					A Network Solution made specifically for financial entities
					needing to connect with each other, with banks, bancnet, e-
					wallets, and remittance centers to facilitate customers’
					payment collection and disbursement using our DXBANK,
					Digital Bank-in-a-Box, thereby reducing costs of
					implementation and maintenance.
				</p>

				<!-- About Coopnet Logos -->
				<div
					class="
						columns
						is-mobile is-centered
						about-coopnet-logos
						mt-3
					"
				>
					<div class="column is-5">
						<app-about-coopnet-logo
							:title="aboutCoopnetLogos[0].title"
							:file-name="aboutCoopnetLogos[0].fileName"
							:style="{ display: 'block' }"
							v-infocus="'aboutlogos0mobile'"
							class="hidden"
						/>
					</div>
					<div class="column is-5">
						<app-about-coopnet-logo
							:title="aboutCoopnetLogos[1].title"
							:file-name="aboutCoopnetLogos[1].fileName"
							:style="{ display: 'block' }"
							v-infocus="'aboutlogos1mobile'"
							class="hidden"
						/>
					</div>
				</div>
				<div
					class="
						columns
						is-mobile is-centered
						about-coopnet-logos
						mt-3
						mb-2
					"
				>
					<div class="column is-5">
						<app-about-coopnet-logo
							:title="aboutCoopnetLogos[2].title"
							:file-name="aboutCoopnetLogos[2].fileName"
							:style="{ display: 'block' }"
							v-infocus="'aboutlogos2mobile'"
							class="hidden"
						/>
					</div>
					<div class="column is-5">
						<app-about-coopnet-logo
							:title="aboutCoopnetLogos[3].title"
							:file-name="aboutCoopnetLogos[3].fileName"
							:style="{ display: 'block' }"
							v-infocus="'aboutlogos3mobile'"
							class="hidden"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import AboutCoopnetLogoComponent from "@/components/AboutCoopnet/AboutCoopnetLogoComponent.vue";
import infocusDirective from "@/components/directives/infocusDirective.vue";

export default {
	name: "AboutCoopnetComponent",
	components: {
		"app-about-coopnet-logo": AboutCoopnetLogoComponent,
	},
	data() {
		return {
			aboutCoopnetLogos: {
				0: {
					title: "Empowered",
					fileName: "/aboutcoopnet/empowered.ca0e59a1.png",
				},
				1: {
					title: "Integrated",
					fileName: "/aboutcoopnet/accesible.c0d72528.png",
				},
				2: {
					title: "Inclusive",
					fileName: "/aboutcoopnet/coop-centric.50c7086e.png",
				},
				3: {
					title: "Innovative",
					fileName: "/aboutcoopnet/innovative.29785f98.png",
				},
			},
		};
	},
	directives: {
		infocus: infocusDirective,
	},
};
</script>

<style scoped>
.aboutcoopnetdesktop {
	background-color: #473eb9;
}
.aboutcoopnetmobile {
	background-color: #473eb9;
}

/* Hidden before animation plays */
.hidden {
	opacity: 0;
}
/* Animate.CSS */
/* Desktop Animations */
.aboutlogos0 {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 0.4s;
}
.aboutlogos1 {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 0.8s;
}
.aboutlogos2 {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 1.2s;
}
.aboutlogos3 {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 1.4s;
}

/* Mobile Animations */
.aboutlogos0mobile {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 0.3s;
}
.aboutlogos1mobile {
	opacity: 1;
	animation: fadeInRight;
	animation-duration: 0.3s;
}
.aboutlogos2mobile {
	opacity: 1;
	animation: fadeInLeft;
	animation-duration: 0.6s;
}
.aboutlogos3mobile {
	opacity: 1;
	animation: fadeInLeft;
	animation-duration: 0.6s;
}
</style>