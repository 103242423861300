<template>
    <div class="has-text-centered">
        <img :src="require('@/assets' + fileName)" alt="logo">
        <p class="is-size-6 has-text-weight-semibold" :style="{color: 'black'}">
            {{ title }}
        </p>
    </div>
</template>
<script>
	export default {
		name: "MobileBankingAppLogo",
		props: {
			title: String,
			fileName: String,
		},
	};
</script>